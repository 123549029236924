import { Subscription } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder  } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { PostsService } from "../posts.service";
import { Post } from "../post.model";
// import { mimeType } from "./mime-type.validator";
import { AngularEditorConfig } from '@kolkov/angular-editor'; 

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit {

  post: Post;
  isLoading = false;
  form: FormGroup;
  imagePreview: string;
  private mode = "create";
  private postId: string;
  private authStatusSub: Subscription; 
  alldata: any = [];


  @ViewChild('artsinputValue', {static: false}) artsinputValue : ElementRef;  
  @ViewChild('artsinputId', {static: false}) artsinputId : ElementRef;  
  @ViewChild('designinputValue',{static: false}) designinputValue : ElementRef;  
  @ViewChild('designinputId', {static: false}) designinputId : ElementRef;  
  @ViewChild('socialinputValue', {static: false}) socialinputValue : ElementRef;   
  @ViewChild('socialinputId', {static: false}) socialinputId : ElementRef;  
  @ViewChild('physicalinputValue', {static: false}) physicalinputValue : ElementRef;  
  @ViewChild('physicalinputId', {static: false}) physicalinputId : ElementRef;  
  @ViewChild('lifesciencesinputValue', {static: false}) lifesciencesinputValue : ElementRef;  
  @ViewChild('lifesciencesinputId',{static: false}) lifesciencesinputId : ElementRef;  
  @ViewChild('engineeringinputValue',{static: false}) engineeringinputValue : ElementRef;  
  @ViewChild('engineeringinputId',{static: false}) engineeringinputId : ElementRef; 
  @ViewChild('businessinputValue',{static: false}) businessinputValue : ElementRef;  
  @ViewChild('businessinputId',{static: false}) businessinputId : ElementRef; 
  @ViewChild('medicineinputValue',{static: false}) medicineinputValue : ElementRef;  
  @ViewChild('medicineinputId',{static: false}) medicineinputId : ElementRef; 
  @ViewChild('educationinputValue',{static: false}) educationinputValue : ElementRef;  
  @ViewChild('educationinputId',{static: false}) educationinputId : ElementRef; 
  @ViewChild('lawinputValue',{static: false}) lawinputValue : ElementRef;  
  @ViewChild('lawinputId',{static: false}) lawinputId : ElementRef;  

selectedArtsarr:any = [];
selectedDesignarr: any =[];
selectedSocialarr: any =[];
selectedPhysicalarr: any =[];
selectedLifearr: any =[];
selectedEngineeringarr: any =[];
selectedBusinessarr: any =[];
selectedMedicinearr: any =[];
selectedEducationarr: any =[];
selectedLawarr: any =[];
selectedAlumniarr: any =[];

artsarr: any[] = [ 
  {
    "id": "1",
    "value": "Archaeology"
  },
  {
    "id": "2",
    "value": "Language"
  },
  {
    "id": "2",
    "value": "Literature & Linguistics"
  },
  {
    "id": "3",
    "value": "History"
  },
  {
    "id": "3",
    "value": "Philosophy & Theology"
  },
  {
    "id": "4",
    "value": "Conservation"
  },
  {
    "id": "5",
    "value": "Sanskrit" 
  },
  {
    "id": "6",
    "value": "Music"
  },
] 

designarr = [ 
  {
    "id": "1",
    "value": "Architecture"
  },
  {
    "id": "2",
    "value": "Arts"
  },
  {
    "id": "3",
    "value": "Performing Arts"
  },
  {
    "id": "4",
    "value": "Fashion Design"
  },
  {
    "id": "5",
    "value": "Interior Design" 
  },
  {
    "id": "6",
    "value": "Product Design"
  },
  {
    "id": "7",
    "value": "Visual Communication"
  },
  {
    "id": "8",
    "value": "Industrial Design"
  },
  {
    "id": "9",
    "value": "Multimedia & Animation"
  }
] 

socialarr = [ 
  {
    "id": "1",
    "value": "Politics & International Studies"
  },
  {
    "id": "2",
    "value": "Development Studies"
  },
  {
    "id": "3",
    "value": "Geography"
  },
  {
    "id": "4",
    "value": "Sociology"
  },
  {
    "id": "5",
    "value": "Communication & Media studies" 
  },
] 

physicalarr = [ 
  {
    "id": "1",
    "value": "Physics"
  },
  {
    "id": "2",
    "value": "Astronomy"
  },
  {
    "id": "3",
    "value": "Chemistry"
  },
  {
    "id": "4",
    "value": "Mathematics"
  },
  {
    "id": "5",
    "value": "Statistics" 
  },
  {
    "id": "6",
    "value": "Environmental"
  },
  {
    "id": "6",
    "value": "Earth and Marine Sciences"
  },
  {
    "id": "7",
    "value": "Polymer Science"
  },
  {
    "id": "8",
    "value": "Health Science"
  },
  {
    "id": "9",
    "value": "Hotel Management"
  },
  {
    "id": "10",
    "value": "Forestry"
  },
  {
    "id": "11",
    "value": "Botany"
  },
  {
    "id": "12",
    "value": "Others"
  }
] 

lifesciencesarr = [ 
  {
    "id": "1",
    "value": "Biological Science"
  },
  {
    "id": "2",
    "value": "Veterinary Science"
  },
  {
    "id": "3",
    "value": "Agriculture & Forestry"
  },
  {
    "id": "4",
    "value": "Sports Science"
  },
  {
    "id": "5",
    "value": "Yoga Education" 
  },
  {
    "id": "6",
    "value": "Vastushastra"
  },
  {
    "id": "7",
    "value": "Tourism"
  },
] 

engineeringarr = [ 
  {
    "id": "1",
    "value": "Chemical Engineering"
  },
  {
    "id": "2",
    "value": "General Engineering"
  },
  {
    "id": "3",
    "value": "Civil Engineering"
  },
  {
    "id": "4",
    "value": "Electrical & Electronics Engineering"
  },
  {
    "id": "5",
    "value": "Mechanical & Aerospace Engineering" 
  },
  {
    "id": "6",
    "value": "Computer Science"
  },
  {
    "id": "7",
    "value": "Data Science & AI"
  },
  {
    "id": "8",
    "value": "Machine Learning"
  },
  {
    "id": "9",
    "value": "Automation & Robotics"
  },
  {
    "id": "10",
    "value": "Nuclear Engineering"
  },
  {
    "id": "11",
    "value": "Industrial Engineering"
  },
  {
    "id": "12",
    "value": "Bio Technology"
  },
  {
    "id": "13",
    "value": "Radio Chemistry"
  },
  {
    "id": "14",
    "value": "Radio Activity"
  },
  {
    "id": "15",
    "value": "Nano Technology"
  },
  {
    "id": "16",
    "value": "Energy System and Management"
  },
  {
    "id": "17", 
    "value": "Bio-Medical Signal Processing and Instrumentation"
  },
  {
    "id": "18",
    "value": "Ceramic Engineering"
  },
  {
    "id": "19",
    "value": "Mining Engineering"
  },
  {
    "id": "20",
    "value": "Metallurgical Engineering"
  },
  {
    "id": "21",
    "value": "Pharmaceutical Engineering"
  },
  {
    "id": "22",
    "value": "Textile & Fibre Engineering"
  },
  {
    "id": "23",
    "value": "Network & Information Technology"
  },
  {
    "id": "24",
    "value": "Dairy Technology"
  },
  {
    "id": "25",
    "value": "Biochemistry"
  },
  {
    "id": "26",
    "value": "Others"
  },
] 

businessarr = [ 
  {
    "id": "1",
    "value": "Accounting & Finance",
  // checked: true ,
  },
  {
    "id": "2",
    "value": "Economics"
  },
  {
    "id": "3",
    "value": "Econometrics"
  },
  {
    "id": "4",
    "value": "Business & Management"
  },
  {
    "id": "5",
    "value": "General Management"
  },
  {
    "id": "6",
    "value": "Retail Management"
  },
  {
    "id": "7",
    "value": "Rural Management"
  },
  {
    "id": "8",
    "value": "E-Commerce & Digital Marketing"
  },
  {
    "id": "9",
    "value": "Entrepreneurship Development"
  },
  {
    "id": "10",
    "value": "Cyber Security",
  },
  {
    "id": "11",
    "value": "Executive Education",
  },
  {
    "id": "12",
    "value": "Digital Forensic",
  },
  {
    "id": "13",
    "value": "Others"
  }
] 
// getselectedOptions() { // right now: ['1','3']
//   this.businessarr 
//             .filter(opt => opt.checked)
//             .map(opt => opt.value)
//             console.log("opt.checked", this.businessarr)
// }
medicinearr = [ 
  {
    "id": "1",
    "value": "Medicine"
  },
  {
    "id": "2",
    "value": "Surgery"
  },
  {
    "id": "3",
    "value": "Dental"
  },
  {
    "id": "4",
    "value": "Nursing"
  },
  {
    "id": "5",
    "value": "Public Health & Informatics" 
  },
  {
    "id": "6",
    "value": "Physiotherapy"
  },
  {
    "id": "7",
    "value": "Ayurveda"
  },
  {
    "id": "8",
    "value": "Others"
  },
] 

educationarr = [ 
  {
    "id": "1",
    "value": "Education"
  }
] 

lawarr = [ 
  {
    "id": "1",
    "value": "Law"
  }
] 


// alumni: any[] = [ 
//   {
//     "profileimg": "",
//     "profilename": "",
//     "profilepos": "",
//   }

// ]

// addNewArt(){
//   // console.log('inputValue : ', );
//   this.artsarr.push({ "id": this.artsinputId.nativeElement.value , "value": this.artsinputValue.nativeElement.value});
//   console.log(this.artsarr);
// }

addNewArt(){
  this.artsarr.push({ "id": this.artsinputId.nativeElement.value , "value": this.artsinputValue.nativeElement.value});
}

addNewDesign(){
  this.designarr.push({ "id": this.designinputId.nativeElement.value , "value": this.designinputValue.nativeElement.value});
}
addNewsocial(){
  this.socialarr.push({ "id": this.socialinputId.nativeElement.value , "value": this.socialinputValue.nativeElement.value});
}

addNewPhysical(){
  this.physicalarr.push({ "id": this.physicalinputId.nativeElement.value , "value": this.physicalinputValue.nativeElement.value});
}

addNewlifesciences(){
  this.lifesciencesarr.push({ "id": this.lifesciencesinputId.nativeElement.value , "value": this.lifesciencesinputValue.nativeElement.value});
}

addNewEngineering(){
  this.engineeringarr.push({ "id": this.engineeringinputId.nativeElement.value , "value": this.engineeringinputValue.nativeElement.value});
}

addNewBusiness(){
  this.businessarr.push({ "id": this.businessinputId.nativeElement.value , "value": this.businessinputValue.nativeElement.value});
}


addNewMedicine(){
  this.medicinearr.push({ "id": this.medicineinputId.nativeElement.value , "value": this.medicineinputValue.nativeElement.value});
}

addNewEducation(){
  this.educationarr.push({ "id": this.educationinputId.nativeElement.value , "value": this.educationinputValue.nativeElement.value});
}

addNewLaw(){
  this.lawarr.push({ "id": this.educationinputId.nativeElement.value , "value": this.educationinputValue.nativeElement.value});
}

artsChange(event){
  let index = this.selectedArtsarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedArtsarr.push(event.target.value);
  }else{
    this.selectedArtsarr.splice(index,1);
  }
  console.log(event.target.value)
}


designChange(event){
  let index = this.selectedDesignarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedDesignarr.push(event.target.value);
  }else{
    this.selectedDesignarr.splice(index,1);
  }
}

socialChange(event){
  let index = this.selectedSocialarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedSocialarr.push(event.target.value);
  }else{
    this.selectedSocialarr.splice(index,1);
  }
}


physicalChange(event){
  let index = this.selectedPhysicalarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedPhysicalarr.push(event.target.value);
  }else{
    this.selectedPhysicalarr.splice(index,1);
  }
}


lifesciencesChange(event){
  let index = this.selectedLifearr.indexOf(event.target.value);
  if(index == -1){
  this.selectedLifearr.push(event.target.value);
  }else{
    this.selectedLifearr.splice(index,1);
  }
}


engineeringChange(event){
  let index = this.selectedEngineeringarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedEngineeringarr.push(event.target.value);
  }else{
    this.selectedEngineeringarr.splice(index,1);
  }
}

businessChange(event){
  let index = this.selectedBusinessarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedBusinessarr.push(event.target.value);
  }else{
    this.selectedBusinessarr.splice(index,1);
  }
  // console.log(event.target.value)
  // console.log("index", index)
}

medicineChange(event){
  let index = this.selectedMedicinearr.indexOf(event.target.value);
  if(index == -1){
  this.selectedMedicinearr.push(event.target.value);
  }else{
    this.selectedMedicinearr.splice(index,1);
  }
}


educationChange(event){
  let index = this.selectedEducationarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedEducationarr.push(event.target.value);
  }else{
    this.selectedEducationarr.splice(index,1);
  }
}

lawChange(event){
  let index = this.selectedLawarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedLawarr.push(event.target.value);
  }else{
    this.selectedLawarr.splice(index,1);
  }
}


profileimg(event){
  let index = this.selectedAlumniarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedAlumniarr.push(event.target.value);
  }else{
    this.selectedAlumniarr.splice(index,1);
  }
}

profilename(event){
  let index = this.selectedAlumniarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedAlumniarr.push(event.target.value);
  }else{
    this.selectedAlumniarr.splice(index,1);
  }
}

profilepos(event){
  let index = this.selectedAlumniarr.indexOf(event.target.value);
  if(index == -1){
  this.selectedAlumniarr.push(event.target.value);
  }else{
    this.selectedAlumniarr.splice(index,1);
  }
}

  constructor(
    public postsService: PostsService,
    public route: ActivatedRoute,
    private authService: AuthService,
    private fb:FormBuilder
  ) {
    this.selectedOptions;
  }


  ngOnInit() {
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(authStatus => {
        this.isLoading = false;
      });
    this.form = new FormGroup({
      rank: new FormControl(null),
      businessschools: new FormControl(null),
      weightedindex: new FormControl(null),
      placementperformance: new FormControl(null),
      tlrp: new FormControl(null),
      research: new FormControl(null),
      industryincomeintegration: new FormControl(null),
      pss: new FormControl(null),
      futureorientation: new FormControl(null), 
      epio: new FormControl(null),
      address: new FormControl(null),
      iirfranking: new FormControl(null),
      artshumanities: new FormControl(null),
      backgroundimg: new FormControl(null),
      percentageofinternationalstudents: new FormControl(null),
      facultytostudentsration: new FormControl(null),
      highestplacementpackage: new FormControl(null),
      scholarshipavailability: new FormControl(null),
      averageplacementpackage: new FormControl(null),
      researchperspective: new FormControl(null),
      video: new FormControl(null),
      gallery: new FormControl(null),
      impactranking: new FormControl(null),
      saarcranking: new FormControl(null),
      design: new FormControl(null),
      social: new FormControl(null),
      physical: new FormControl(null),
      lifesciences: new FormControl(null),
      engineering: new FormControl(null),
      business: new FormControl(null),
      medicine: new FormControl(null),
      education: new FormControl(null),
      law: new FormControl(null),
      phone: new FormControl(null),
      email: new FormControl(null),
      websitelink: new FormControl(null),
      overallmbaranking: new FormControl(null),
      schoolofeminenceranking: new FormControl(null),
      topprivatebschoolsranking: new FormControl(null),
      bestbschoolsnorthranking: new FormControl(null),
      bestbschoolssouthranking: new FormControl(null),
      bestbschoolscentralranking: new FormControl(null),
      bestbschoolswestranking: new FormControl(null),
      bestbschoolseastranking: new FormControl(null),
      visionranking: new FormControl(null),
      admissionssupport: new FormControl(null),
      facebookpagelink: new FormControl(null),
      linkedinpagelink: new FormControl(null),
      twitterpagelink: new FormControl(null),
      lng: new FormControl(null),
      lat: new FormControl(null),
      content: new FormControl(null),
      logoImagePath: new FormControl(null, {
        // validators: [Validators.required],
        // asyncValidators: [mimeType] 
      })
    });
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has("postId")) {
        this.mode = "edit";
        this.postId = paramMap.get("postId");
        this.isLoading = true;
        this.postsService.getPost(this.postId).subscribe((postData) => {

          console.log(' content: ', postData.content);
          

          this.isLoading = false;
          this.selectedArtsarr = postData.artshumanities,
          this.selectedDesignarr = postData.design,
          this.selectedSocialarr = postData.social,
          this.selectedPhysicalarr = postData.physical,
          this.selectedLifearr = postData.lifesciences,
          this.selectedEngineeringarr = postData.engineering,
          this.selectedBusinessarr = postData.business,
          this.selectedMedicinearr = postData.medicine,
          this.selectedEducationarr = postData.education,
          this.selectedLawarr = postData.law


          this.post = {
            id: postData._id,
            rank: postData.rank,
            businessschools: postData.businessschools,
            weightedindex: postData.weightedindex,
            logoImagePath: postData.logoImagePath,
            placementperformance: postData.placementperformance,
            tlrp: postData.tlrp,
            research: postData.research,
            industryincomeintegration: postData.industryincomeintegration,
            pss: postData.pss,
            futureorientation: postData.futureorientation,
            epio: postData.epio,
            iirfranking: postData.iirfranking,
            address: postData.address,
            content: postData.content,
            artshumanities: postData.artshumanities,
            backgroundimg: postData.backgroundimg,
            percentageofinternationalstudents: postData.percentageofinternationalstudents,
            facultytostudentsration: postData.facultytostudentsration,
            highestplacementpackage: postData.highestplacementpackage,
            scholarshipavailability: postData.scholarshipavailability,
            averageplacementpackage: postData.averageplacementpackage,
            researchperspective: postData.researchperspective,
            video: postData.video,
            gallery: postData.gallery,
            impactranking: postData.impactranking,
            saarcranking: postData.saarcranking,
            design: postData.design,
            social: postData.social,
            physical: postData.physical,
            lifesciences: postData.lifesciences,
            engineering: postData.engineering,
            business: postData.business,
            medicine: postData.medicine,
            education: postData.education,
            law: postData.law,
            phone: postData.phone,
            email: postData.email,
            websitelink: postData.websitelink,
            overallmbaranking: postData.overallmbaranking,
            schoolofeminenceranking: postData.schoolofeminenceranking,
            topprivatebschoolsranking: postData.topprivatebschoolsranking,
            bestbschoolsnorthranking: postData.bestbschoolsnorthranking,
            bestbschoolssouthranking: postData.bestbschoolssouthranking,
            bestbschoolscentralranking: postData.bestbschoolscentralranking,
            bestbschoolswestranking: postData.bestbschoolswestranking,
            bestbschoolseastranking: postData.bestbschoolseastranking,
            visionranking: postData.visionranking,
            admissionssupport: postData.admissionssupport,
            facebookpagelink: postData.facebookpagelink,
            linkedinpagelink: postData.linkedinpagelink,
            twitterpagelink: postData.twitterpagelink,
            lng: postData.lng,
            lat: postData.lat,
            // creator: postData.creator
          };
          this.form.patchValue({
            rank: this.post.rank,
            businessschools: this.post.businessschools,
            weightedindex: this.post.weightedindex,
            placementperformance: this.post.placementperformance,
            tlrp: this.post.tlrp,
            research: this.post.research,
            industryincomeintegration: this.post.industryincomeintegration,
            pss: this.post.pss,
            futureorientation: this.post.futureorientation,
            epio: this.post.epio,
            iirfranking: this.post.iirfranking,
            address: this.post.address,
            content: this.post.content,
            artshumanities: this.post.artshumanities,
            backgroundimg: this.post.backgroundimg,
            percentageofinternationalstudents: this.post.percentageofinternationalstudents,
            facultytostudentsration: this.post.facultytostudentsration,
            highestplacementpackage: this.post.highestplacementpackage,
            scholarshipavailability: this.post.scholarshipavailability,
            averageplacementpackage: this.post.averageplacementpackage,
            researchperspective: this.post.researchperspective,
            video: this.post.video,
            gallery: this.post.gallery,
            impactranking: this.post.impactranking,
            saarcranking: this.post.saarcranking,
            design: this.post.design,
            social: this.post.social,
            physical: this.post.physical,
            lifesciences: this.post.lifesciences,
            engineering: this.post.engineering,
            business: this.post.business,
            medicine: this.post.medicine,
            education: this.post.education,
            law: this.post.law,
            phone: this.post.phone,
            email: this.post.email,
            websitelink: this.post.websitelink,
            overallmbaranking: this.post.overallmbaranking,
            schoolofeminenceranking: this.post.schoolofeminenceranking,
            topprivatebschoolsranking: this.post.topprivatebschoolsranking,
            bestbschoolsnorthranking:this.post.bestbschoolsnorthranking,
            bestbschoolssouthranking: this.post.bestbschoolssouthranking,
            bestbschoolscentralranking: this.post.bestbschoolscentralranking,
            bestbschoolswestranking: this.post.bestbschoolswestranking,
            bestbschoolseastranking: this.post.bestbschoolseastranking,
            visionranking: this.post.visionranking,
            admissionssupport: this.post.admissionssupport,
            facebookpagelink: this.post.facebookpagelink,
            linkedinpagelink: this.post.linkedinpagelink,
            twitterpagelink: this.post.twitterpagelink,
            lng: this.post.lng,
            lat: this.post.lat,
            image: this.post.logoImagePath,
          });
          // console.log('this.postDatas ', this.postDatas );
          // console.log("post data", this.post);
          this.alldata = this.post
          // this.alldata.logoImagePath = `https://iirfpedia.com/admin/upload/${this.alldata.logoImagePath}`
          // this.alldata.backgroundimg = `https://iirfpedia.com/admin/upload/${this.alldata.backgroundimg}`


          // this.postDatas = this.post
        });
      } else {
        this.mode = "create";
        this.postId = null;
      }
    });
  }
  options = [
    {name:'OptionA', value:'1', checked:true},
    {name:'OptionB', value:'2', checked:false},
    {name:'OptionC', value:'3', checked:true}
  ]

  get selectedOptions() { // right now: ['1','3']
    return this.options
              .filter(opt => opt.checked)
              .map(opt => opt.value)
  }
  
  // compareWithFunc(a, b) {
  //   return a.name === b.name;
  // }

  onImagePicked(event: Event) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ logoImagePath: file });
    this.form.get("logoImagePath").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      // console.log('reader.result : ', reader.result);
      this.alldata.logoImagePath = reader.result as string;
      // backgroundimg
      
    };
    reader.readAsDataURL(file);
  }


  onBackgroundPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ backgroundimg: files });
    this.form.get("backgroundimg").updateValueAndValidity();
    // console.log('file: ', files)
    const reader = new FileReader();
    this.alldata.backgroundimg = reader.result as string;
    // reader.onload = () => {
    //   this.backImagePreview = reader.result as string;
    // };
    // reader.readAsDataURL(file);
    function readFile(index) {
      if( index >= files.length ) return;
      var file = files[index];
      reader.onload = function(e) {  
     
        // get file content  
        
        // var bin = e.target.result;
        // do sth with bin
        readFile(index+1)
     
      
      }
      reader.readAsBinaryString(file);
    }
    readFile(0);
  }


  onGalleryPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();
    console.log('file: ', files)
    const reader = new FileReader();
    // reader.onload = () => {
    //   this.backImagePreview = reader.result as string;
    // };
    // reader.readAsDataURL(file);
    function readFile(index) {
      if( index >= files.length ) return;
      var file = files[index];
      reader.onload = function(e) {  
        // get file content  
        
        // var bin = e.target.result;
        // do sth with bin
        readFile(index+1)
      }
      reader.readAsBinaryString(file);
    }
    readFile(0);
  }



  // onSavePost() {
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   this.isLoading = true;
  //   if (this.mode === "create") {
  //     this.postsService.addPost(
  //       this.form.value.title,
  //       this.form.value.content,
  //       this.form.value.image
  //     );
  //   } else {
  //     this.postsService.updatePost(
  //       this.postId,
  //       this.form.value.title,
  //       this.form.value.content,
  //       this.form.value.image 
  //     );
  //   }
  //   this.form.reset();
  // }

  onSavePost() {
    if (this.form.invalid) {
      return;
    }
    // console.log(this.selectedArtsarr);
    // this.isLoading = true;
    if (this.mode === "create") {
      // console.log('add post');
      // console.log("this.form.value.rank",this.form.value.rank)
      // console.log("this.form.value.businessschools",this.form.value.businessschools)
      this.postsService.addPost( 
        this.form.value.rank!=null?this.form.value.rank:undefined,
        this.form.value.businessschools, 
        this.form.value.weightedindex, 
        this.form.value.placementperformance,
        this.form.value.tlrp,
        this.form.value.research,
        this.form.value.industryincomeintegration,
        this.form.value.pss,
        this.form.value.futureorientation,
        this.form.value.epio,
        this.form.value.iirfranking,
        this.form.value.address,
        this.form.value.content,
        this.form.value.artshumanities = this.selectedArtsarr,
        this.form.value.backgroundimg,
        this.form.value.percentageofinternationalstudents,
        this.form.value.facultytostudentsration, 
        this.form.value.highestplacementpackage,
        this.form.value.scholarshipavailability,
        this.form.value.averageplacementpackage,
        this.form.value.researchperspective,
        this.form.value.video,
        this.form.value.gallery,
        this.form.value.impactranking,
        this.form.value.saarcranking,
        this.form.value.design = this.selectedDesignarr,
        this.form.value.social = this.selectedSocialarr,
        this.form.value.physical = this.selectedPhysicalarr,
        this.form.value.lifesciences = this.selectedLifearr,
        this.form.value.engineering = this.selectedEngineeringarr,
        this.form.value.business = this.selectedBusinessarr,
        this.form.value.medicine = this.selectedMedicinearr,
        this.form.value.education = this.selectedEducationarr,
        this.form.value.law = this.selectedLawarr,
        this.form.value.phone,
        this.form.value.email,
        this.form.value.websitelink,
        this.form.value.overallmbaranking,
        this.form.value.schoolofeminenceranking,
        this.form.value.topprivatebschoolsranking,
        this.form.value.bestbschoolsnorthranking,
        this.form.value.bestbschoolssouthranking,
        this.form.value.bestbschoolscentralranking,
        this.form.value.bestbschoolswestranking,
        this.form.value.bestbschoolseastranking,
        this.form.value.visionranking,
        this.form.value.admissionssupport,
        this.form.value.facebookpagelink,
        this.form.value.linkedinpagelink,
        this.form.value.twitterpagelink,
        this.form.value.lng,
        this.form.value.lat,
        this.form.value.logoImagePath, 
      );
    } else {
      // console.log("this.form.value.content",     this.form.value.content);
      // console.log("this.form.value.artshumanities ", this.form.value.artshumanities )
      
      // return;
      this.postsService.updatePost(
        this.postId,
        this.form.value.rank,
        this.form.value.businessschools, 
        this.form.value.weightedindex, 
        this.form.value.placementperformance,
        this.form.value.tlrp,
        this.form.value.research,
        this.form.value.industryincomeintegration,
        this.form.value.pss,
        this.form.value.futureorientation,
        this.form.value.epio,
        this.form.value.iirfranking,
        this.form.value.address,
        this.form.value.content,
        this.form.value.artshumanities = this.selectedArtsarr,
        this.form.value.backgroundimg,
        this.form.value.percentageofinternationalstudents,
        this.form.value.facultytostudentsration, 
        this.form.value.highestplacementpackage,
        this.form.value.scholarshipavailability,
        this.form.value.averageplacementpackage,
        this.form.value.researchperspective,
        this.form.value.video,
        this.form.value.gallery,
        this.form.value.impactranking,
        this.form.value.saarcranking,
        this.form.value.design = this.selectedDesignarr,
        this.form.value.social = this.selectedSocialarr,
        this.form.value.physical = this.selectedPhysicalarr,
        this.form.value.lifesciences = this.selectedLifearr,
        this.form.value.engineering = this.selectedEngineeringarr,
        this.form.value.business = this.selectedBusinessarr,
        this.form.value.medicine = this.selectedMedicinearr,
        this.form.value.education = this.selectedEducationarr,
        this.form.value.law = this.selectedLawarr,
        this.form.value.phone,
        this.form.value.email,
        this.form.value.websitelink,
        this.form.value.overallmbaranking,
        this.form.value.schoolofeminenceranking,
        this.form.value.topprivatebschoolsranking,
        this.form.value.bestbschoolsnorthranking,
        this.form.value.bestbschoolssouthranking,
        this.form.value.bestbschoolscentralranking,
        this.form.value.bestbschoolswestranking,
        this.form.value.bestbschoolseastranking,
        this.form.value.visionranking,
        this.form.value.admissionssupport,
        this.form.value.facebookpagelink,
        this.form.value.linkedinpagelink,
        this.form.value.twitterpagelink,
        this.form.value.lng,
        this.form.value.lat,
        this.form.value.logoImagePath,
        this.form.value.logoImagePathEdit,
    
      );
    }
    // console.log(this.postsService)
    this.form.reset();
  }


  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: '300px',
      minHeight: '500',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [ 
      // ['bold', 'italic'],
      // ['fontSize']
    ]
};

checkArtsData = (value) => {
  let data = this.post.artshumanities.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkDesignData = (value) => {
  let data = this.post.design.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkSocialData = (value) => {
  let data = this.post.social.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkPhysicalData = (value) => {
  let data = this.post.physical.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkLifesciencesData = (value) => {
  let data = this.post.lifesciences.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkEngineeringData = (value) => {
  let data = this.post.engineering.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkBusinessData = (value) => {
  let data = this.post.business.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkMedicineData = (value) => {
  let data = this.post.medicine.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkEducationData = (value) => {
  let data = this.post.education.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
checkLawData = (value) => {
  let data = this.post.law.filter(item => item == value.trim())
  return data.length > 0 ? true : false;
}
}
