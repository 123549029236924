// csv.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor() {}

  downloadFile(data: string, filename: string) {
    const blob = new Blob([data], { type: 'text/csv' });

    // Create a link element, set its attributes, and trigger a download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // Append the link to the document and trigger a click event
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }
}
