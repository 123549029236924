import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl  } from "@angular/forms";
import { HttpRequestService } from '../../../services/http-request.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
@Component({
  selector: 'app-branchmapping',
  templateUrl: './branchmapping.component.html',
  styleUrls: ['./branchmapping.component.css']
})
export class BranchmappingComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit =  false;
  btntext = 'Submit';
  text = 'Add';
  isLoading= false;
  isdisabled = false;
  subbranches:any[] = [];
  yearsArray:any[] = [];
  SubObjectData:any= [];
  branches:any= [];
  childbranches:any= [];
  constructor(private formBuilder: FormBuilder, private httpRequest: HttpRequestService) { }

  ngOnInit() {
    this.form = this.formBuilder.group({ 
    //  yearid:['',Validators.required],
      branchid:['',Validators.required],
      subbranchid:['',Validators.required],
      childbranch:['',Validators.required],
      status:['1',Validators.required],
     // childbranchuri: ['',Validators.pattern(/^[a-z0-9-]+$/)],
      tabstatus:['',Validators.required],
    })
   // this.geyears();
    this.geBranch();
    this.getChildBranchdata();
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geyears(){
    this.httpRequest.getRequest({
      url: '/year/fetchYears?status=1',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
        console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.yearsArray = response.data;
          }
        }
        
      },
      error => {
        console.log(error)
      }
    );

  }

  geBranch(yearObjectId=0){
    this.httpRequest.getRequest({
      url: '/branch/fetchbranch',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
        console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.branches = response.data;
          }
        }
        
      },
      error => {
        console.log(error)
      }
    );
  }

  getSubBranchdata(branchObjectId){
    console.log(branchObjectId);
    this.httpRequest.getRequest({
      url: '/subbranch/fetchsubbranch?branchid='+branchObjectId
      }).
      subscribe((response: any) => {
       
        if (response.status == 'success') {
          this.subbranches = response.data;
        }
      },
      error => {
      }
    );
  }


  getChildBranchdata(){
    console.log();
    this.httpRequest.getRequest({
      url: '/subbranch/getChildBranch'
      }).
      subscribe((response: any) => {
       
        if (response.status == 'success') {
          this.childbranches = response.data;
        }
      },
      error => {
      }
    );
  }


  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled= true;
    this.btntext = 'Processing ...';
    console.log(this.objectId);
    if(this.objectId) {
      this.httpRequest.putRequest({
        url: '/subbranch/updatechildbranch/'+ this.objectId,
        value:JSON.stringify(this.form.value),
        headers:{'content-type':'application/json'}
        }).
        subscribe((response: any) => {
          console.log(response);
          this.isLoading = false;
          if (response.status == 'success') {
            this.objectId = '';
            this.isSubmit= true;
            this.getChildBranchdata();
            setTimeout(() => {
              this.btntext = 'Submit';
              this.onReset();
              this.isdisabled= false;
               },2000);
  
          } else {
  
          }
        },
        error => {
          this.isSubmit= false;
          this.btntext = 'Submit';
          this.isdisabled= false;
          this.isLoading = false;
        }
      );

    } else {

      this.httpRequest.postRequest({
        url: '/subbranch/savechildbranch',
        value:JSON.stringify(this.form.value),
        headers:{'content-type':'application/json'}
        }).
        subscribe((response: any) => {
         
          this.isLoading = false;
        
          this.isdisabled= false;
          if (response.status == 'success') {
            this.isSubmit= true;
            this.getChildBranchdata();
            setTimeout(() => {
              this.isSubmit= false;
              this.btntext = 'Submit';
              this.onReset();
               },2000);
          } else {
  
          }
        },
        error => {
          this.isSubmit= false;
          this.btntext = 'Submit';
          this.isdisabled= false;
          this.isLoading = false;
        }
      );

    }

  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = '';
    this.SingleObjectData = {};
  }


  editform(Object){
    console.log(Object);
    if(Object._id){
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      //if(this.SingleObjectData.yearid){
        this.geBranch()
     // }

      if(this.SingleObjectData.branchid){
        this.getSubBranchdata(this.SingleObjectData.branchid._id)
      }

      this.form.patchValue({
      //  yearid: (this.SingleObjectData.yearid) ? this.SingleObjectData.yearid._id : '',
        branchid: (this.SingleObjectData.branchid) ? this.SingleObjectData.branchid._id : '',
        subbranchid: (this.SingleObjectData.subbranchid) ? this.SingleObjectData.subbranchid._id: '',
       /// childbranchuri: this.SingleObjectData.childbranchuri,
        status: this.SingleObjectData.status, 
        childbranch: this.SingleObjectData.childbranch, 
        tabstatus: this.SingleObjectData.tabstatus,
      });
    } 

  }

}
