
import { PageEvent } from "@angular/material";
import { Post , Meta } from "../post.model";
import { MetaService } from "../meta.service";
// import { UniversitiesService } from "../";
import { PostsService } from "../posts.service";
import { Subscription } from "rxjs";
import { AuthService } from "../../auth/auth.service";
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder  } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { AngularEditorConfig } from '@kolkov/angular-editor'; 




@Component({
  selector: 'app-add-meta',
  templateUrl: './add-meta.component.html',
  styleUrls: ['./add-meta.component.scss']
})
export class AddMetaComponent implements OnInit , OnDestroy{
  posts: Post[] = [];
  isLoading = false;
  totalPosts = 0;
  postsPerPage = 1000;
  currentPage = 1;
  // pageSizeOptions = [1, 2, 5, 10];
  userIsAuthenticated = false;
  userId: string;
  imagePreview: string;
  private postsSub: Subscription;
  private authStatusSub: Subscription;

  post: Meta;

  form: FormGroup;
  private mode = "create";
  private postId: string;
  
  constructor(
    public postsService: PostsService,
    public postsDetailsService: MetaService,
    private authService: AuthService,
    public route: ActivatedRoute, 
    private fb:FormBuilder
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.postsService.getPosts(this.postsPerPage, this.currentPage);
    this.userId = this.authService.getUserId();
    this.postsSub = this.postsService
      .getPostUpdateListener()
      .subscribe((postData: { posts: Post[]; postCount: number }) => {
        this.isLoading = false;
        this.totalPosts = postData.postCount;
        this.posts = postData.posts;
        // console.log(this.posts) 
      });
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe(isAuthenticated => {
        this.userIsAuthenticated = isAuthenticated;
        this.userId = this.authService.getUserId();
      });

      this.form = new FormGroup({
        
        collegename: new FormControl(null, {
          validators: [Validators.required]
        }),
        title: new FormControl(null, {
          validators: [Validators.required]
        }),
        metadescription: new FormControl(null, {
          validators: [Validators.required]
        }),
        keywords: new FormControl(null, {
          // validators: [Validators.required]
        }),
      });
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        if (paramMap.has("postId")) {
          this.mode = "edit";
          this.postId = paramMap.get("postId");
          this.isLoading = true;
          this.postsDetailsService.getPost(this.postId).subscribe(postData => {
            this.isLoading = false;
            this.post = {
              id: postData._id,
              collegename: postData.collegename,
              title: postData.title,
              metadescription: postData.metadescription,
              keywords: postData.keywords,
              creator: postData.creator,
 
            };
            this.form.setValue({
              collegename: this.post.collegename,
              title: this.post.title,
              metadescription: this.post.metadescription,
              keywords: this.post.keywords,
            });
          });
        } else {
          this.mode = "create";
          this.postId = null;
        }
      });
  }


  onSavePost() {
    if (this.form.invalid) {
      return;
    }
    // console.log(this.postsDetailsService.addPost)
  // return;
    this.isLoading = true;
    console.log('this.form.value : ', this.form.value);
    if (this.mode === "create") {
      // this.postsDetailsService.addPost(
      //   this.form.value.collegename,
      //   this.form.value.title,
      //   this.form.value.metadescription,
      //   this.form.value.keywords
      // );
      this.postsDetailsService.addPost(
        this.form.value
      )
      
    } else {
      this.postsDetailsService.updatePost(
        this.postId,
        this.form.value.collegename,
        this.form.value.title, 
        this.form.value.metadescription,
        this.form.value.keywords,
      );

    }
    console.log(this.form.value)
    this.form.reset();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
  }
  
}
