import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import { Subscription } from "rxjs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
@Component({
  selector: "app-sub-category",
  templateUrl: "./sub-category.component.html",
  styleUrls: ["./sub-category.component.css"],
})
export class SubCategoryComponent implements OnInit {
  @ViewChild("editor", { static: false }) editor: any; // Add this line for CKEditor
  @ViewChild("metaeditor", { static: false }) metaeditor: any; // Add this line for CKEditor
  submitted = false;
  form: FormGroup;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  ObjectCategoryData:  any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  yearsArray: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  private postsSub: Subscription;
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      parent: ["", Validators.required],
      categoryname: ["", Validators.required],
      displayorder: ["", Validators.required],
      status: ["1", Validators.required],
      description: [''],
      pagetitle: [''],
      metatitle: [''],
      keywords: [''],
      metadescription: [''],
      displayinpages: [],
      slug: ["", [Validators.pattern('^[a-z0-9-]*$')]],
    });

    this.Searchform = this.formBuilder.group({
      categoryid: [""],
      status: [""],
    });
    this.geCategory();

    // // Added listener for subscribe data
    this.postsSub = this.httpRequest
      .getPostUpdateListener()
      .subscribe((postData) => {
        this.isLoading = false;
        this.totalCount = postData.postCount;
        this.ObjectData = postData.posts;
        console.log("ObjectData");
        console.log(this.ObjectData);
      });

  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geCategory() {
    this.httpRequest
      .getRequest({
        url:
          "/category/fetchallcategory?status=1",
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
                this.ObjectCategoryData = response.data;
              this.Searchform.setValue({ categoryid: this.ObjectCategoryData[0]._id, status:1});
              this.geSubCategory();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Pagination to change page
  onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.geSubCategory();
  }

  // Fetch all data from database with page size
  geSubCategory() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&categoryid=${this.Searchform.value.categoryid}&status=${this.Searchform.value.status}`;
    this.httpRequest.getPosts({
      url: "/subcategory/fetchsubcategory" + queryParams,
    });
  }


  // geSubCategory() {
  //   this.httpRequest
  //     .getRequest({
  //       url:
  //         "/subcategory/fetchsubcategory?categoryid=" + this.Searchform.value.categoryid,
  //        headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status == "success") {
  //           if (response.data.length > 0) {
  //             this.ObjectData = response.data.sort(function (a: any, b: any) {
  //               return Number(a.displayorder) - Number(b.displayorder);
  //             });
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    console.log(this.objectId);
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/subcategory/updatesubcategory/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              //this.Searchform.setValue({ categoryid: this.form.value.categoryid });
              this.geSubCategory();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = false;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/subcategory/savesubcategory",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              //this.Searchform.setValue({ categoryid: this.form.value.categoryid });
              this.geSubCategory();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object) {
    console.log(Object);
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        parent: this.SingleObjectData.parent._id,
        categoryname: this.SingleObjectData.categoryname,
        displayorder: this.SingleObjectData.displayorder,
        status: this.SingleObjectData.status,
        description: this.SingleObjectData.description,
        pagetitle: this.SingleObjectData.pagetitle,
        metatitle: this.SingleObjectData.metatitle,
        keywords: this.SingleObjectData.keywords,
        metadescription: this.SingleObjectData.metadescription,
        displayinpages: this.SingleObjectData.displayinpages,
        slug: this.SingleObjectData.slug,
      });
      if (this.SingleObjectData.description) {
        this.editor.editorInstance.setData(
          this.SingleObjectData.description
        );
      }

      if (this.SingleObjectData.metadescription) {
        this.metaeditor.editorInstance.setData(
          this.SingleObjectData.metadescription
        );
      }
    }
  }

  onSubmitCategory() {
    console.log(this.Searchform.value.categoryid);
    this.geSubCategory();
  }
}
