import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";

@Component({
  selector: "app-iirf-exam-listing",
  templateUrl: "./iirf-exam-listing.component.html",
  styleUrls: ["./iirf-exam-listing.component.css"],
})
export class IIRFExamListingComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  // objectId: any;
  ObjectData: any[] = [];
  // SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  // text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      // country: ["", Validators.required],
      abroadexam: [""],
      status: ["1", Validators.required],
    });
    this.getAbroadExam();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  getAbroadExam() {
    this.httpRequest
      .getRequest({
        url: "/iirfexam/get-exam",
        // value: JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response, "qwsedrftgyhujiko");
          if (response) {
            // if (response.data.length > 0) {
              this.ObjectData = response;
            // }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Turncate string
  truncateString(str: any, maxLength: any) {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    }
    return str;
  }
  // onSubmit() {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   console.log(this.form.value, " :: country connect!");
  //   this.isLoading = true;
  //   this.isdisabled = true;
  //   this.btntext = "Processing...";

  //   this.httpRequest
  //     .getRequest({
  //       url: `/country/fetchCountries?country=${
  //         this.form.value.country ? this.form.value.country : ""
  //       }&status=${this.form.value.status}`,
  //       // value: JSON.stringify(this.form.value),
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status == "success") {
  //           if (response.data.length > 0) {
  //             this.ObjectData = response.data;
  //           }
  //           this.isLoading = false;
  //           this.isdisabled = false;
  //           this.btntext = "Submit";
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //         this.isLoading = false;
  //         this.isdisabled = false;
  //         this.btntext = "Submit";
  //       }
  //     );
  // }
  // onReset(): void {
  //   this.submitted = false;
  //   this.form.reset();
  //   // this.objectId = "";
  //   // this.SingleObjectData = {};
  // }
}
