import { Component, OnInit, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";

import { HttpRequestService } from "./../../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";

@Component({
  selector: "app-update-common-courses-content",
  templateUrl: "./update-common-courses-content.component.html",
  styleUrls: ["./update-common-courses-content.component.css"],
})
export class UpdateCommonCourseContentComponent implements OnInit {
  @ViewChild("editor", { static: false }) editorComponent: CKEditorComponent;
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfigs = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "imageUpload",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    height: "10rem",
    // extraPlugins: "autogrow",
    // autoGrow_minHeight: 200,
    // autoGrow_maxHeight: 600,
    // autoGrow_bottomSpace: 50,
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add other heading levels as needed
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:full",
        "imageStyle:side",
      ],
      styles: ["full", "side"],
      customUploadFileName: (file) => {
        return "custom-prefix-" + file.name;
      },
    },
  };

  commonCourseDetails: {
    contentTitle: string;
    content: string;
    courseName: string;
    courseCode: string;
    created: string;
    stream: any;
    subStream: any;
    updated: string;
    __v: string;
    _id: string;
    // Seo Section Starts here
    metatitle: string;
    keywords: string;
    metadescription: string;
    // Seo Section Ends heres
  } = {
    contentTitle: "",
    content: "",
    courseName: "",
    courseCode: "",
    created: "",
    stream: {},
    subStream: {},
    updated: "",
    __v: "",
    _id: "",
    // Seo Section Starts here
    metatitle: "",
    keywords: "",
    metadescription: "",
    // Seo Section Ends heres
  };

  // this.editorConfigs.extraPlugins = 'autogrow';
  // this.editorConfigs.autoGrow_minHeight = 200;
  // this.editorConfigs.autoGrow_maxHeight = 600;
  // this.editorConfigs.autoGrow_bottomSpace = 50;

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    let id: string = "";
    this.route.params.subscribe((params: any) => {
      id = params["id"];
    });
    this.fetchCommonCoursesDetails(id);
  }

  fetchCommonCoursesDetails(item: any) {
    this.httpRequest
      .getRequest({
        url: `/v1courses/getsinglecourse/${item}`,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.commonCourseDetails = response.responseFormat.data[0];
            if (!response.responseFormat.data[0].content) {
              this.commonCourseDetails.content = "";
            }
            if (!response.responseFormat.data[0].contentTitle) {
              this.commonCourseDetails.contentTitle = "";
            }
          } else {
          }
        },
        (error) => {}
      );
  }

  // Updating the States
  updateCourseName(newCourseName: string) {
    this.commonCourseDetails.courseName = newCourseName;
  }
  updateContentTitle(newContentTitle: string) {
    this.commonCourseDetails.contentTitle = newContentTitle;
  }
  updateMetatitle(newMetatitle: string) {
    this.commonCourseDetails.metatitle = newMetatitle;
  }
  updateMetaDescription(newMetaDescription: string) {
    this.commonCourseDetails.metadescription = newMetaDescription;
  }
  updateKeyWords(newKeyWords: string) {
    this.commonCourseDetails.keywords = newKeyWords;
  }

  onSubmitUpdate() {
    let payload: any = {};
    payload._id = this.commonCourseDetails._id;
    payload.stream = this.commonCourseDetails.stream._id;
    if (this.commonCourseDetails.subStream) {
      payload.subStream = this.commonCourseDetails.subStream;
    }
    if (this.commonCourseDetails.courseName) {
      payload.courseName = this.commonCourseDetails.courseName;
    }
    if (this.commonCourseDetails.content) {
      payload.content = this.commonCourseDetails.content;
    }
    this.httpRequest
      .putRequest({
        url: `/v1courses/updatecommoncourse/${this.commonCourseDetails._id}`,
        value: JSON.stringify(payload),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.toastr.success("Course Applied!", "Toastr fun!");
            this.fetchCommonCoursesDetails(this.commonCourseDetails._id);
          }
        },
        (error) => {
          // Handle any errors that occur during the request
          console.error("API error:", error);
        }
      );
  }

  onCkEditorContentChange(item: any) {
    this.commonCourseDetails.content = item;
  }
}
