import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../../services/http-request.service";

import { ToastrService } from "ngx-toastr";

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

export interface CommonCourse {
  courseName: string;
  courseCode: string;
  created: Date;
  updated: Date;
  stream: string;
  _id: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
];

const COMMON_COURSE_DATA: CommonCourse[] = [];
/**
 * @title Basic use of `<table mat-table>`
 */

@Component({
  selector: "app-college-course",
  templateUrl: "./add-college-course.component.html",
  styleUrls: ["./add-college-course.component.scss"],
})
export class CollegesCoursesComponent implements OnInit {
  // panelOpenState = false;
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SubObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  searchCourseName: string = "";

  streams: any[] = [];
  substreams: Object = {};
  courseLevels: any[] = [];
  public id: string;

  displayedColumns: string[] = [
    "position",
    "name",
    "symbol2",
    "symbol",
    "status",
    "weight",
  ];

  displayedCourseColumns: string[] = [
    "serialNo",
    "levelSpecificCourseName",
    "commonCourse",
    "level",
    "action",
  ];

  dataSource = ELEMENT_DATA;

  // New Course Options
  courseOptions: any = {};
  appliedCourses: any = {};
  appliedCoursesRecord: any = {};
  newCoursesToApply: any = {};
  coursesToRemove: any = {};

  @ViewChildren("courseNameInput") courseNameInputs: QueryList<ElementRef>;
  @ViewChildren("courseCodeInput") courseCodeInputs: QueryList<ElementRef>;

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      stream: ["", Validators.required],
      substream: ["", Validators.required],
      substreamslug: [""],
      status: ["1", Validators.required],
    });
    this.id = this.route.snapshot.paramMap.get("id");
    this.getActiveStreams();
    this.getCourseLevels();
  }

  // toastr
  showSuccess() {
    this.toastr.success("Course Applied!", "Toastr fun!");
  }

  // Get All the Active Streams
  getActiveStreams() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchstreams?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              // this.branches = response.data;
              this.streams = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Get All the Active Course Level
  getCourseLevels() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getcourselevels?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe((response: any) => {
        console.log(response);
        if (response.status === "success") {
          if (response.data.length > 0) {
            this.courseLevels = response.data;
            console.log(response.data, " : this.courseLevels");
          }
        }
      });
  }

  streamTabOpen(streamId: any) {
    console.log(streamId, " : Stream tab Opened");
    // this.getAllCommonCourses(streamId);
    this.getLevelSpecificCommonCourses(streamId);

    this.httpRequest
      .getRequest({
        url: `/v1courses/fetchsubstreams?status=1&streamId=${streamId}`,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status === "success") {
            if (response.data.length > 0) {
              this.substreams[streamId] = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  streamTabClosed(streamId: any) {
    delete this.substreams[streamId];
    delete this.courseOptions[streamId];
    delete this.appliedCourses[streamId];
    delete this.appliedCoursesRecord[streamId];
    delete this.newCoursesToApply[streamId];
    delete this.coursesToRemove[streamId];
    console.log(this.courseOptions, " : this.courseOptions After");
    console.log(this.appliedCourses, " : this.appliedCourses After");
    console.log(this.appliedCoursesRecord, " : this.appliedCoursesRecord");
    console.log(this.newCoursesToApply, " : this.newCoursesTApply After");
    console.log(this.coursesToRemove, " : this.coursesToRemove");
  }

  // onSearchCourseName() {
  //   if (this.searchCourseName.trim() !== "") {
  //     this.getLevelSpecificCommonCourses(this.searchCourseName);
  //   }
  // }
  // getLevelSpecificCommonCourses(stream: any,levelSpecifcCourseName : string ="") {
  //   // getLevelSpecificCommonCourses
    
  //   this.httpRequest
  //     .getRequest({
  //       url: `/v1courses/getLevelSpecificCommonCourses?stream=${stream}&levelSpecifcCourseName=${levelSpecifcCourseName}&status=1`,
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe((response: any) => {
  //       if (response.status === "success") {
  //         this.courseOptions[stream] = response.responseFormat[stream];
  //         this.httpRequest
  //           .getRequest({
  //             url: `/v1courses/getLevelSpecificCommonCourses?stream=${stream}&levelSpecifcCourseName=${levelSpecifcCourseName}&status=1`,
  //             headers: { "content-type": "application/json" },
  //           })
  //           .subscribe((response: any) => {
  //             console.log(response, " : Applied common courses");
  //             if (response.status === "success") {
  //               let sampleArr = response.responseFormat[stream].map(
  //                 (element: any) => {
  //                   return element.levelSpecifcCommonCourse._id;
  //                 }
  //               );
  //               this.appliedCourses[stream] = JSON.parse(
  //                 JSON.stringify(sampleArr)
  //               );
  //               this.appliedCoursesRecord[stream] = JSON.parse(
  //                 JSON.stringify(sampleArr)
  //               );
  //             }
  //           });
  //       }
  //       console.log(this.courseOptions, " : this.courseOptions Before");
  //       console.log(this.appliedCourses, " : this.appliedCourses Before");
  //       console.log(this.appliedCoursesRecord, " : this.appliedCoursesRecord");
  //       console.log(this.newCoursesToApply, " this.newCoursesToApply Before");
  //       console.log(this.coursesToRemove, " this.coursesToRemove Before");
  //     });
  // }
  onSearchCourseName(stream) {
    if (this.searchCourseName.trim() !== "") {
      this.getLevelSpecificCommonCourses(stream, this.searchCourseName); // Pass null for stream
    }
  }
  
  getLevelSpecificCommonCourses(stream: any, levelSpecifcCourseName: string = "") {
    // getLevelSpecificCommonCourses
    this.httpRequest
      .getRequest({
        url: `/v1courses/getLevelSpecificCommonCourses?stream=${stream}&levelSpecifcCourseName=${levelSpecifcCourseName}&status=1`,
        headers: { "content-type": "application/json" },
      })
      .subscribe((response: any) => {
        if (response.status === "success") {
          this.courseOptions[stream] = response.responseFormat[stream];
          this.httpRequest
            .getRequest({
              url: `/v1courses/getCollegeSpecificCommonCourse?collegeId=${this.id}&stream=${stream}&levelSpecifcCourseName=${levelSpecifcCourseName}`,
              headers: { "content-type": "application/json" },
            })
            .subscribe((response: any) => {
              console.log(response, " : Applied common courses");
              if (response.status === "success") {
                let sampleArr = response.responseFormat[stream].map(
                  (element: any) => {
                    return element.levelSpecifcCommonCourse._id;
                  }
                );
                this.appliedCourses[stream] = JSON.parse(
                  JSON.stringify(sampleArr)
                );
                this.appliedCoursesRecord[stream] = JSON.parse(
                  JSON.stringify(sampleArr)
                );
              }
            });
        }
        console.log(this.courseOptions, " : this.courseOptions Before");
        console.log(this.appliedCourses, " : this.appliedCourses Before");
        console.log(this.appliedCoursesRecord, " : this.appliedCoursesRecord");
        console.log(this.newCoursesToApply, " this.newCoursesToApply Before");
        console.log(this.coursesToRemove, " this.coursesToRemove Before");
      });
  }
  
  // get college specific Courses
  getCollegeSpecificCommonCourses(stream: any) {
    this.httpRequest
      .getRequest({
        url: `/v1courses/getCollegeSpecificCommonCourse?collegeId=${this.id}&stream=${stream}`,
        headers: { "content-type": "application/json" },
      })
      .subscribe((response: any) => {
        if (response.status === "success") {
          let sampleArr = response.responseFormat[stream].map(
            (element: any) => {
              return element.levelSpecifcCommonCourse._id;
            }
          );
          this.appliedCourses[stream] = JSON.parse(JSON.stringify(sampleArr));
          this.appliedCoursesRecord[stream] = JSON.parse(
            JSON.stringify(sampleArr)
          );
        }
      });
  }

  // addNewCommonCourse(stream: any) {
  //   console.log(stream, " : Stream Object");

  //   // Get the input values
  //   const courseNameInputsArray = this.courseNameInputs.toArray();
  //   const courseCodeInputsArray = this.courseCodeInputs.toArray();

  //   const courseName = courseNameInputsArray.find(
  //     (input) => input.nativeElement.id === `courseNameInput_${stream._id}`
  //   ).nativeElement.value;
  //   // Clearing Value
  //   courseNameInputsArray.find(
  //     (input) => input.nativeElement.id === `courseNameInput_${stream._id}`
  //   ).nativeElement.value = "";

  //   const courseCode = courseCodeInputsArray.find(
  //     (input) => input.nativeElement.id === `courseCodeInput_${stream._id}`
  //   ).nativeElement.value;
  //   // Clearing Value
  //   courseCodeInputsArray.find(
  //     (input) => input.nativeElement.id === `courseCodeInput_${stream._id}`
  //   ).nativeElement.value = "";

  //   // Use the input values in your function
  //   console.log("Course Name:", courseName);
  //   console.log("Course Code:", courseCode);

  //   // this.courseOptions["6488093cfef38a2470b74e45"][
  //   //   "64786810386d8f2a94c3eec1"
  //   // ].push({
  //   //   _id: new Date(),
  //   //   courseName: courseName,
  //   //   courseCode: courseCode,
  //   // });

  //   let payload: any = {
  //     stream: stream._id,
  //   };
  //   if (courseName) {
  //     payload.courseName = courseName;
  //   }
  //   if (courseCode) {
  //     payload.courseCode = courseCode;
  //   } else {
  //     payload.courseCode = " ";
  //   }

  //   console.log(payload, " : payload");

  //   this.httpRequest
  //     .postRequest({
  //       url: "/v1courses/savecommoncourse",
  //       value: payload,
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         // this.isLoading = false;
  //         // this.isdisabled = false;
  //         if (response.status == "success") {
  //           console.log("Course Added Successfully!");
  //           // this.getAllCommonCourses(stream._id);
  //           this.getLevelSpecificCommonCourses(stream._id);

  //           // let item = {};
  //           // let keyName = `${response.data.courselevel}_${response.data.branch}`;
  //           // item[keyName] = response.data._id;
  //           // this.form.patchValue(item);

  //           // this.isSubmit = true;
  //           // this.btntext = "Submit";
  //           // this.onReset();
  //           // setTimeout(() => {
  //           //   this.isSubmit = false;
  //           // }, 3000);
  //         } else {
  //           console.log("Unable to register the course");
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //         // this.isLoading = false;
  //         // this.isdisabled = false;
  //         // this.isSubmit = false;
  //       }
  //     );
  // }

  searchCourse(courseName: String, stream: any) {
    console.log("courseName : ", courseName);
    console.log("Stream : ", stream);
  }

  // checkBoxValue: boolean = true;

  handleCheckboxClick(event: any, element: any, stream: any) {
    // console.log(event, " : event");
    // console.log(element, " : specifciCourse");
    // console.log(stream, " : stream");

    // console.log(this.newCoursesToApply[element.stream._id]);

    if (this.appliedCourses[stream._id].includes(element._id)) {
      ///////////////////////---------------------------------------------------------------------> WIP
      console.log("Remove Course Handler invoked!");
      if (this.appliedCoursesRecord[stream._id].includes(element._id)) {
        if (this.coursesToRemove[stream._id]) {
          this.coursesToRemove[stream._id].push(element._id);
        } else {
          this.coursesToRemove[stream._id] = [];
          this.coursesToRemove[stream._id].push(element._id);
        }
      }
      const index = this.appliedCourses[stream._id].indexOf(element._id);
      if (index !== -1) {
        this.appliedCourses[stream._id].splice(index, 1);
      }

      if (this.newCoursesToApply[stream._id]) {
        const index2 = this.newCoursesToApply[stream._id].indexOf(element._id);
        if (index2 !== -1) {
          this.newCoursesToApply[stream._id].splice(index2, 1);
        }
      }

      console.log(
        this.appliedCourses,
        " : this.appliedCourse After remove onclick event"
      );
      console.log(
        this.appliedCoursesRecord,
        " : this.appliedCoursesRecord After Add onclick event"
      );
      console.log(
        this.coursesToRemove,
        " : this.coursesToRemove After remove onclick event"
      );
      console.log(
        this.newCoursesToApply,
        " : this.newCoursesToApply After Add onclick event"
      );
    } else {
      //////////////////////-----------------------------------------------------------------------> WIP
      console.log("Add Course handler invoked!");
      if (!this.appliedCoursesRecord[stream._id].includes(element._id)) {
        if (this.newCoursesToApply[stream._id]) {
          this.newCoursesToApply[stream._id].push(element._id);
        } else {
          this.newCoursesToApply[stream._id] = [];
          this.newCoursesToApply[stream._id].push(element._id);
        }
      }

      if (this.coursesToRemove[stream._id]) {
        let index = this.coursesToRemove[stream._id].indexOf(element._id);
        if (index !== -1) {
          this.coursesToRemove[stream._id].splice(index, 1);
        }
      }

      this.appliedCourses[stream._id].push(element._id);
      // const index = this.appliedCourses.indexOf(element._id);
      // if (index !== -1) {
      //   this.appliedCourses.splice(index, 1);
      // }
      console.log(
        this.appliedCourses,
        " : this.appliedCourse After Add onclick event"
      );
      console.log(
        this.appliedCoursesRecord,
        " : this.appliedCoursesRecord After Add onclick event"
      );
      console.log(
        this.newCoursesToApply,
        " : this.newCoursesToApply After Add onclick event"
      );
      console.log(
        this.coursesToRemove,
        " : this.coursesToRemove After remove onclick event"
      );
    }

    // if (!this.newCoursesToApply[specificCourse.stream._id]) {
    //   this.newCoursesToApply[specificCourse.stream._id] = [];
    // }
    // this.newCoursesToApply[specificCourse.stream._id].push(specificCourse._id);
    // console.log(this.newCoursesToApply, "newCourseToApply");
  }

  // addCoursesForThisCollege(specificCourse: any) {
  //   console.log(specificCourse, " : specificCourse");
  // }

  // removeCoursesForThisCollege(specificCourse: any) {
  //   console.log(specificCourse, " : specificCourse");
  // }

  applyCoursesForThisCollege(stream: any) {
    // console.log(this.newCoursesToApply, " : newCourseToApply");
    let payload: any = {
      newCoursesToApply: [],
      coursesToRemove: [],
    };
    if (this.newCoursesToApply[stream._id]) {
      payload.newCoursesToApply = this.newCoursesToApply[stream._id];
    }
    if (this.coursesToRemove[stream._id]) {
      payload.coursesToRemove = this.coursesToRemove[stream._id];
    }

    this.httpRequest
      .postRequest({
        url: `/v1courses/saveCollegeSpecificCommonCourse?collegeId=${this.id}&stream=${stream._id}`,
        // value: this.newCoursesToApply[stream._id],
        value: payload,
      })
      .subscribe((response: any) => {
        console.log(response, " : Common Courses Applied");
        // if (response.status === "success") {
        //   this.appliedCourses[stream] = response.responseFormat[stream];
        // }
        if (response.status === "success") {
          // this.getLevelSpecificCommonCourses(stream._id);
          this.getCollegeSpecificCommonCourses(stream._id);
          this.showSuccess();
        }
      });
  }

  // addCollegeSpecificCourse(item: any) {
  //   console.log(item, ": College specific course payload!");
  // }
}
