import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";

@Component({
  selector: "ranks-listing-page",
  templateUrl: "./ranks-listing-page.component.html",
  styleUrls: ["./ranks-listing-page.component.css"],
})
export class RanksListingPage implements OnInit {
  years: any[] = ["2020", "2021", "2022", "2023", "2024"];
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  BranchObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  subbranches: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      years: [""],
      branchid: [""],
      subbranchid: [""],
      // uri: [
      //   '',
      //   [Validators.pattern(/^[a-z0-9-]+$/)],
      // ],
      status: [""],
    });

    this.geBranch();
    this.fetchUriList();
    // Added listener for subscribe data
    this.httpRequest.getPostUpdateListener().subscribe((postData) => {
      this.isLoading = false;
      this.totalCount = postData.postCount;
      this.ObjectData = postData.posts;
      console.log("ObjectData");
      console.log(this.ObjectData);
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.BranchObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubBranchdata(branchObjectId) {
    console.log(branchObjectId);
    this.httpRequest
      .getRequest({
        url: "/subbranch/fetchsubbranch?branchid=" + branchObjectId,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subbranches = response.data;
          }
        },
        (error) => {}
      );
  }

  onSubmit() {
    this.fetchUriList();
  }

  // Pagination to change page
  onChangedPage(pageData) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchUriList();
  }

  // Fetch all data from database with page size
  fetchUriList() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&years=${this.form.value.years}&status=${this.form.value.status}&branchid=${this.form.value.branchid}&subbranchid=${this.form.value.subbranchid}`;
    this.httpRequest.getPosts({
      url: "/uri/getUriList" + queryParams,
    });
  }
}
