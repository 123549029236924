import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HttpRequestService } from "./../../services/http-request.service";
import { Router, ActivatedRoute } from "@angular/router"; // Import the Router module
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";

@Component({
  selector: "app-add-exam-study-abroad",
  templateUrl: "./add-exam-study-abroad.component.html",
  styleUrls: ["./add-exam-study-abroad.component.css"],
})
export class AddExamStudyAbroadComponent implements OnInit, AfterViewInit {
  @ViewChild("editor", { static: false }) editorComponent: CKEditorComponent;

  public editorFields = [
    { key: "title", label: "Title", content: "" },
    { key: "short_description", label: "Short Description", content: "" },

    { key: "overview", label: "Overview", content: "" },
    {
      key: "examEligibilityCriteria",
      label: "Exam Eligibility Criteria",
      content: "",
    },
    { key: "patternsyllabus", label: "Pattern & syllabus", content: "" },
    // { key: "highlights", label: " Highlights", content: "" },
    // { key: "examStructure", label: "Exam Structure", content: "" },
    // { key: "examPattern", label: "Exam Pattern", content: "" },
    { key: "registration", label: "Exam Registration", content: "" },
    { key: "examFees", label: "Exam Fees", content: "" },
    { key: "highlights", label: "Highlights", content: "" },
    { key: "examdatecenter", label: "Exam Date Center", content: "" },
    { key: "pretips", label: "preTips", content: "" },
    { key: "heading", label: "heading", content: "" },
    { key: "short_form", label: "short form", content: "" },

    // { key: "title", label: "Title", content: "" },
  ];
  public examId: any;
  public metaTitle: string = "";
  public metaDescription: string = "";
  public metaKeywords: string = "";
  // public seoMetaName: string = "";
  // public seoMetaContent: string = "";
  public seoMetaList: { metaName: string; metaContent: string }[] = [];
  public text: any;
  public faqs: any[] = []; // Array to hold FAQ objects
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfig = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  // Function to add a new FAQ field

  constructor(
    private httpRequest: HttpRequestService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  // const seo = {
  //   meta: [
  //     {
  //       metaName: this.editorFields[12].content,
  //       metaContent: this.editorFields[13].content,
  //     },
  //   ],
  // };
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.examId = params["_id"];
      if (this.examId) {
        this.text = `Edit Exam: ${this.examId}`;
        this.fetchExamDataForEdit(this.examId);
      }
    });
    console.log(this.examId, "dsfeferfer");
  }

  ngAfterViewInit() {
    // Initialize CKEditor content after view initialization
    // this.setCKEditorContent();
  }

  // Function to add a new SEO meta data field
  addSEOMeta() {
    this.seoMetaList.push({ metaName: "", metaContent: "" });
  }

  // Function to remove an SEO meta data field by index
  removeSEOMeta(index: number) {
    this.seoMetaList.splice(index, 1);
  }
  addFAQ() {
    this.faqs.push({ question: "", answer: "" });
  }

  // Function to remove a FAQ field by index
  removeFAQ(index: number) {
    this.faqs.splice(index, 1);
  }
  submitData() {
    const payload = {};
    this.editorFields.forEach((field) => {
      payload[field.key] = field.content;
    });
    // Create an array to hold the SEO meta data
    const seoMetaArray = this.seoMetaList.map((seo) => ({
      metaName: seo.metaName,
      metaContent: seo.metaContent,
    }));
    const titleAndDescription = {
      title: this.editorFields[0].content,
      short_description: this.editorFields[1].content,
      short_form: this.editorFields[11].content,
      faqs: this.faqs,
      seo: {
        meta: seoMetaArray,
        metaTitle: this.metaTitle,
        metaDescription: this.metaDescription,
        metaKeywords: this.metaKeywords,
      }, // Add the SEO meta data here
    };

    // Update with your API submit endpoint
    let submitUrl = "/examStudyabroad/abroadexam";

    if (this.examId) {
      // If examId is present, it means you want to update existing data using PATCH
      submitUrl += `/${this.examId}`;
      const combinedPayload = { ...payload, ...titleAndDescription };

      this.httpRequest
        .patchRequest({ url: submitUrl, value: combinedPayload })
        .subscribe((response) => {
          console.log("Data updated successfully.");
          // Optionally, you can reset the content after submission
          this.editorFields.forEach((field) => {
            field.content = "";
          });

          // After updating the data, you might want to navigate to a different page
          this.router.navigate(["/v1/manageexam"]);
        });
    } else {
      const combinedPayload = { ...payload, ...titleAndDescription };

      // If examId is not present, it means you want to submit new data using POST
      this.httpRequest
        .postRequest({ url: submitUrl, value: combinedPayload })
        .subscribe((response) => {
          console.log("Data submitted successfully.");
          // Navigate to the /manageexam page
          this.router.navigate(["/v1/manageexam"]);
          // Optionally, you can reset the content after submission
          // this.editorFields.forEach(field => {
          //   field.content = "";
          // });
        });
    }
  }

  fetchExamDataForEdit(examId: string) {
    const apiUrl = `/examStudyabroad/get-exam?_id=${examId}`;

    this.httpRequest.getRequest({ url: apiUrl }).subscribe((data: any) => {
      // Assuming data structure matches the response you've shown
      if (data && data.data && data.data.length > 0 && data.data[0].faqs) {
        // If the response structure has an array under "data" property
        this.editorFields[0].content = data.data[0].title || "";
        this.editorFields[1].content = data.data[0].short_description || "";

        this.editorFields[7].content = data.data[0].highlights || "";
        this.editorFields[2].content = data.data[0].overview || "";
        // this.editorFields[2].content = data.data[0].examHighlights || "";
        this.editorFields[3].content =
          data.data[0].examEligibilityCriteria || "";
        // this.editorFields[5].content = data.data[0].examStructure || "";
        this.editorFields[4].content = data.data[0].patternsyllabus || "";
        this.editorFields[5].content = data.data[0].registration || "";
        // this.editorFields[8].content = data.data[0].examRegistration || "";
        this.editorFields[6].content = data.data[0].examFees || "";
        this.editorFields[8].content = data.data[0].examdatecenter || "";
        this.editorFields[9].content = data.data[0].pretips || "";
        this.editorFields[10].content = data.data[0].heading || "";
        this.editorFields[11].content = data.data[0].short_form || "";
        // SEO meta data edit
        if (data.data[0].seo) {
          this.metaTitle = data.data[0].seo.metaTitle;
          this.metaDescription = data.data[0].seo.metaDescription;
          this.metaKeywords = data.data[0].seo.metaKeywords;
          this.seoMetaList = [...data.data[0].seo.meta];
        }
        this.faqs = data.data[0].faqs || "";
      } else {
        this.editorFields[0].content = data.data[0].title || "";
        this.editorFields[7].content = data.data[0].highlights || "";
        this.editorFields[2].content = data.data[0].overview || "";
        // this.editorFields[2].content = data.data[0].examHighlights || "";
        this.editorFields[3].content =
          data.data[0].examEligibilityCriteria || "";
        // this.editorFields[5].content = data.data[0].examStructure || "";
        this.editorFields[4].content = data.data[0].patternsyllabus || "";
        this.editorFields[5].content = data.data[0].registration || "";
        // this.editorFields[8].content = data.data[0].examRegistration || "";
        this.editorFields[6].content = data.data[0].examFees || "";
        this.editorFields[1].content = data.data[0].short_description || "";
        this.editorFields[8].content = data.data[0].examdatecenter || "";
        this.editorFields[9].content = data.data[0].pretips || "";
        this.editorFields[10].content = data.data[0].heading || "";
        this.editorFields[11].content = data.data[0].short_form || "";
        this.faqs = data.data[0].faqs || "";
      }

      console.log(this.editorFields[0].content, data.title, "jhbhuhbhbih");

      // Once the content is set, you can initialize CKEditor
      // this.setCKEditorContent();
    });
  }
}
