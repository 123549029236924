import { Component, OnInit, ElementRef, Renderer2 } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn,
  ValidationErrors,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { CsvService } from "./../../services/csv-create.service";
import { environment } from "./../../../environments/environment";

@Component({
  selector: "app-online-course-images",
  templateUrl: "./online-course-images.component.html",
  styleUrls: ["./online-course-images.component.css"],
})
export class OnlineCourseImagesComponent implements OnInit {
  submitted = false;
  Searchform: FormGroup;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  allowedFileExtensions = ["jpg", "jpeg", "png"];
  imagePreview: string;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [1, 10, 20, 50, 100, 150];
  importbtntext = "Import from CSV";
  ImagePreview: any[] = [];
  ImageBaseUrl = "";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private csvService: CsvService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      imagetype: ["", Validators.required],
      images: [[], [Validators.required], [this.imageFilesValidator()]],
    });
    this.Searchform = this.formBuilder.group({
      imagetype: [""],
    });
    this.gefetchImages();
  }

  /*  imageFilesValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const files = control.value;
      const imagetype = this.form.get('imagetype').value;
      console.log('imagetype: '+ imagetype);
      return new Promise(async (resolve) => {
        if (files) {
          if (imagetype == '1') {
            const maxWidth = 60; // Set your maximum width
            const maxHeight = 60; // Set your maximum height
          } else {
            const maxWidth = 800; // Set your maximum width
            const maxHeight = 600; // Set your maximum height
          }
         
          console.log('maxWidth: '+maxWidth, "maxHeight: " + maxHeight);
          for (const file of files) {
            const image = new Image();
            
            // Wait for the image to load
            await new Promise((imageLoadResolve) => {
              image.onload = () => {
                imageLoadResolve();
              };
              image.src = URL.createObjectURL(file);
            });
            console.log(image.width, "height: " + image.height);
            // Check the dimensions
            if (image.width > maxWidth || image.height > maxHeight) {
              resolve({ imageSizeError: true });
              return;
            }
          }
        }

        resolve(null);
      });
    };
  } */

  // imageFilesValidator(): AsyncValidatorFn {
  imageFilesValidator() {
    return (control: AbstractControl): Promise<ValidationErrors | null> => {
      const files = control.value;
      // const status = (control.parent as FormGroup)?.controls['imagetype'].value; // Access the status control using control.parent

      const status = this.form.get("imagetype").value;

      return new Promise<ValidationErrors | null>(async (resolve) => {
        if (status == 1 && files) {
          const isValid = await this.checkImageSize(files, 60, 60);
          return isValid ? resolve(null) : resolve({ imageSizeError: true });
        } else if (status == 2 && files) {
          const isValid = await this.checkImageSize(files, 600, 400);
          return isValid ? resolve(null) : resolve({ imageSizeError: true });
        }
        resolve(null);
      });
    };
  }

  private async checkImageSize(
    files: File[],
    maxWidth: number,
    maxHeight: number
  ): Promise<boolean> {
    for (const file of files) {
      const image = new Image();
      console.log("maxWidth: " + maxWidth + "maxHeight: " + maxHeight);
      // Wrap image loading in a Promise
      const loadPromise = new Promise<void>((loadResolve) => {
        image.onload = () => loadResolve();
        image.src = URL.createObjectURL(file);
      });

      await loadPromise;

      // Check the dimensions
      if (image.width > maxWidth || image.height > maxHeight) {
        return false;
      }
    }

    return true;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  gefetchImages() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
    this.httpRequest
      .postRequest({
        url: "/online-course-images/fetchImages" + queryParams,
        value: JSON.stringify(this.Searchform.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.posts.length > 0) {
              this.ObjectData = response.posts;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectData = [];
              this.totalCount = 0;
              this.postsPerPage = 10;
              this.currentPage = 1;
            }
          } else {
            this.ObjectData = [];
            this.totalCount = 0;
            this.postsPerPage = 10;
            this.currentPage = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // file format validation
  fileUploadValidator(allowedExtensions: any): ValidatorFn {
    console.log("inside validation");
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // Enter to validation only if has value or it's not undefined
      if (control.value !== undefined && isNaN(control.value)) {
        const file = control.value;
        // Get extension from file name
        const ext = file.substring(file.lastIndexOf(".") + 1);
        // Find extension file inside allowed extensions array
        if (allowedExtensions.includes(ext.toLowerCase())) {
        } else {
          return { extensionFile: true };
        }
      }
      return null;
    };
  }

  onImagePicked(event) {
    console.log(event);
    this.ImagePreview = [];
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ images: files });
    // this.form.patchValue({ imagename: files });
    console.log("files :", files);

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.ImagePreview.push(reader.result as string);
        console.log(this.ImagePreview, " : this.ImagePreview");
      };
      reader.readAsDataURL(files[i]);
    }
  }

  onSubmit() {
    this.submitted = true;
    this.form.updateValueAndValidity();
    if (this.form.invalid) {
      return;
    }
    let postData = new FormData();
    if (this.form.value.imagetype) {
      postData.append("imagetype", this.form.value.imagetype);
    }

    if (this.form.value.images && this.form.value.images.length > 0) {
      for (var i = 0; i < this.form.value.images.length; i++) {
        postData.append("images", this.form.value.images[i]);
      }
    }
    console.log(postData);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    this.httpRequest
      .postRequest({
        url: "/online-course-images/saveimages",
        value: postData,
        // headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          this.isdisabled = false;
          if (response.status == "success") {
            this.gefetchImages();
            this.isSubmit = true;
            setTimeout(() => {
              this.isSubmit = false;
              this.btntext = "Submit";
              //this.onReset();
            }, 2000);
          } else {
            this.isLoading = false;
            this.btntext = "Submit";
            this.isdisabled = false;
          }
        },
        (error) => {
          this.isLoading = false;
          this.btntext = "Submit";
          this.isdisabled = false;
        }
      );
  }

  /* To copy any Text */
  copyText(val: string, i) {
    let selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
    //var tooltip = document.getElementById("myTooltip");
    //  let tooltip: HTMLElement = document.getElementById('myTooltip'+i) as HTMLElement;
    // tooltip.innerHTML = "Copied";
    let tooltipElement = this.el.nativeElement.querySelector("#myTooltip" + i);
    if (tooltipElement) {
      this.renderer.setProperty(tooltipElement, "textContent", "Copied");

      setTimeout(() => {
        this.renderer.setProperty(
          tooltipElement,
          "textContent",
          "Copy to clipboard"
        );
      }, 1000);
    }
  }

  // Pagination to change page
  onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.gefetchImages();
  }

  onSearchSubmit() {
    console.log(this.Searchform.value.categoryid);
    this.gefetchImages();
  }

  deleteImage(Object: any) {
    console.log(Object);
    if (Object._id) {
      if (confirm("Are you sure to delete this image/logo")) {
        this.httpRequest
          .deleteRequest({
            url: "/online-course-images/delete-image/" + Object._id,
            headers: { "content-type": "application/json" },
          })
          .subscribe(
            (response: any) => {
              console.log(response);
              if (response.status == "success") {
                let b: HTMLElement = document.getElementById(
                  "success-modal"
                ) as HTMLElement;
                b.click();
              }
              this.gefetchImages();
            },
            (error) => {
              console.log(error);
            }
          );
      }
    }
  }
}
