import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { ActivatedRoute, ParamMap } from "@angular/router";

@Component({
  selector: 'app-courses-faq',
  templateUrl: './courses-faq.component.html',
  styleUrls: ['./courses-faq.component.css']
})
export class CoursesFaqComponent implements OnInit {

  submitted = false;
  form: FormGroup;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      title: ["", Validators.required],
      status: ["1", Validators.required],
      description: ['', Validators.required]
    });
    this.geCategoryFaq();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.form.value.courseid = this.router.snapshot.params.id;
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/onlinecourse/updatefaq/"+this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.isSubmit = true;
              this.geCategoryFaq();
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = false;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/onlinecourse/savefaq",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.geCategoryFaq();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  geCategoryFaq() {
    this.httpRequest
      .getRequest({
        url:
          "/onlinecourse/fetchfaq?courseid=" + this.router.snapshot.params.id,
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  editform(Object) {
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        title: this.SingleObjectData.title,
        status: this.SingleObjectData.status,
        description: this.SingleObjectData.description,

      });
    }
  }

}
