import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { CsvService } from "./../../services/csv-create.service";
import { environment } from "./../../../environments/environment";
@Component({
  selector: "app-provider",
  templateUrl: "./provider.component.html",
  styleUrls: ["./provider.component.css"],
})
export class ProviderComponent implements OnInit {
  submitted = false;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  allowedFileExtensions = ['jpg', 'jpeg', 'png'];
  imagePreview: string;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [1,10, 20, 50, 100, 150];
  importbtntext = "Import from CSV";
  ImageBaseUrl = '';
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private csvService: CsvService
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
  }



  ngOnInit() {
    this.Searchform = this.formBuilder.group({
      provider_name: [""],
      providerexistence: [""],
      providertype: [""],
      status: [""]
    });
    this.geProvider();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.Searchform.controls;
  }
  // file format validation
  fileUploadValidator(allowedExtensions: any): ValidatorFn {
    console.log('inside validation');
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // Enter to validation only if has value or it's not undefined
      if (control.value !== undefined && isNaN(control.value)) {
        const file = control.value;
        // Get extension from file name
        const ext = file.substring(file.lastIndexOf('.') + 1);
        // Find extension file inside allowed extensions array
        if (allowedExtensions.includes(ext.toLowerCase())) {
        } else {
          return { extensionFile: true };
        }
      }
      return null;
    };
  }



  geProvider() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
    this.httpRequest
      .postRequest({
        url: "/provider/fetchProvider"+queryParams,
        value: JSON.stringify(this.Searchform.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.posts.length > 0) {
              this.ObjectData = response.posts;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectData = [];
              this.totalCount = 0;
              this.postsPerPage = 10;
              this.currentPage = 1;
            }
          } else {
            this.ObjectData = [];
            this.totalCount = 0;
            this.postsPerPage = 10;
            this.currentPage = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // onSubmit() {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   console.log(this.form.value);
  //   this.isLoading = true;
  //   this.isdisabled = true;
  //   this.btntext = "Processing ...";
  //   console.log(this.objectId);
  //   if (this.objectId) {
  //     this.httpRequest
  //       .putRequest({
  //         url: "/provider/updateProvider/" + this.objectId,
  //         value: JSON.stringify(this.form.value),
  //       //  headers: { "content-type": "application/json" },
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           console.log(response.status);
  //           this.isLoading = false;
  //           if (response.status == "success") {
  //             this.objectId = "";
  //             this.geProvider();
  //             this.isSubmit = true;
  //             this.onReset();
  //             setTimeout(() => {
  //               this.isSubmit = true;
  //               this.btntext = "Submit";
  //               this.isdisabled = false;
  //             }, 1000);
  //           } else {
  //             this.isLoading = false;
  //             this.btntext = "Submit";
  //             this.isdisabled = false;
  //           }
  //         },
  //         (error) => {
  //           this.isLoading = false;
  //           this.btntext = "Submit";
  //           this.isdisabled = false;
  //         }
  //       );
  //   } else {
  //     this.httpRequest
  //       .postRequest({
  //         url: "/provider/saveProvider",
  //         value: JSON.stringify(this.form.value),
  //        // headers: { "content-type": "application/json" },
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           this.isLoading = false;

  //           this.isdisabled = false;
  //           if (response.status == "success") {
  //             this.geProvider();
  //             this.isSubmit = true;
  //             setTimeout(() => {
  //               this.isSubmit = false;
  //               this.btntext = "Submit";
  //               this.onReset();
  //             }, 2000);
  //           } else {
  //             this.isLoading = false;
  //             this.btntext = "Submit";
  //             this.isdisabled = false;
  //           }
  //         },
  //         (error) => {
  //           this.isLoading = false;
  //           this.btntext = "Submit";
  //           this.isdisabled = false;
  //         }
  //       );
  //   }
  // }



  // Pagination to change page
  onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.geProvider();
  }


  onSubmit() {
    console.log(this.Searchform.value.categoryid);
    this.geProvider()
  }

  displayStyle: any = "none";
  openPopup() {
    // setTimeout(() => {
    this.displayStyle = "block";
    // }, 10000);
  }

  closePopup() {
    this.displayStyle = "none";
  }
  export_sample_csv_file() {
    console.log('hi csv file');
     // Example CSV data
     const csvData = 'provider_name,providerexistence,providertype,overview,logoImagePath\nLinkedIn,1,3, hi this is linked in  provider,https://stage-api.iirfranking.com/admin/upload//upload__1696867077592.png';

     // Specify the filename
     const filename = 'Providers.csv';
 
     // Use the CsvService to download the file
     this.csvService.downloadFile(csvData, filename);
  }

    // Handlers function for parsing CSV data and firing post API request
  // fileName: string = "";
  displayCsvData: any = [];
  // files: any = "";
  csvButtonChange(event: Event) {
    this.isLoading = true;
    this.isdisabled = true;
    this.importbtntext = "Processing";
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0) {
      alert("Please Choose any file!...");
      return;
    }
    const file: File = (target.files as FileList)[0];
    console.log("** File : ", file);
    let fileName = file.name;
    // let interimData = [];
    let extension = fileName.substring(fileName.lastIndexOf(".")).toUpperCase();
    if (extension == ".CSV") {
      //Here calling another method to read CSV file into json
      try {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          console.log(e.target.result, " : e.target.result");
          let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\n");
          console.log(rows, " : **rows");
          for (let i = 0; i < rows.length - 1; i++) {
            // Handler function to support , inside cells
            let str = rows[i];
            let cells = [];
            let inQuotes = false;
            let current = "";
            for (let h = 0; h < str.length; h++) {
              // if (str[h] === '"' || str[h] === "'") {
              if (str[h] === '"') {
                inQuotes = !inQuotes;
              }
              if (str[h] === "," && !inQuotes) {
                cells.push(current);
                current = "";
              } else {
                // if (str[h] === '"' || str[h] === "'") {
                if (str[h] === '"') {
                  // Do nothing
                } else {
                  current += str[h];
                }
              }
            }
            cells.push(current); // Push the last item
            // Handler function to support , inside cells
            // let cells = rows[i].split(",");

            let rowData = {};
            for (let j = 0; j < cells.length; j++) {
              if (i == 0) {
                let headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                let key = headers[j];
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i != 0) {
              jsonData.push(rowData);
            }
          }

          console.log("display_csv_data : ", jsonData);
          // Handler function to submit this data...
          this.httpRequest
          .postRequest({
            url: "/provider/saveProvider?type=bulkupload",
            value: jsonData,
           // headers: { "content-type": "application/json" },
          })
            .subscribe(
              (response: any) => {
                this.isLoading = false;
                this.isdisabled = false;
                if (response.status == "success") {
                  this.isSubmit = true;
                  // this.btntext = "Submit";
                  this.importbtntext = "Import from CSV";
                  this.geProvider();
                  this.closePopup();
                  let b: HTMLElement = document.getElementById('success-modal') as HTMLElement;
                  b.click();
                  // this.onReset();
                  setTimeout(() => {
                    this.isSubmit = false;
                  }, 3000);
                } else {
                }
              },
              (error) => {
                this.isdisabled = false;
                this.isLoading = false;
                this.importbtntext = "Import from CSV";
              }
            );
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Please select a valid csv file.");
    }
  }
}
