import { Component, OnInit, HostListener } from "@angular/core";

@Component({
  selector: "app-scroll-to-top-button",
  templateUrl: "./scroll-to-top-button.component.html",
  styleUrls: ["./scroll-to-top-button.component.css"],
})
export class ScrollToTopButtonComponent implements OnInit {
  showButton: boolean = false;
  constructor() {}

  ngOnInit() {}

  @HostListener("window:scroll")
  onWindowScroll() {
    this.showButton = window.pageYOffset > 20;
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
}
