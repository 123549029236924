import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { HttpRequestService } from "../../services/http-request.service";

@Component({
  selector: 'app-online-course-user-enquery',
  templateUrl: './online-course-user-enquery.component.html',
  styleUrls: ['./online-course-user-enquery.component.css']
})
export class OnlineCourseUserEnqueryComponent implements OnInit {

  submitted = false;
  Searchform: FormGroup;
  objectId: any;
  ObjectData:  any[] = [];
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.Searchform = this.formBuilder.group({
      fromDate: [""],
      toDate: [""],
      status: [""],
    });
    this.geUserEnqueryList();
  }

  geUserEnqueryList() { 
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
    this.isLoading = true;
    this.httpRequest
      .postRequest({
        url:
          "/course-services/user-enquiry/fetchUserEnquiry"+queryParams,
        value: JSON.stringify(this.Searchform.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          console.log(response);
          if (response.status == "success") {
            if (response.posts.length > 0) {
              this.ObjectData = response.posts;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectData = [];
              this.totalCount = 0;
              this.postsPerPage = 10;
              this.currentPage = 1;
            }
          } else {
            this.ObjectData = [];
            this.totalCount = 0;
            this.postsPerPage = 10;
            this.currentPage = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

   // Pagination to change page
   onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.geUserEnqueryList();
  }

  // onchange approved/cancelled rating status
  onSelectChange(id:any, event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const obj = {
        id:id,
        status:selectedValue
    }
    this.isLoading = true;
    this.httpRequest
      .postRequest({
        url:"/course-services/user-enquiry/updateUserStatus",
        value: obj,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          console.log(response);
          if (response.status == "success") {
            this.geUserEnqueryList()
              //console.log("response:::",response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    console.log(this.Searchform.value.created);
    this.geUserEnqueryList()
  }

}
