import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";import { Component, OnInit, ViewChild } from '@angular/core';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: 'app-child-category',
  templateUrl: './child-category.component.html',
  styleUrls: ['./child-category.component.css']
})
export class ChildCategoryComponent implements OnInit {
  @ViewChild("editor", { static: false }) editor: any; // Add this line for CKEditor
  @ViewChild("metaeditor", { static: false }) metaeditor: any; // Add this line for CKEditor
  submitted = false;
  form: FormGroup;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  ObjectCategoryData:  any[] = [];
  SubCategoryArray:  any[] = [];
  SearchSubCategoryArray:  any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  yearsArray: any[] = [];
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      categoryid: ["", Validators.required],
      subcategoryid: ["", Validators.required],
      childname: ["", Validators.required],
      title: ["", Validators.required],
      displayorder: ["", Validators.required],
      status: ["1", Validators.required],
      description: [''],
      pagetitle: [''],
      metatitle: [''],
      keywords: [''],
      metadescription: [''],
      displayinpages: [],
      slug: ["", [Validators.pattern('^[a-z0-9-]*$')]],
    });

    this.Searchform = this.formBuilder.group({
      categoryid: [""],
      subcategoryid: [""],
      childname: [""],
    });
    this.geCategory();
    this.geChildCategory()
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geCategory() {
    this.httpRequest
      .getRequest({
        url:
          "/category/fetchallcategory?status=1",
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectCategoryData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
              this.geChildCategory();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }


  getSubCategory(categoryid = 0, issearch = 0) {
    console.log(categoryid);
    this.httpRequest.getRequest({
      url: '/subcategory/fetchallsubcategory?status=1&categoryid='+categoryid
      }).
      subscribe((response: any) => {
       
        if (response.status == 'success') {
          if(issearch){
            this.SearchSubCategoryArray =response.data;
          } else {
            this.SubCategoryArray = response.data;
          }
         
        } else {
          this.SubCategoryArray = [];   
        }
      },
      error => {
      }
    );
  }

  // search function 
  geChildCategory() {
    this.httpRequest
      .getRequest({
        url:
          "/subcategory/fetchchildcategory?categoryid=" + this.Searchform.value.categoryid+'&subcategoryid='+this.Searchform.value.subcategoryid+'&childname='+this.Searchform.value.childname,
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    console.log(this.objectId);
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/subcategory/updatechildcategory/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              //this.Searchform.setValue({ categoryid: this.form.value.categoryid });
              this.geChildCategory();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = false;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/subcategory/savechildcategory",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              //this.Searchform.setValue({ categoryid: this.form.value.categoryid });
              this.geChildCategory();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object) {
    console.log(Object);
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.getSubCategory(this.SingleObjectData.categoryid._id)
      this.form.patchValue({
        categoryid: this.SingleObjectData.categoryid._id,
        subcategoryid: this.SingleObjectData.subcategoryid._id,
        childname: this.SingleObjectData.childname,
        title: this.SingleObjectData.title,
        displayorder: this.SingleObjectData.displayorder,
        status: this.SingleObjectData.status,
        description: this.SingleObjectData.description,
        pagetitle: this.SingleObjectData.pagetitle,
        metatitle: this.SingleObjectData.metatitle,
        keywords: this.SingleObjectData.keywords,
        metadescription: this.SingleObjectData.metadescription,
        displayinpages: this.SingleObjectData.displayinpages,
        slug: this.SingleObjectData.slug,
      });

      if (this.SingleObjectData.description) {
        this.editor.editorInstance.setData(
          this.SingleObjectData.description
        );
      }
      if (this.SingleObjectData.metadescription) {
        this.metaeditor.editorInstance.setData(
          this.SingleObjectData.metadescription
        );
      }
    }
  }

  onSubmitCategory() {
    console.log(this.Searchform.value.categoryid);
    this.geChildCategory();
  }

}
