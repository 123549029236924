import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatTabsModule } from "@angular/material/tabs";
import { AngularEditorModule } from "@kolkov/angular-editor";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BranchesComponent } from "./branches/branches.component";
import { V1RoutingModule } from "./v1-routing.module";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
} from "@angular/material";
import { CdkTableModule } from "@angular/cdk/table";

import { CollegesComponent } from "./colleges/colleges.component";
import { AddCollegeComponent } from "./colleges/add-college/add-college.component";
import { EditCollegeComponent } from "./colleges/edit-college/edit-college.component";
import { AbroadCollegesComponent } from "./abroad-colleges/colleges.component";
import { AddAbroadCollegeComponent } from "./abroad-colleges/add-college/add-college.component";
import { EditAbroadCollegeComponent } from "./abroad-colleges/edit-college/edit-college.component";
import { ImportCsvCoursesComponent } from "./import-csv-courses/import-csv-courses.component";

import { SubBrancheComponent } from "./branches/sub-branche/sub-branche.component";
import { UriComponent } from "./uri/uri.component";
import { AddUriComponent } from "./uri/add-uri/add-uri.component";
import { EditUriComponent } from "./uri/edit-uri/edit-uri.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { YearsComponent } from "./years/years.component";
import { CollegeTypeComponent } from "./college-type/college-type.component";
import { BranchmappingComponent } from "./branches/branchmapping/branchmapping.component";
import { FaqComponent } from "./faq/faq.component";
import { AddFaqComponent } from "./faq/add-faq/add-faq.component";
import { EditFaqComponent } from "./faq/edit-faq/edit-faq.component";
import { CountriesComponent } from "./countries/countries.component";
import { SubscriberList } from "./subscriber-list/subscriber-list.component";
import { RanksListingPage } from "./ranks-listing-page/ranks-listing-page.component";
import { ImportBulkImages } from "./import-bulk-images/import-bulk-images.component";
import { ChildBranchesComponent } from "./branches/sub-branche/child-branches/child-branches.component";
// Import Courses Components
import { CourseLevelComponent } from "./course-section/course-level.component";
import { ProgramTypeComponent } from "./course-section/program-type/program-type.component";
import { StreamComponent } from "./course-section/stream/stream.component";
import { SubStreamComponent } from "./course-section/stream/substream/sub-stream.component";

import { CommonCourseComponent } from "./common-course-section/common-course.component";
import { LevelSpecificCommonCourseComponent } from "./common-course-section/level-specific-common-courses/level-specific-common-course.component";

import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CollegesCoursesComponent } from "./colleges/add-college-course/add-college-course.component";
// import { ExamAbroadComponent } from './exam-abroad/exam-abroad.component';
import { AddExamStudyAbroadComponent } from "./add-exam-study-abroad/add-exam-study-abroad.component";
import { StudyAbroadExamListingComponent } from "./study-abroad-exam-listing/study-abroad-exam-listing.component";
import { AddCountryComponent } from "./countries/add-country/add-country.component";
import { EditCountryComponent } from "./countries/edit-country/edit-country.component";
import { ProviderComponent } from "./provider/provider.component";
import { DurationComponent } from "./duration/duration.component";
import { CategoryComponent } from "./category/category.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { OnlineCourseComponent } from "./online-course/online-course.component";
import { AddOnlineCourseComponent } from "./online-course/add-online-course/add-online-course.component";
import { EditOnlineCourseComponent } from "./online-course/edit-online-course/edit-online-course.component";
import { ChildCategoryComponent } from "./child-category/child-category.component";
import { AddProviderComponent } from "./provider/add-provider/add-provider.component";
import { EditProviderComponent } from "./provider/edit-provider/edit-provider.component";
import { OnlineRatingComponent } from "./online-rating/online-rating.component";
import { OnlineCourseLevelComponent } from "./online-course-config/online-course-level.component";
import { OnlineProgramTypeComponent } from "./online-course-config/online-program-type/online-program-type.component";
import { OnlineStreamComponent } from "./online-course-config/online-stream/online-stream.component";
import { OnlineSubStreamComponent } from "./online-course-config/online-stream/online-substream/online-sub-stream.component";
import { CatFaqComponent } from "./category/cat-faq/cat-faq.component";
import { SubCatFaqComponent } from "./sub-category/sub-cat-faq/sub-cat-faq.component";
import { ChildCatFaqComponent } from "./child-category/child-cat-faq/child-cat-faq.component";
import { CoursesFaqComponent } from "./online-course/courses-faq/courses-faq.component";
import { UpdateCommonCourseContentComponent } from "./common-course-section/update-common-courses-content/update-common-courses-content.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { OnlineCourseImagesComponent } from "./online-course-images/online-course-images.component";
import { IIRFExamListingComponent } from "./iirf-exam-listing/iirf-exam-listing.component";
import { AddExamIIRFComponent } from "./add-exam-iirf/add-exam-iirf.component";
import { OnlineCourseUserEnqueryComponent } from './online-course-user-enquery/online-course-user-enquery.component';
@NgModule({
  declarations: [
    BranchesComponent,

    CollegesComponent,
    AddCollegeComponent,
    EditCollegeComponent,

    AbroadCollegesComponent,
    AddAbroadCollegeComponent,
    EditAbroadCollegeComponent,

    ImportCsvCoursesComponent,

    SubBrancheComponent,
    ChildBranchesComponent,
    UriComponent,
    AddUriComponent,
    EditUriComponent,
    YearsComponent,
    CollegeTypeComponent,
    BranchmappingComponent,
    FaqComponent,
    AddFaqComponent,
    EditFaqComponent,
    CountriesComponent,
    SubscriberList,
    RanksListingPage,
    ImportBulkImages,
    // Import Course details components
    CourseLevelComponent,
    ProgramTypeComponent,
    StreamComponent,
    SubStreamComponent,
    CollegesCoursesComponent,
    CommonCourseComponent,
    LevelSpecificCommonCourseComponent,
    // Import Online Course Config Components
    OnlineCourseLevelComponent,
    OnlineProgramTypeComponent,
    OnlineStreamComponent,
    OnlineSubStreamComponent,
    // ExamAbroadComponent,
    AddExamStudyAbroadComponent,
    AddExamIIRFComponent,
    StudyAbroadExamListingComponent,
    IIRFExamListingComponent,
    AddCountryComponent,
    EditCountryComponent,
    ProviderComponent,
    DurationComponent,
    CategoryComponent,
    SubCategoryComponent,
    OnlineCourseComponent,
    AddOnlineCourseComponent,
    EditOnlineCourseComponent,
    ChildCategoryComponent,
    AddProviderComponent,
    EditProviderComponent,
    OnlineRatingComponent,
    CatFaqComponent,
    SubCatFaqComponent,
    ChildCatFaqComponent,
    CoursesFaqComponent,
    UpdateCommonCourseContentComponent,
    OnlineCourseImagesComponent,
    OnlineCourseUserEnqueryComponent
  ],
  imports: [
    CommonModule,
    CdkTableModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    AngularEditorModule,
    FormsModule,
    ReactiveFormsModule,
    V1RoutingModule,
    NgSelectModule,
    CKEditorModule,
  ],
  // entryComponents: [ConfirmationDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class V1Module {}
