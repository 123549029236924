import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
@Component({
  selector: 'app-online-course',
  templateUrl: './online-course.component.html',
  styleUrls: ['./online-course.component.css']
})
export class OnlineCourseComponent implements OnInit {
  submitted = false;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  ObjectCategoryData:  any[] = [];
  ChildCategoryData:  any[] = [];
  ObjectCourseData:  any[] = [];
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  yearsArray: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [5,10, 20, 50, 100, 150];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.Searchform = this.formBuilder.group({
      categoryid: [""],
      subcategoryid: [""],
      childcategoryid: [""],
      coursetitle: [""],
      courselevel: [""],
      streamid: [""],
      programid: [""],
      status: [""],
      provideid: [""],
    });
    this.geCategory();
    this.geCourses();
  }

  geCategory() {
    this.ObjectCategoryData = [];
    this.httpRequest
      .getRequest({
        url:
          "/category/fetchallcategory?status=1",
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectCategoryData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geSubCategory(categoryid) {
    this.ObjectData = [];
    console.log(categoryid);
    if(categoryid){
    this.httpRequest
      .getRequest({
        url:
          "/subcategory/fetchallsubcategory?status=1&categoryid=" + categoryid,
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
            } else {
              this.ObjectData = [];
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  gechildCategory(subcategoryid) {
    this.ChildCategoryData = [];
    if(subcategoryid){
    this.httpRequest
      .getRequest({
        url:
          "/subcategory/fetchchildcategory?subcategoryid=" + subcategoryid,
         headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ChildCategoryData = response.data.sort(function (a: any, b: any) {
                return Number(a.displayorder) - Number(b.displayorder);
              });
            } else {
              this.ChildCategoryData = [];
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }


  geCourses() { 
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
    this.isLoading = true;
    this.httpRequest
      .postRequest({
        url:
          "/onlinecourse/fetch-onlinecourse"+queryParams,
        value: JSON.stringify(this.Searchform.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          console.log(response);
          if (response.status == "success") {
            if (response.posts.length > 0) {
              this.ObjectCourseData = response.posts;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectCourseData = [];
              this.totalCount = 0;
              this.postsPerPage = 10;
              this.currentPage = 1;
            }
          } else {
            this.ObjectCourseData = [];
            this.totalCount = 0;
            this.postsPerPage = 10;
            this.currentPage = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

   // Pagination to change page
   onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.geCourses();
  }


  onSubmit() {
    console.log(this.Searchform.value.categoryid);
    this.geCourses()
  }

}
