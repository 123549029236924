import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
// import { HttpRequestService } from "../../../services/http-request.service";
import { HttpRequestService } from "../../../../services/http-request.service";
@Component({
  selector: "app-online-sub-stream",
  templateUrl: "./online-sub-stream.component.html",
  styleUrls: ["./online-sub-stream.component.css"],
})
export class OnlineSubStreamComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SubObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  placeholderText: string = "Sub Stream";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      stream: ["", Validators.required],
      substream: ["", Validators.required],
      substreamslug: [""],
      status: ["1", Validators.required],
      enableonlinecourse: ["3"],
    });

    this.getStream();
    this.getSubStreams();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getStream() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchonlinestreams?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubStreams() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchonlinesubstreams",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.SubObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.form.value.stream == "") {
      this.form.removeControl("stream");
    }
    // console.log(this.objectId);
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/v1courses/updateonlinesubstream/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            if (!this.form.get("stream")) {
              this.form.addControl("stream", new FormControl(""));
            }
            // console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.getSubStreams();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {}
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/v1courses/saveonlinesubstream",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            if (!this.form.get("stream")) {
              this.form.addControl("stream", new FormControl(""));
            }
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.getSubStreams();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {}
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object: any) {
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        stream: this.SingleObjectData.stream
          ? this.SingleObjectData.stream._id
          : "",
        substream: this.SingleObjectData.substream,
        substreamslug: this.SingleObjectData.substreamslug,
        status: this.SingleObjectData.status,
        enableonlinecourse: this.SingleObjectData.enableonlinecourse,
      });
    }
  }

  changevalidation(val = 0) {
    const stream = <FormControl>this.form.get("stream");
    if (val == 3) {
      stream.setValidators(null);
      this.placeholderText = "Stream for online course";
    } else {
      stream.setValidators([Validators.required]);
      this.placeholderText = "Sub Stream";
    }
    stream.updateValueAndValidity();
  }
}
