import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
@Component({
  selector: "app-sub-branche",
  templateUrl: "./sub-branche.component.html",
  styleUrls: ["./sub-branche.component.css"],
})
export class SubBrancheComponent implements OnInit {
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "100px",
    minHeight: "100",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ],
  };
  submitted = false;
  form: FormGroup;
  Searchform: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SubObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  yearsArray: any[] = [];
  branches: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      // yearid:['',Validators.required],
      branchid: ["", Validators.required],
      subbranchname: ["", Validators.required],
      status: ["1", Validators.required],
      tabstatus: ["0", Validators.required],
      subbranchorder: ["1", Validators.required],
      //  subbranchuri: ['',Validators.pattern(/^[a-zA-Z0-9-]+$/)],
    });
    this.Searchform = this.formBuilder.group({
      branchid: [""],
    });

    //  this.geyears();
    this.geBranch();
    // this.geSubBranch();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // geyears() {
  //   this.httpRequest
  //     .getRequest({
  //       url: "/year/fetchYears?status=1",
  //       //  value:JSON.stringify(this.form.value),
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status == "success") {
  //           if (response.data.length > 0) {
  //             this.yearsArray = response.data;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.branches = response.data.sort(function (a: any, b: any) {
                return Number(a.branchorder) - Number(b.branchorder);
              });
              this.Searchform.setValue({ branchid: this.branches[0]._id });
              this.geSubBranch();
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  checktabstatus(objectID: any) {
    if (objectID) {
      this.httpRequest
        .getRequest({
          url: "/branch/getsingleBranch/" + objectID,
          //  value:JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            if (response.status == "success") {
              if (response.data.tabstatus && response.data.tabstatus == "1") {
                //this.form.controls['subbranchuri'].reset({ value: '', disabled: true });
              } else {
                // this.form.controls['subbranchuri'].reset({ value: '', disabled: false });
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  geSubBranch() {
    this.httpRequest
      .getRequest({
        url:
          "/subbranch/fetchsubbranch?branchid=" +
          this.Searchform.value.branchid,
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.SubObjectData = response.data.sort(function (
                a: any,
                b: any
              ) {
                return Number(a.subbranchorder) - Number(b.subbranchorder);
              });
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.form.value);
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    console.log(this.objectId);
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/subbranch/updatesubbranch/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.Searchform.setValue({ branchid: this.form.value.branchid });
              this.geSubBranch();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = false;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/subbranch/savesubbranch",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;
            this.isdisabled = false;
            if (response.status == "success") {
              this.Searchform.setValue({ branchid: this.form.value.branchid });
              this.geSubBranch();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            this.isSubmit = false;
            this.btntext = "Submit";
            this.isdisabled = false;
            this.isLoading = false;
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object) {
    console.log(Object);
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        //  yearid: this.SingleObjectData.yearid,
        branchid: this.SingleObjectData.branchid._id,
        subbranchname: this.SingleObjectData.subbranchname,
        subbranchorder: this.SingleObjectData.subbranchorder,
        //   subbranchuri: this.SingleObjectData.subbranchuri,
        status: this.SingleObjectData.status,
        tabstatus: this.SingleObjectData.tabstatus,
      });
    }
  }

  onSubmitBranch() {
    console.log(this.Searchform.value.branchid);
    this.geSubBranch();
  }
}
