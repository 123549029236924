import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { isPlatformBrowser } from "@angular/common";
// import { PLATFORM_ID } from "@angular/core";
import { ConfirmationDialogService } from "src/app/services/confirmation-prompt.service";
@Component({
  selector: "app-import-csv-courses",
  templateUrl: "./import-csv-courses.component.html",
  styleUrls: ["./import-csv-courses.component.css"],
})
export class ImportCsvCoursesComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  importbtntext = "Import from CSV";
  imagePreview: string;
  branches = []; // Initialize with your branch data

  mbaData: any = [];
  userId: string;
  userIsAuthenticated = false;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150, 300, 500];
  private postsSub: Subscription;
  private postsUpdated = new Subject<{ ObjectData; postCount: number }>();
  ImageBaseUrl: string = "";
  s3ImageUri: string = "";
  subbranches: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService, // @Inject(PLATFORM_ID) private platformId: any
    private confirmationDialogService: ConfirmationDialogService
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
    this.s3ImageUri = environment.s3ImageUri;
  }

  ngOnInit() {
    // this.form = this.formBuilder.group({
    //   collegename: [""],
    //   status: [""],
    //   branchid: ["", Validators.required],
    // });
    // this.geBranch();
    // // this.getSubBranchdata();
    // this.fetchcollege();
    // // Added listener for subscribe data
    // this.postsSub = this.httpRequest
    //   .getPostUpdateListener()
    //   .subscribe((postData) => {
    //     this.isLoading = false;
    //     this.totalCount = postData.postCount;
    //     this.ObjectData = postData.posts;
    //     console.log("ObjectData");
    //     console.log(this.ObjectData);
    //   });
  }

  // Pagination to change page
  onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchcollege();
    // this.getSubBranchdata();
  }

  // Fetch all data from database with page size
  // fetchcollege() {
  //   const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&collegename=${this.form.value.collegename}&status=${this.form.value.status}&branchid=${this.form.value.branchid}`;

  //   this.httpRequest.getPosts({
  //     url: "/college/fetchcollege" + queryParams,
  //   });
  // }
  fetchcollege() {
    let queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&collegename=${this.form.value.collegename}&status=${this.form.value.status}`;

    // Add branchid to queryParams if it's selected
    if (this.form.value.branchid) {
      queryParams += `&branchid=${this.form.value.branchid}`;
    }

    this.httpRequest.getPosts({
      url: "/college/fetchcollege" + queryParams,
    });
  }
  onBranchSelection() {
    // Call fetchcollege when the branch selection changes
    this.fetchcollege();
  }
  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response, "getSubBranchdata");
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.branches = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }
  // getSubBranchdata(branchObjectId) {
  //   console.log(branchObjectId,'branchObjectId');
  //   this.httpRequest
  //     .getRequest({
  //       url:
  //         "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         if (response.status == "success") {
  //           this.subbranches = response.data;
  //         } else {
  //         }
  //       },
  //       (error) => {}
  //     );
  // }

  onSubmit() {
    console.log(this.form.value);
    this.fetchcollege();
  }

  // Handler Section for Modal
  displayStyle: any = "none";
  openPopup() {
    // setTimeout(() => {
    this.displayStyle = "block";
    // }, 10000);
  }

  // AMS AMS AMS AMS    AMS AMS AMS AMS
  onDelete(itemId: string) {
    console.log(itemId, " : itemId******");
    this.confirmationDialogService
      .openConfirmationDialog()
      .then((confirmed: any) => {
        if (confirmed) {
          this.httpRequest
            .deleteRequest({
              url: "/college/deletecollege/" + itemId,
            })
            .subscribe((response: any) => {
              if (response.status == "success") {
                this.fetchcollege();
                console.log(response, " : Response *****");
              }
            });
        }
      });
  }

  // fileName: string = "";
  displayCsvData: any = [];
  // Handlers function for parsing CSV data and firing post API request
  // fileName: string = "";
  // displayCsvData: any = [];
  // files: any = "";
  csvButtonChange(event: Event) {
    this.isLoading = true;
    this.isdisabled = true;
    this.importbtntext = "Processing";
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0) {
      alert("Please Choose any file!...");
      return;
    }
    const file: File = (target.files as FileList)[0];
    console.log("** File : ", file);
    let fileName = file.name;
    // let interimData = [];
    let extension = fileName.substring(fileName.lastIndexOf(".")).toUpperCase();
    if (extension == ".CSV") {
      //Here calling another method to read CSV file into json
      try {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          console.log(e.target.result, " : e.target.result");
          let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\n");
          console.log(rows, " : **rows");
          for (let i = 0; i < rows.length - 1; i++) {
            // Handler function to support , inside cells
            let str = rows[i];
            let cells = [];
            let inQuotes = false;
            let current = "";
            for (let h = 0; h < str.length; h++) {
              // if (str[h] === '"' || str[h] === "'") {
              if (str[h] === '"') {
                inQuotes = !inQuotes;
              }
              if (str[h] === "," && !inQuotes) {
                cells.push(current);
                current = "";
              } else {
                // if (str[h] === '"' || str[h] === "'") {
                if (str[h] === '"') {
                  // Do nothing
                } else {
                  current += str[h];
                }
              }
            }
            cells.push(current); // Push the last item
            // Handler function to support , inside cells
            // let cells = rows[i].split(",");

            let rowData = {};
            for (let j = 0; j < cells.length; j++) {
              if (i == 0) {
                let headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                let key = headers[j];
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i != 0) {
              jsonData.push(rowData);
            }
          }
          // displaying the json result in string format
          // document.getElementById("display_csv_data").innerHTML =
          //   JSON.stringify(jsonData);
          console.log("display_csv_data : ", jsonData);

          // Handler function to submit the data...
          this.httpRequest
            .postRequest({
              url: "/v1courses/import-courses-from-csv",
              value: JSON.stringify(jsonData),
              headers: { "content-type": "application/json" },
            })
            .subscribe(
              (response: any) => {
                console.log(response, " : response");
                this.isLoading = false;

                this.isdisabled = false;
                if (response.status == "success") {
                  this.isSubmit = true;
                  setTimeout(() => {
                    this.isSubmit = false;
                    this.btntext = "Submit";
                    // this.onReset();
                  }, 2000);
                } else {
                }
              },
              (error) => {
                console.log(error);
                // this.getLevelSpecificCourses();
                this.isSubmit = true;
                setTimeout(() => {
                  this.isSubmit = false;
                  this.btntext = "Submit";
                  // this.onReset();
                  this.isdisabled = false;
                }, 2000);
              }
            );

          // Handler function to submit this data...
          // this.bolkOnSubmit(jsonData);
          // this.mbsService
          //   .bulkPostRequest({
          //     url: "/savemultiranks",
          //     value: jsonData,
          //   })
          //   .subscribe(
          //     (response: any) => {
          //       this.isLoading = false;
          //       this.isdisabled = false;
          //       if (response.status == "success") {
          //         this.isSubmit = true;
          //         // this.btntext = "Submit";
          //         this.importbtntext = "Import from CSV";
          //         // this.onReset();
          //         setTimeout(() => {
          //           this.isSubmit = false;
          //         }, 3000);
          //       } else {
          //       }
          //     },
          //     (error) => {
          //       this.isdisabled = false;
          //       this.isLoading = false;
          //       this.importbtntext = "Import from CSV";
          //     }
          //   );
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Please select a valid csv file.");
    }
    // this.fileName = event.target.files[0].name;
    // this.files = event.target.files[0];
    // console.log("Kailash : ", event);
    // console.log("File name : ", this.fileName);
    // if (this.fileName)
  }

  closePopup() {
    this.displayStyle = "none";
    // if (isPlatformBrowser(this.platformId)) {
    // sessionStorage.setItem("modelclick", "yes");
    // }
  }
}
