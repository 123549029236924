import {
  Component,
  OnInit,
  ChangeDetectorRef,
  NgZone,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
  AbstractControl,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { HttpRequestService } from "./../../../services/http-request.service";
import { environment } from "src/environments/environment";
// import { AngularEditorConfig } from "@kolkov/angular-editor";

import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";

// import { environment } from "src/environments/environment";

@Component({
  selector: "app-add-country",
  templateUrl: "./add-country.component.html",
  styleUrls: ["./add-country.component.css"],
})
export class AddCountryComponent implements OnInit {
  // @ViewChild("editor", { static: false }) editor: any;
  @ViewChild("editorStudy", { static: false }) editorStudy: any; // Reference to the CKEditor instance
  @ViewChild("editorShortDescription", { static: false })
  editorShortDescription: any;
  @ViewChild("editorDesciplineAvailableContent", { static: false })
  editorDesciplineAvailableContent: any;
  // editorAverageMonthlyExpense
  @ViewChild("editorAverageMonthlyExpense", { static: false })
  editorAverageMonthlyExpense: any;
  // editorVisaRequirementsContent
  @ViewChild("editorVisaRequirementsContent", { static: false })
  editorVisaRequirementsContent: any;
  @ViewChild("editorVisaRequirementslistOfDocuments", { static: false })
  editorVisaRequirementslistOfDocuments: any;

  // @ViewChild('editor') editor: any;

  countryId: any = "";
  submitted = false;
  form: FormGroup;
  objectId: any;
  SingleObjectData: any = true;
  invalidErrorMessage: any = "";
  isSubmit = false;
  btntext = "Submit";
  importbtntext = "Import from CSV";
  text = "Add Country with Details";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  backImagePreview: any[] = [];

  galleryPreview: any[] = [];
  ImageBaseUrl: string = "";
  // galleryPreview: any[] = [];

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfig = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    this.selectedOptions;
    this.ImageBaseUrl = environment.s3ImageUri;

    this.form = this.formBuilder.group({
      // Country Section
      // _id: [""],
      pageTitle: ["", Validators.required],
      country: ["", Validators.required],
      status: ["1", Validators.required],
      study_in: ["", Validators.required],
      short_description: ["", Validators.required],

      the_perks: this.formBuilder.group({
        heading: ["", Validators.required],
        sub_heading: ["", Validators.required],
        content: this.formBuilder.array([
          this.formBuilder.control("", Validators.required),
        ]),
      }),

      program_framework: this.formBuilder.array([
        this.formBuilder.group({
          heading: ["", Validators.required],
          content: ["", Validators.required],
          order: ["", Validators.required],
        }),
      ]),

      discipline_available: this.formBuilder.group({
        heading: ["", Validators.required],
        content: ["", Validators.required],
      }),

      popular_cities: this.formBuilder.group({
        heading: ["", Validators.required],
        sub_heading: ["", Validators.required],
        // ckeditorTable: [""],
        table: this.formBuilder.array([
          this.formBuilder.group({
            city_name: ["", Validators.required],
            no_of_university: ["", Validators.required],
          }),
        ]),
      }),

      average_monthly_expenses: ["", Validators.required],
      metatitle: ["", Validators.required],
      metakeywords: ["", Validators.required],
      metadescription: ["", Validators.required],

      visa_riquirements: this.formBuilder.group({
        heading: ["Students Visa Requirements", Validators.required],
        content: ["", Validators.required],
        listOfDocuments: [
          "<ul><li>Passport</li><li>Two Photographs</li><li>Valid permit to return</li><li>Travel Medical Insurance</li><li>Cover Letter</li><li>Confirmation of present student status</li><li>Certificate of admission or registration</li><li>Evidence of payment of tuition fees</li><li>Proof of sufficient funds</li><li>Electronic letter of guarantee of sponsor</li><li>Proof of accommodation in Austria or letter of acceptance at the school’s</li><li>Round-trip flight bookings - travel itinerary</li></ul>",
          Validators.required,
        ],
        app_fees: this.formBuilder.group({
          heading: [""],
          content: [""],
          faqs: this.formBuilder.array([
            this.formBuilder.group({
              heading: ["", Validators.required],
              sub_heading: ["", Validators.required],
            }),
          ]),
        }),
      }),

      // customurl: [
      //   "",
      //   [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)],
      // ],

      // metatitle: [""],
      // keywords: [""],
      // metadescription: [""],
      // checked: [false],
      // indeterminate: [false],
      flagImage: new FormControl(null, {}),
      // countryImages: new FormControl(null, {}),
      // flagImagePath: new FormControl(null, {}),
      backgroundimg: new FormControl([], {}),
      // gallery: new FormControl([], {}),
    });
  }

  //////////*********** This is for country section : starts here *************////////////////
  // Helper function to get the content form array
  get thePerksContent(): FormArray {
    let resp = this.form.get("the_perks.content") as FormArray;
    return resp;
  }
  // for program framework controller
  get programFrameworkContent(): FormArray {
    let resp = this.form.get("program_framework") as FormArray;
    return resp;
  }
  // for popularCitiesTable controller
  get popularCitiesTable(): FormArray {
    let resp = this.form.get("popular_cities.table") as FormArray;
    return resp;
  }
  // for visaReqFaqs controller
  get visaReqFaqs(): FormArray {
    let resp = this.form.get("visa_riquirements.app_fees.faqs") as FormArray;
    return resp;
  }

  // Add a new input field to the content array
  addPerksContentField() {
    this.thePerksContent.push(
      this.formBuilder.control("", Validators.required)
    );
  }
  // Remove function
  deletePerksContentField() {
    const thePerksContent = this.thePerksContent;
    if (thePerksContent.length > 0) {
      thePerksContent.removeAt(thePerksContent.length - 1);
    }
  }
  // Add a new input field to the program Framework
  addProgramFrameworkContentField() {
    this.programFrameworkContent.push(
      this.formBuilder.group({
        heading: ["", Validators.required],
        content: ["", Validators.required],
        order: ["", Validators.required],
      })
    );
  }
  // Remove function
  deleteProgramFrameworkContentField() {
    const programFrameworkContent = this.programFrameworkContent;
    if (programFrameworkContent.length > 0) {
      programFrameworkContent.removeAt(programFrameworkContent.length - 1);
    }
  }
  // Add a new input field to the popular Cities
  addPopularCitiesTableField() {
    this.popularCitiesTable.push(
      this.formBuilder.group({
        city_name: ["", Validators.required],
        no_of_university: ["", Validators.required],
      })
    );
  }
  // Remove function
  deletePopularCitiesTableField() {
    const popularCitiesTable = this.popularCitiesTable;
    if (popularCitiesTable.length > 0) {
      popularCitiesTable.removeAt(popularCitiesTable.length - 1);
    }
  }
  // Add a new input field to the faqs array for visa requiremnets
  addVisaReqFaqsField() {
    this.visaReqFaqs.push(
      this.formBuilder.group({
        heading: ["", Validators.required],
        sub_heading: ["", Validators.required],
      })
    );
  }
  // remove the last added input field from the faqs array for visa requirements
  deleteLastVisaReqFaq() {
    const visaReqFaqs = this.visaReqFaqs; // Access the FormArray
    if (visaReqFaqs.length > 0) {
      visaReqFaqs.removeAt(visaReqFaqs.length - 1); // Remove the last item
    }
  }

  // Update the form when the input content changes
  inputPerksContent(event: Event, index: number) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value;
    this.thePerksContent.at(index).setValue(inputValue);
  }
  // Update the Program Framework form when the input content changes
  inputProgramFrameworkContent(
    event: Event,
    index: number,
    controlName: string
  ) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value;
    this.programFrameworkContent
      .at(index)
      .get(controlName)
      .setValue(inputValue);
  }
  // Update the Cities Table form the input content changes
  inputPopularCitiesTable(event: Event, index: number, controlName: string) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value;
    this.popularCitiesTable.at(index).get(controlName).setValue(inputValue);
  }
  // Update the faqs Array form the input content changes
  inputVisaReqFaqs(event: Event, index: number, controlName: string) {
    const target = event.target as HTMLInputElement;
    const inputValue = target.value;
    this.visaReqFaqs.at(index).get(controlName).setValue(inputValue);
  }
  //////////*********** This is for country section : Ends here *************////////////////

  ngOnInit() {
    // console.log(this.Editor, " : Lorel Editor");

    this.route.queryParams.subscribe((params) => {
      this.countryId = params["countryId"];
      if (this.countryId) {
        this.text = `Edit Country: ${this.countryId}`;
        this.fetchCountryDetails(this.countryId);
      }
    });
  }

  // Update Content Handler
  updateContentHandler(type: any, selector: any, parent: any = false) {
    if (!parent) {
      let data: any = this.form.controls[type].value;

      // Update CKEditor content manually
      if (this[selector] && this[selector].editorInstance) {
        this[selector].editorInstance.setData(data);
      }
      // Update form control value
      this.form.controls[type].setValue(data);
    } else {
      const availableParentGroup = this.form.get(parent) as FormGroup;

      let data = availableParentGroup.get(type).value;
      // console.log(data, "ntttData");

      if (this[selector] && this[selector].editorInstance) {
        this[selector].editorInstance.setData(data);
      }

      // Update the value of the content control inside the discipline_available group
      availableParentGroup.get(type).setValue(data);

      // Also there is a soimple method to work with form controls... Example below
      /* form.controls.visa_riquirements.controls.content.setValue(
           $event.editor.getData()
       )*/
    }
  }
  // Remove/delete Content Handler
  // Reload Content Handler

  editorChangeHandler = (event: any, type: any, parent: any = false) => {
    if (!parent) {
      this.form.controls[type].setValue(event.editor.getData());
    } else {
      const disciplineAvailableGroup = this.form.get(parent) as FormGroup;
      disciplineAvailableGroup.get(type).setValue(event.editor.getData());
    }
  };

  // Handler function to fetch the country details
  fetchCountryDetails(countryId: any) {
    this.httpRequest
      .getRequest({
        url: "/country/getsingleCountry/" + countryId,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.SingleObjectData = response.data.doc;
            delete this.SingleObjectData._id;
            delete this.SingleObjectData.__v;
            for (
              let i = 0;
              i < this.SingleObjectData.popular_cities.table.length;
              i++
            ) {
              delete this.SingleObjectData.popular_cities.table[i]._id;
            }
            for (
              let i = 0;
              i < this.SingleObjectData.program_framework.length;
              i++
            ) {
              delete this.SingleObjectData.program_framework[i]._id;
            }
            delete this.SingleObjectData.the_perks._id;

            for (
              let i = 0;
              i < this.SingleObjectData.visa_riquirements.app_fees.faqs.length;
              i++
            ) {
              delete this.SingleObjectData.visa_riquirements.app_fees.faqs[i]
                ._id;
            }
            // this.form.setValue(this.SingleObjectData);
            this.SingleObjectData.the_perks.content.forEach(
              (item: any, index: any) => {
                if (index !== 0) {
                  this.addPerksContentField();
                }
              }
            );
            this.SingleObjectData.program_framework.forEach(
              (item: any, index: any) => {
                if (index !== 0) {
                  this.addProgramFrameworkContentField();
                }
              }
            );
            this.SingleObjectData.popular_cities.table.forEach(
              (item: any, index: any) => {
                if (index !== 0) {
                  this.addPopularCitiesTableField();
                }
              }
            );
            this.SingleObjectData.visa_riquirements.app_fees.faqs.forEach(
              (item: any, index: any) => {
                if (index !== 0) {
                  this.addVisaReqFaqsField();
                }
              }
            );

            this.imagePreview =
              this.ImageBaseUrl + this.SingleObjectData.flagImage;
            if (this.SingleObjectData.backgroundimg.length) {
              this.SingleObjectData.backgroundimg.forEach((element: any) => {
                this.backImagePreview.push(this.ImageBaseUrl + element);
              });
            }
            this.text = `Edit Country: ${this.SingleObjectData.country}`;
            this.form.patchValue({
              pageTitle: this.SingleObjectData.pageTitle,
              country: this.SingleObjectData.country,
              status: this.SingleObjectData.status,
              study_in: this.SingleObjectData.study_in,
              short_description: this.SingleObjectData.short_description,
              the_perks: this.SingleObjectData.the_perks,
              program_framework: this.SingleObjectData.program_framework,
              discipline_available: this.SingleObjectData.discipline_available,
              popular_cities: this.SingleObjectData.popular_cities,
              average_monthly_expenses:
                this.SingleObjectData.average_monthly_expenses,
              visa_riquirements: this.SingleObjectData.visa_riquirements,
              metatitle: this.SingleObjectData.metatitle,
              metakeywords: this.SingleObjectData.metakeywords,
              metadescription: this.SingleObjectData.metadescription,
              flagImage: this.SingleObjectData.flagImage,
              // flagImagePath: this.SingleObjectData.flagImagePath,
            });
            this.updateContentHandler("study_in", "editorStudy");
            this.updateContentHandler(
              "short_description",
              "editorShortDescription"
            );
            this.updateContentHandler(
              "content",
              "editorDesciplineAvailableContent",
              "discipline_available"
            );
            this.updateContentHandler(
              "average_monthly_expenses",
              "editorAverageMonthlyExpense"
            );
            this.updateContentHandler(
              "content",
              "editorVisaRequirementsContent",
              "visa_riquirements"
            );
            this.updateContentHandler(
              "listOfDocuments",
              "editorVisaRequirementslistOfDocuments",
              "visa_riquirements"
            );
          } else {
            this.SingleObjectData = false;
          }
        },
        (error) => {
          this.SingleObjectData = false;
          this.invalidErrorMessage = error.message;
        }
      );
  }

  options = [
    { name: "OptionA", value: "1", checked: true },
    { name: "OptionB", value: "2", checked: false },
    { name: "OptionC", value: "3", checked: true },
  ];

  get selectedOptions() {
    return this.options.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  onImagePicked(event: Event) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ flagImage: file });
    this.form.get("flagImage").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onBackgroundPicked(event: Event) {
    this.backImagePreview = [];
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ backgroundimg: files });
    this.form.get("backgroundimg").updateValueAndValidity();
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.backImagePreview.push(reader.result as string);
        console.log(this.backImagePreview, " : this.backImagePreview");
      };
      reader.readAsDataURL(files[i]);
    }
  }

  // onGalleryPicked(event: Event) {
  //   console.log(event);
  //   const files = (event.target as HTMLInputElement).files;
  //   this.form.patchValue({ gallery: files });
  //   this.form.get("gallery").updateValueAndValidity();
  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.galleryPreview.push(reader.result as string);
  //       console.log(this.galleryPreview, " : this.galleryPreview");
  //     };
  //     reader.readAsDataURL(files[i]);
  //   }
  // }

  onSubmit() {
    // console.log("Submit : ", this.form);
    this.submitted = true;
    if (this.form.invalid) {
      // return;
      console.log(this.form.invalid, " : ** this.form.invalid");
    }
    this.isdisabled = true;
    this.btntext = "Processing...";
    let postData = new FormData();

    // if (this.form.value.customurl) {
    //   postData.append("customurl", this.form.value.customurl);
    // }

    if (this.form.value.pageTitle) {
      postData.append("pageTitle", this.form.value.pageTitle);
    }
    if (this.form.value.country) {
      postData.append("country", this.form.value.country);
    }
    if (this.form.value.study_in) {
      postData.append("study_in", this.form.value.study_in);
    }
    if (this.form.value.short_description) {
      postData.append("short_description", this.form.value.short_description);
    }
    if (this.form.value.the_perks) {
      postData.append("the_perks", JSON.stringify(this.form.value.the_perks));
    }
    if (this.form.value.program_framework) {
      postData.append(
        "program_framework",
        JSON.stringify(this.form.value.program_framework)
      );
    }
    if (this.form.value.discipline_available) {
      postData.append(
        "discipline_available",
        JSON.stringify(this.form.value.discipline_available)
      );
    }
    // if (this.form.value.metatitle) {
    //   postData.append("metatitle", JSON.stringify(this.form.value.metatitle));
    // }
    // if (this.form.value.metakeywords) {
    //   postData.append(
    //     "metakeywords",
    //     JSON.stringify(this.form.value.metakeywords)
    //   );
    // }
    // if (this.form.value.metadescription) {
    //   postData.append(
    //     "metadescription",
    //     JSON.stringify(this.form.value.metadescription)
    //   );
    // }
    if (this.form.value.popular_cities) {
      JSON.stringify(
        postData.append(
          "popular_cities",
          JSON.stringify(this.form.value.popular_cities)
        )
      );
    }
    if (this.form.value.average_monthly_expenses) {
      postData.append(
        "average_monthly_expenses",
        this.form.value.average_monthly_expenses
      );
    }
    if (this.form.value.visa_riquirements) {
      postData.append(
        "visa_riquirements",
        JSON.stringify(this.form.value.visa_riquirements)
      );
    }
    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }
    if (this.form.value.metakeywords) {
      postData.append("metakeywords", this.form.value.metakeywords);
    }
    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (
      this.form.value.backgroundimg &&
      this.form.value.backgroundimg.length > 0
    ) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
    }

    postData.append("flagImage", this.form.value.flagImage);
    postData.append("status", this.form.value.status);

    // console.log(postData, " : *** postData");

    // this.isLoading = false;
    // this.isdisabled = false;
    // this.isSubmit = true;
    // this.btntext = "Submit";
    // this.isSubmit = false;

    this.httpRequest
      .postRequest({
        url: `/country/${
          !this.countryId ? "saveCountry" : `updateCountry/${this.countryId}`
        }`,
        // value: this.form.value,
        value: postData,
        // headers: { "Content-Type": "multipart/form-data" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
          }
          setTimeout(() => {
            this.isSubmit = false;
          }, 3000);
        },
        (error) => {
          this.isSubmit = false;
          this.btntext = "Submit";
          this.isLoading = false;
          this.isdisabled = false;
          // console.log(error);
          // this.isLoading = false;
          // this.isdisabled = false;
          // this.isSubmit = false;
        }
      );

    // this.httpRequest
    //   .postRequest({
    //     url: "/college/savecollege",
    //     value: postData,
    //   })
    //   .subscribe(
    //     (response: any) => {
    //       this.isLoading = false;
    //       this.isdisabled = false;
    //       if (response.status == "success") {
    //         this.isSubmit = true;
    //         this.btntext = "Submit";
    //         this.onReset();
    //         setTimeout(() => {
    //           this.isSubmit = false;
    //         }, 3000);
    //       } else {
    //       }
    //     },
    //     (error) => {
    //       this.isSubmit = false;
    //       this.btntext = "Submit";
    //       this.isLoading = false;
    //       this.isdisabled = false;
    //       // console.log(error);
    //       // this.isLoading = false;
    //       // this.isdisabled = false;
    //       // this.isSubmit = false;
    //     }
    //   );
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }
}









