import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../services/http-request.service";

@Component({
  selector: 'app-online-rating',
  templateUrl: './online-rating.component.html',
  styleUrls: ['./online-rating.component.css']
})

export class OnlineRatingComponent implements OnInit {
  submitted = false;
  Searchform: FormGroup;
  objectId: any;
  ObjectRatingData:  any[] = [];
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [1,10, 20, 50, 100, 150];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.Searchform = this.formBuilder.group({
      date: [""],
      status: [""],
    });
    this.geRatingList();
  }

  geRatingList() { 
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
    this.isLoading = true;
    this.httpRequest
      .postRequest({
        url:
          "/course-services/get-rating-list"+queryParams,
        value: JSON.stringify(this.Searchform.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          //console.log(response);
          if (response.status == "success") {
            if (response.posts.length > 0) {
              this.ObjectRatingData = response.posts;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectRatingData = [];
              this.totalCount = 0;
              this.postsPerPage = 10;
              this.currentPage = 1;
            }
          } else {
            this.ObjectRatingData = [];
            this.totalCount = 0;
            this.postsPerPage = 10;
            this.currentPage = 1;
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

   // Pagination to change page
   onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.geRatingList();
  }

  // onchange approved/cancelled rating status
  onSelectChange(id:any, event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;
    const obj = {
        id:id,
        status:selectedValue
    }
    this.isLoading = true;
    this.httpRequest
      .postRequest({
        url:"/course-services/update-rating",
        value: obj,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          console.log(response);
          if (response.status == "success") {
              this.geRatingList()
              //console.log("response:::",response);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    console.log(this.Searchform.value.categoryid);
    this.geRatingList()
  }

}
