import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Post } from "./post.model";

const BACKEND_URL = environment.apiUrl + "/mbaranking/";

@Injectable({ providedIn: "root" })
export class PostsService {
  warning(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  error(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  success(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  private posts: Post[] = [];
  private postsUpdated = new Subject<{ posts: Post[]; postCount: number }>();

  private httpOptions;

  constructor(private http: HttpClient, private router: Router) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        
          // method: 'GET',
      })
    };
  }

  getPosts(postsPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
    this.http
      .get<{ message: string; posts: any; maxPosts: number }>(
        BACKEND_URL + queryParams, {headers: this.httpOptions }
      )
      .pipe(
        map(postData => {
          return {
            posts: postData.posts.map(post => {
              return {
                rank: post.rank,
                businessschools: post.businessschools,
                id: post._id,
                weightedindex: post.weightedindex,
                artshumanities: post.artshumanities,
                logoImagePath: post.logoImagePath,
                creator: post.creator
              };
            }),
            maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe(transformedPostData => {
        this.posts = transformedPostData.posts;
        this.postsUpdated.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts
        });
      });
  }

  getPostUpdateListener() {
    return this.postsUpdated.asObservable();
  }

  getPost(id: string) {
    return this.http.get<{
      _id: string, rank: string, businessschools: string,  weightedindex: string, placementperformance: string,tlrp: string, research: string,
      industryincomeintegration: string, pss: string,futureorientation: string,epio: string, iirfranking: string,address: string,content: string,artshumanities: any,backgroundimg:string, percentageofinternationalstudents: string,facultytostudentsration: string,highestplacementpackage: string, scholarshipavailability: string, averageplacementpackage: string, researchperspective: string,video: string,  gallery: string, impactranking: string, saarcranking: string, design: string,social: string, physical: string, lifesciences: string,engineering: string, business: string, medicine: string, education: string, law: string,phone: string, email: string ,websitelink: string, overallmbaranking: string, schoolofeminenceranking: string,topprivatebschoolsranking: string,bestbschoolsnorthranking: string, bestbschoolssouthranking: string, bestbschoolscentralranking: string, bestbschoolswestranking: string,bestbschoolseastranking: string,visionranking:string,admissionssupport: string,facebookpagelink: string,linkedinpagelink: string,twitterpagelink: string,lng: string ,lat:string, logoImagePath: string,
      creator: string;
    }>(BACKEND_URL + id , {headers: this.httpOptions});
  }

  addPost(rank: any, businessschools: string,  weightedindex: string, placementperformance: string,tlrp: string,research: string,industryincomeintegration: string,pss: string,futureorientation: string,epio: string, iirfranking: string,address: string,content: string,artshumanities: any,backgroundimg:any, percentageofinternationalstudents: string,facultytostudentsration: string,highestplacementpackage: string, scholarshipavailability: string, averageplacementpackage: string, researchperspective: string,video: string,  gallery: string, impactranking: string, saarcranking: string, design: string,social: string, physical: string, lifesciences: string,engineering: string, business: string, medicine: string, education: string, law: string,phone: string, email: string, websitelink: string, overallmbaranking: string, schoolofeminenceranking: string, topprivatebschoolsranking: string,bestbschoolsnorthranking: string,bestbschoolssouthranking: string,bestbschoolscentralranking: string,bestbschoolswestranking: string,bestbschoolseastranking: string,visionranking: string, admissionssupport:string, facebookpagelink: string,linkedinpagelink: string,twitterpagelink:string,lng: string, lat: string, logoImagePath: File) {
    const postData = new FormData();
    if(rank){
      postData.append("rank", rank);
    }
    if(businessschools){
      postData.append("businessschools", businessschools);
    }
    if(weightedindex){
      postData.append("weightedindex",weightedindex);
    }
    if(placementperformance){
      postData.append("placementperformance",placementperformance);
    }
    if(tlrp){
      postData.append("tlrp", tlrp);
    }
    if(research){
      postData.append("research",research);
    }
    if(industryincomeintegration){
      postData.append("industryincomeintegration",industryincomeintegration);
    }
    if(pss){
      postData.append("pss",pss);
    }
    if(futureorientation){
      postData.append("futureorientation",futureorientation);
    }
    if(epio){
      postData.append("epio",epio);
    }
    if(iirfranking){
      postData.append("iirfranking",iirfranking);
    }
    if(address){
      postData.append("address",address);
    }
    if(content){
      postData.append("content",content);
    }
    if(artshumanities){
      postData.append("artshumanities",artshumanities);
    }
    // postData.append("backgroundimg",backgroundimg);
    // console.log("Back",backgroundimg)  
  // if(backgroundimg){
    for (var i = 0; i < backgroundimg.length; i++) { 
      postData.append("backgroundimg", backgroundimg[i]);
    }
  // }else{
  //   postData.append("backgroundimg", new Array[0])
  // }

  if(percentageofinternationalstudents){
    postData.append("percentageofinternationalstudents",percentageofinternationalstudents);
  }
  if(facultytostudentsration){
    postData.append("facultytostudentsration",facultytostudentsration);
  }
  if(highestplacementpackage){
    postData.append("highestplacementpackage",highestplacementpackage);
  }
  if(scholarshipavailability){
    postData.append("scholarshipavailability",scholarshipavailability);
  }
  if(averageplacementpackage){
    postData.append("averageplacementpackage",averageplacementpackage);
  }
  if(researchperspective){
    postData.append("researchperspective",researchperspective);
  }
  if(video){
    postData.append("video",video);
  }
    // postData.append("gallery",gallery);
    for (var i = 0; i < gallery.length; i++) { 
      postData.append("gallery", gallery[i]);
    }
    if(impactranking){
      postData.append("impactranking",impactranking);
    }
    if(saarcranking){
      postData.append("saarcranking",saarcranking);
    }
    if(design){
      postData.append("design",design);
    }
    if(social){
      postData.append("social",social);
    }
    if(physical){
      postData.append("physical",physical);
    }
    if(lifesciences){
      postData.append("lifesciences",lifesciences);
    }
    if(engineering){
      postData.append("engineering",engineering);
    }
    if(business){
      postData.append("business",business);
    }
    if(medicine){
      postData.append("medicine",medicine);
    }
    if(education){
      postData.append("education",education);
    }
    if(law){
      postData.append("law",law);
    }
    if(phone){
      postData.append("phone",phone);
    }
    if(email){
      postData.append("email",email);
    }
    if(websitelink){
      postData.append("websitelink",websitelink);
    }
    if(overallmbaranking){
      postData.append("overallmbaranking",overallmbaranking);
    }
    if(schoolofeminenceranking){
      postData.append("schoolofeminenceranking",schoolofeminenceranking);
    }
    if(topprivatebschoolsranking){
      postData.append("topprivatebschoolsranking",topprivatebschoolsranking);
    }
    if(bestbschoolsnorthranking){
      postData.append("bestbschoolsnorthranking",bestbschoolsnorthranking);
    }
    if(bestbschoolssouthranking){
      postData.append("bestbschoolssouthranking",bestbschoolssouthranking);
    }
    if(bestbschoolscentralranking){
      postData.append("bestbschoolscentralranking",bestbschoolscentralranking);
    }
    if(bestbschoolswestranking){
      postData.append("bestbschoolswestranking",bestbschoolswestranking);
    }
    if(bestbschoolseastranking){
      postData.append("bestbschoolseastranking",bestbschoolseastranking);
    }
    if(visionranking){
      postData.append("visionranking",visionranking);
    }
    if(admissionssupport){
      postData.append("admissionssupport",admissionssupport);
    }
    if(facebookpagelink){
      postData.append("facebookpagelink",facebookpagelink);
    }
    if(linkedinpagelink){
      postData.append("linkedinpagelink",linkedinpagelink);
    }
    if(twitterpagelink){
      postData.append("twitterpagelink",twitterpagelink);
    }
    if(lng){
      postData.append("lng",lng);
    }
    if(lat){
      postData.append("lat",lat);
    }

    postData.append("logoImagePath", logoImagePath);
    console.log(postData);
    this.http
      .post<{ message: string; post: Post }>(
        BACKEND_URL,
        postData
      )
      .subscribe(responseData => {
        this.router.navigate(["/listing-mba-ranking"]);
      });
  }

  updatePost(id: string, rank: string, businessschools: string,  weightedindex: string, placementperformance: string,tlrp: string,research: string,industryincomeintegration: string,pss: string,futureorientation: string,epio: string, iirfranking: string,address: string,content: string,artshumanities: any,
    backgroundimg:string, percentageofinternationalstudents: string,facultytostudentsration: string,highestplacementpackage: string, scholarshipavailability: string, averageplacementpackage: string, researchperspective: string,video: string,  gallery: string, impactranking: string, saarcranking: string, design: string,social: string, physical: string, lifesciences: string,engineering: string, business: string, medicine: string, education: string, law: string,phone: string, email: string, websitelink: string, overallmbaranking: string, schoolofeminenceranking: string, topprivatebschoolsranking: string,bestbschoolsnorthranking: string,bestbschoolssouthranking: string,bestbschoolscentralranking: string,bestbschoolswestranking: string,bestbschoolseastranking: string,visionranking: string, admissionssupport:string, facebookpagelink: string,linkedinpagelink: string,twitterpagelink:string,lng: string, lat: string,logoImagePath: File | any, logoImagePathEdit: File | any,) {
    let postData: any;
    if (typeof logoImagePath === "object") {
      console.log("logoImagePath", logoImagePath)
      // return;
      postData = new FormData();
      postData.append("id", id);
      if(rank){
        postData.append("rank", rank);
      }
      if(businessschools){
        postData.append("businessschools", businessschools);
      }
      if(weightedindex){
        postData.append("weightedindex",weightedindex);
      }
      if(placementperformance){
        postData.append("placementperformance",placementperformance);
      }
      if(tlrp){
        postData.append("tlrp", tlrp);
      }
      if(research){
        postData.append("research",research);
      }
      if(industryincomeintegration){
        postData.append("industryincomeintegration",industryincomeintegration);
      }
      if(pss){
        postData.append("pss",pss);
      }
      if(futureorientation){
        postData.append("futureorientation",futureorientation);
      }
      if(epio){
        postData.append("epio",epio);
      }
      if(iirfranking){
        postData.append("iirfranking",iirfranking);
      }
      if(address){
        postData.append("address",address);
      }
      if(content != null){
        postData.append("content",content);
      }
      if(artshumanities){
        postData.append("artshumanities",artshumanities);
      }
      // postData.append("backgroundimg",backgroundimg);
      // console.log("Back",backgroundimg)  
    // if(backgroundimg){
      for (var i = 0; i < backgroundimg.length; i++) { 
        postData.append("backgroundimg", backgroundimg[i]);
      }
    // }else{
    //   postData.append("backgroundimg", new Array[0])
    // }
  
    if(percentageofinternationalstudents){
      postData.append("percentageofinternationalstudents",percentageofinternationalstudents);
    }
    if(facultytostudentsration){
      postData.append("facultytostudentsration",facultytostudentsration);
    }
    if(highestplacementpackage){
      postData.append("highestplacementpackage",highestplacementpackage);
    }
    if(scholarshipavailability){
      postData.append("scholarshipavailability",scholarshipavailability);
    }
    if(averageplacementpackage){
      postData.append("averageplacementpackage",averageplacementpackage);
    }
    if(researchperspective){
      postData.append("researchperspective",researchperspective);
    }
    if(video){
      postData.append("video",video);
    }
      // postData.append("gallery",gallery);
      for (var i = 0; i < gallery.length; i++) { 
        postData.append("gallery", gallery[i]);
      }
      if(impactranking){
        postData.append("impactranking",impactranking);
      }
      if(saarcranking){
        postData.append("saarcranking",saarcranking);
      }
      if(design){
        postData.append("design",design);
      }
      if(social){
        postData.append("social",social);
      }
      if(physical){
        postData.append("physical",physical);
      }
      if(lifesciences){
        postData.append("lifesciences",lifesciences);
      }
      if(engineering){
        postData.append("engineering",engineering);
      }
      if(business){
        postData.append("business",business);
      }
      if(medicine){
        postData.append("medicine",medicine);
      }
      if(education){
        postData.append("education",education);
      }
      if(law){
        postData.append("law",law);
      }
      if(phone){
        postData.append("phone",phone);
      }
      if(email){
        postData.append("email",email);
      }
      if(websitelink){
        postData.append("websitelink",websitelink);
      }
      if(overallmbaranking){
        postData.append("overallmbaranking",overallmbaranking);
      }
      if(schoolofeminenceranking){
        postData.append("schoolofeminenceranking",schoolofeminenceranking);
      }
      if(topprivatebschoolsranking){
        postData.append("topprivatebschoolsranking",topprivatebschoolsranking);
      }
      if(bestbschoolsnorthranking){
        postData.append("bestbschoolsnorthranking",bestbschoolsnorthranking);
      }
      if(bestbschoolssouthranking){
        postData.append("bestbschoolssouthranking",bestbschoolssouthranking);
      }
      if(bestbschoolscentralranking){
        postData.append("bestbschoolscentralranking",bestbschoolscentralranking);
      }
      if(bestbschoolswestranking){
        postData.append("bestbschoolswestranking",bestbschoolswestranking);
      }
      if(bestbschoolseastranking){
        postData.append("bestbschoolseastranking",bestbschoolseastranking);
      }
      if(visionranking){
        postData.append("visionranking",visionranking);
      }
      if(admissionssupport){
        postData.append("admissionssupport",admissionssupport);
      } 
      if(facebookpagelink){
        postData.append("facebookpagelink",facebookpagelink);
      }
      if(linkedinpagelink){
        postData.append("linkedinpagelink",linkedinpagelink);
      }
      if(twitterpagelink){
        postData.append("twitterpagelink",twitterpagelink);
      }
      if(lng){
        postData.append("lng",lng);
      }
      if(lat){
        postData.append("lat",lat);
      }
  
      postData.append("logoImagePath", logoImagePath);
      postData.append("logoImagePathEdit", logoImagePathEdit)
    } else {
      postData = {
        id: id,
        rank: rank,
        businessschools: businessschools,
        weightedindex: weightedindex,
        placementperformance: placementperformance,
        tlrp: tlrp,
        research: research,
        industryincomeintegration: industryincomeintegration,
        pss: pss,
        futureorientation: futureorientation,
        epio:epio,
        iirfranking: iirfranking,
        address: address,
        content: content,
        artshumanities: artshumanities,
        backgroundimg: backgroundimg,
        percentageofinternationalstudents:percentageofinternationalstudents,
        facultytostudentsration: facultytostudentsration,
        highestplacementpackage: highestplacementpackage,
        scholarshipavailability: scholarshipavailability,
        averageplacementpackage: averageplacementpackage,
        researchperspective: researchperspective,
        video: video,
        gallery: gallery,
        impactranking: impactranking,
        saarcranking: saarcranking,
        design: design,
        social: social,
        physical: physical,
        lifesciences: lifesciences,
        engineering: engineering,
        business: business,
        medicine: medicine,
        education: education,
        law: law,
        phone:phone,
        email: email,
        websitelink: websitelink,
        overallmbaranking: overallmbaranking,
        schoolofeminenceranking: schoolofeminenceranking,
        topprivatebschoolsranking: topprivatebschoolsranking,
        bestbschoolsnorthranking: bestbschoolsnorthranking,
        bestbschoolssouthranking: bestbschoolssouthranking,
        bestbschoolscentralranking: bestbschoolscentralranking,
        bestbschoolswestranking: bestbschoolswestranking,
        bestbschoolseastranking: bestbschoolseastranking,
        visionranking: visionranking,
        admissionssupport: admissionssupport,
        facebookpagelink: facebookpagelink,
        linkedinpagelink: linkedinpagelink,
        twitterpagelink: twitterpagelink,
        lng: lng,
        lat: lat,
        logoImagePath: logoImagePath,
        logoImagePathEdit:logoImagePathEdit,
        creator: null
      };
    }
    // console.log("Service postData", Object.fromEntries(postData))
    // for (let [key, value] of postData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    // return
  
    this.http
      .put(BACKEND_URL + id, postData)
      .subscribe(response => {
        this.router.navigate(["/listing-mba-ranking"]);
      });
  }

  getCurrentData(postId: string){
    return this.http.get(BACKEND_URL + postId, this.httpOptions);
  }

  UpdateCurrentData(postId: string, data){
    return this.http.put(BACKEND_URL + postId, data, this.httpOptions);
  }

  deletePost(postId: string) {
    return this.http.delete(BACKEND_URL + postId, this.httpOptions);
  }
}
 