import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-edit-college",
  templateUrl: "./edit-college.component.html",
  styleUrls: ["./edit-college.component.css"],
})
export class EditCollegeComponent implements OnInit {
  @ViewChild("editor", { static: false }) editor: any; // Add this line for CKEditor

  submitted = false;
  collegetypes: any[] = [];
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  childData: any[] = [];
  // SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "500",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ],
  };

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  // editorConfigs is for CKEditor
  public editorConfigs = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "imageUpload",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    height: "10rem",
    // extraPlugins: "autogrow",
    // autoGrow_minHeight: 200,
    // autoGrow_maxHeight: 600,
    // autoGrow_bottomSpace: 50,
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add other heading levels as needed
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:full",
        "imageStyle:side",
      ],
      styles: ["full", "side"],
      customUploadFileName: (file) => {
        return "custom-prefix-" + file.name;
      },
    },
  };

  selectedAlumniarr: any = [];

  // getselectedOptions() { // right now: ['1','3']
  //   this.businessarr
  //             .filter(opt => opt.checked)
  //             .map(opt => opt.value)
  //             console.log("opt.checked", this.businessarr)
  // }

  // alumni: any[] = [
  //   {
  //     "profileimg": "",
  //     "profilename": "",
  //     "profilepos": "",
  //   }

  // ]

  profileimg(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  profilename(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  profilepos(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  ImageBaseUrl: string = "";
  backImagePreview: string = "";
  galleryPreview: any[] = [];
  branches: any[] = [];
  subbranches: any[] = [];
  countries: any[] = [];

  mappedCollegeIdDetails: any;

  // Below is the section for Filtering Colleges to map
  typedStateName: string = "";
  typedDistrictName: string = "";
  typedCityName: string = "";
  typedInstituteName: string = "";
  typeForFilteringInstitute: string = "";
  branchIdForFilteringInstitute: string = "";

  totalPosts = 0;
  postsPerPage = 20;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  posts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private router: ActivatedRoute
  ) {
    this.selectedOptions;
    this.ImageBaseUrl = environment.s3ImageUri;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      //collegetypeid: ["", Validators.required],
      type: ["", Validators.required],
      parentid: [""],
      branchid: ["", Validators.required],
      subbranchid: [""],
      childbranchid: [""],
      // collegetypeid: ["", Validators.required],
      countryid: [""],
      // branchid: ["", Validators.required],
      // subbranchid: ["", Validators.required],
      collegename: ["", Validators.required],
      address: ["", Validators.required],
      state: ["", Validators.required],
      district: ["", Validators.required],
      city: [""],
      // backgroundimg: [""],
      phone: [""],
      email: [""],
      websitelink: [""],
      admissionssupport: [""],
      facebookpagelink: [""],
      linkedinpagelink: [""],
      twitterpagelink: [""],
      percentageofinternationalstudents: [""],
      facultytostudentsration: [""],
      highestplacementpackage: [""],
      scholarshipavailability: [""],
      averageplacementpackage: [""],
      researchperspective: [""],
      video: [""],
      // gallery: [""],
      lng: [""],
      lat: [""],
      content: [""],
      ckeditorcontent: [""],
      status: ["1"],

      // customurl: [
      //   "",
      //   [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)],
      // ],
      customurl: [""],
      metatitle: [""],
      keywords: [""],
      metadescription: [""],

      logoImagePath: new FormControl(null, {
        // validators: [Validators.required],
        // asyncValidators: [mimeType]
      }),
      // backgroundimg: [""],
      backgroundimg: new FormControl([], {}),
      // gallery: [""],
      gallery: new FormControl([], {}),
    });
    this.getSingleFetchdata();
    this.geBranch();
    this.getcollegetype();
    this.getCountries();
    // this.geBranch();
    // this.getSubBranchdata();
    // this.getcollegetype();
  }

  getcollegetype() {
    this.httpRequest
      .getRequest({
        url: "/college/getCollegeType",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.collegetypes = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  getCountries() {
    this.httpRequest
      .getRequest({
        url: "/country/fetchCountries?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if ((response.status = "success")) {
            if (response.data.length > 0) {
              this.countries = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.branches = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubBranchdata(branchObjectId) {
    console.log(branchObjectId);
    this.httpRequest
      .getRequest({
        url:
          "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subbranches = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  getSingleFetchdata() {
    this.httpRequest
      .getRequest({
        url: "/college/fetchsinglecollege/" + this.router.snapshot.params.id,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.SingleObjectData = response.data;
            this.imagePreview =
              this.ImageBaseUrl + this.SingleObjectData.logoImagePath;
            this.backImagePreview =
              this.ImageBaseUrl + this.SingleObjectData.backgroundimg;
            // this.galleryPreview =
            this.SingleObjectData.gallery.forEach((element: any) => {
              this.galleryPreview.push(this.ImageBaseUrl + element);
            });
            console.log(this.ObjectData);
            if (this.SingleObjectData.branchid) {
              this.getSubBranchdata(this.SingleObjectData.branchid);
            }
            if (this.SingleObjectData.subbranchid) {
              // this.getChilddata(this.SingleObjectData.subbranchid);
            }
            this.form.patchValue({
              type: this.SingleObjectData.type,
              // parentid: this.SingleObjectData.parentid._id,
              branchid: this.SingleObjectData.branchid,
              countryid: this.SingleObjectData.countryid,
              subbranchid: this.SingleObjectData.subbranchid,
              childbranchid: this.SingleObjectData.childbranchid,
              collegename: this.SingleObjectData.collegename,
              // businessschools: this.SingleObjectData.businessschools,
              weightedindex: this.SingleObjectData.weightedindex,
              placementperformance: this.SingleObjectData.placementperformance,
              tlrp: this.SingleObjectData.tlrp,
              research: this.SingleObjectData.research,
              industryincomeintegration:
                this.SingleObjectData.industryincomeintegration,
              pss: this.SingleObjectData.pss,
              futureorientation: this.SingleObjectData.futureorientation,
              epio: this.SingleObjectData.epio,
              iirfranking: this.SingleObjectData.iirfranking,
              address: this.SingleObjectData.address,
              state: this.SingleObjectData.state,
              district: this.SingleObjectData.district,
              city: this.SingleObjectData.city,
              content: this.SingleObjectData.content,
              ckeditorcontent: this.SingleObjectData.ckeditorcontent,
              // data: this.SingleObjectData.ckeditorcontent,
              artshumanities: this.SingleObjectData.artshumanities,
              backgroundimg: this.SingleObjectData.backgroundimg,
              percentageofinternationalstudents:
                this.SingleObjectData.percentageofinternationalstudents,
              facultytostudentsration:
                this.SingleObjectData.facultytostudentsration,
              highestplacementpackage:
                this.SingleObjectData.highestplacementpackage,
              scholarshipavailability:
                this.SingleObjectData.scholarshipavailability,
              averageplacementpackage:
                this.SingleObjectData.averageplacementpackage,
              researchperspective: this.SingleObjectData.researchperspective,
              video: this.SingleObjectData.video,
              gallery: this.SingleObjectData.gallery,
              impactranking: this.SingleObjectData.impactranking,
              saarcranking: this.SingleObjectData.saarcranking,
              design: this.SingleObjectData.design,
              social: this.SingleObjectData.social,
              physical: this.SingleObjectData.physical,
              lifesciences: this.SingleObjectData.lifesciences,
              engineering: this.SingleObjectData.engineering,
              business: this.SingleObjectData.business,
              medicine: this.SingleObjectData.medicine,
              education: this.SingleObjectData.education,
              law: this.SingleObjectData.law,
              phone: this.SingleObjectData.phone,
              email: this.SingleObjectData.email,
              websitelink: this.SingleObjectData.websitelink,
              overallmbaranking: this.SingleObjectData.overallmbaranking,
              schoolofeminenceranking:
                this.SingleObjectData.schoolofeminenceranking,
              topprivatebschoolsranking:
                this.SingleObjectData.topprivatebschoolsranking,
              bestbschoolsnorthranking:
                this.SingleObjectData.bestbschoolsnorthranking,
              bestbschoolssouthranking:
                this.SingleObjectData.bestbschoolssouthranking,
              bestbschoolscentralranking:
                this.SingleObjectData.bestbschoolscentralranking,
              bestbschoolswestranking:
                this.SingleObjectData.bestbschoolswestranking,
              bestbschoolseastranking:
                this.SingleObjectData.bestbschoolseastranking,
              visionranking: this.SingleObjectData.visionranking,
              admissionssupport: this.SingleObjectData.admissionssupport,
              facebookpagelink: this.SingleObjectData.facebookpagelink,
              linkedinpagelink: this.SingleObjectData.linkedinpagelink,
              twitterpagelink: this.SingleObjectData.twitterpagelink,
              lng: this.SingleObjectData.lng,
              lat: this.SingleObjectData.lat,
              logoImagePath: this.SingleObjectData.logoImagePath,
              //  collegetypeid: this.SingleObjectData.collegetypeid,
              customurl: this.SingleObjectData.customurl,
              // SEO parameter
              metatitle: this.SingleObjectData.metatitle,
              keywords: this.SingleObjectData.keywords,
              metadescription: this.SingleObjectData.metadescription,
            });
            if (
              this.SingleObjectData.parentid &&
              this.SingleObjectData.parentid._id
            ) {
              this.form.patchValue({
                parentid: this.SingleObjectData.parentid._id,
              });
            }
            // This is to map the mapped parent details
            if (
              this.SingleObjectData.parentid &&
              this.SingleObjectData.parentid._id
            ) {
              this.mappedCollegeIdDetails = this.SingleObjectData.parentid;
            }
            // This was for separate formcontrol instance for ckeditor
            // if (this.SingleObjectData.ckeditorcontent) {
            //   this.editor.editorInstance.setData(
            //     this.SingleObjectData.ckeditorcontent
            //   );
            // }

            // Below is for the same formControl instance as of angular
            if (this.SingleObjectData.content) {
              this.editor.editorInstance.setData(this.SingleObjectData.content);
            }
          } else {
          }
        },
        (error) => {}
      );
  }

  options = [
    { name: "OptionA", value: "1", checked: true },
    { name: "OptionB", value: "2", checked: false },
    { name: "OptionC", value: "3", checked: true },
  ];
  get selectedOptions() {
    // right now: ['1','3']
    return this.options.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  // compareWithFunc(a, b) {
  //   return a.name === b.name;
  // }

  onImagePicked(event: Event) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ logoImagePath: file });
    this.form.get("logoImagePath").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      // console.log('reader.result : ', reader.result);
      // backgroundimg
    };
    reader.readAsDataURL(file);
  }

  onBackgroundPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ backgroundimg: files });
    this.form.get("backgroundimg").updateValueAndValidity();
    // console.log('file: ', files)
    const reader = new FileReader();
    reader.onload = () => {
      this.backImagePreview = reader.result as string;
    };
    reader.readAsDataURL(files);

    /*  function readFile(index) {
      if( index >= files.length ) return;
      var file = files[index];
      reader.onload = function(e) {  
     
        // get file content  
        
        // var bin = e.target.result;
        // do sth with bin
        readFile(index+1)
     
      
      }
      reader.readAsBinaryString(file);
    }
    readFile(0); */
  }

  onGalleryPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();

    if (files.length > 0) {
      this.galleryPreview = [];
    }

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.galleryPreview.push(reader.result as string);
        console.log(this.galleryPreview, " : this.galleryPreview");
      };
      reader.readAsDataURL(files[i]);
    }
    // console.log("file: ", files);
    // const reader = new FileReader();
    // // reader.onload = () => {
    // //   this.backImagePreview = reader.result as string;
    // // };
    // // reader.readAsDataURL(file);
    // function readFile(index) {
    //   if (index >= files.length) return;
    //   var file = files[index];
    //   reader.onload = function (e) {
    //     // get file content

    //     // var bin = e.target.result;
    //     // do sth with bin
    //     readFile(index + 1);
    //   };
    //   reader.readAsBinaryString(file);
    // }
    // readFile(0);
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form, " : this.form");
      return;
    }
    this.isdisabled = true;
    this.btntext = "Processing...";
    //   console.log(this.form.value);
    let postData = new FormData();
    //postData = this.form.value;
    if (this.form.value.type) {
      postData.append("type", this.form.value.type);
    }
    if (this.form.value.parentid.trim() !== "") {
      postData.append("parentid", this.form.value.parentid);
    }

    if (this.form.value.countryid) {
      postData.append("countryid", this.form.value.countryid);
    }
    if (this.form.value.branchid) {
      postData.append("branchid", this.form.value.branchid);
    }
    if (this.form.value.subbranchid) {
      postData.append("subbranchid", this.form.value.subbranchid);
    }
    if (this.form.value.childbranchid) {
      postData.append("childbranchid", this.form.value.childbranchid);
    }

    // if (this.form.value.collegetypeid) {
    //   postData.append("collegetypeid", this.form.value.collegetypeid);
    // }
    if (this.form.value.customurl) {
      postData.append("customurl", this.form.value.customurl);
    }

    if (this.form.value.collegename) {
      postData.append("collegename", this.form.value.collegename);
    }

    if (this.form.value.phone) {
      postData.append("phone", this.form.value.phone);
    }
    if (this.form.value.email) {
      postData.append("email", this.form.value.email);
    }
    if (this.form.value.websitelink) {
      postData.append("websitelink", this.form.value.websitelink);
    }
    if (this.form.value.placementperformance) {
      postData.append(
        "placementperformance",
        this.form.value.placementperformance
      );
    }

    if (this.form.value.admissionssupport) {
      postData.append("admissionssupport", this.form.value.admissionssupport);
    }
    if (this.form.value.facebookpagelink) {
      postData.append("facebookpagelink", this.form.value.facebookpagelink);
    }
    if (this.form.value.linkedinpagelink) {
      postData.append("linkedinpagelink", this.form.value.linkedinpagelink);
    }
    if (this.form.value.twitterpagelink) {
      postData.append("twitterpagelink", this.form.value.twitterpagelink);
    }
    if (this.form.value.percentageofinternationalstudents) {
      postData.append(
        "percentageofinternationalstudents",
        this.form.value.percentageofinternationalstudents
      );
    }
    if (this.form.value.facultytostudentsration) {
      postData.append(
        "facultytostudentsration",
        this.form.value.facultytostudentsration
      );
    }
    if (this.form.value.highestplacementpackage) {
      postData.append(
        "highestplacementpackage",
        this.form.value.highestplacementpackage
      );
    }
    if (this.form.value.scholarshipavailability) {
      postData.append(
        "scholarshipavailability",
        this.form.value.scholarshipavailability
      );
    }

    if (this.form.value.averageplacementpackage) {
      postData.append(
        "averageplacementpackage",
        this.form.value.averageplacementpackage
      );
    }

    if (this.form.value.researchperspective) {
      postData.append(
        "researchperspective",
        this.form.value.researchperspective
      );
    }
    if (this.form.value.video) {
      postData.append("video", this.form.value.video);
    }
    if (this.form.value.lng) {
      postData.append("lng", this.form.value.lng);
    }
    if (this.form.value.lat) {
      postData.append("lat", this.form.value.lat);
    }
    if (this.form.value.establishmentYear) {
      postData.append("establishmentYear", this.form.value.establishmentYear);
    }
    if (this.form.value.address) {
      postData.append("address", this.form.value.address);
    }
    // if (this.form.value.districtorcity) {
    //   postData.append("districtorcity", this.form.value.districtorcity);
    // }
    if (this.form.value.state) {
      postData.append("state", this.form.value.state);
    }
    if (this.form.value.district) {
      postData.append("district", this.form.value.district);
    }
    if (this.form.value.city) {
      postData.append("city", this.form.value.city);
    }
    if (this.form.value.zone) {
      postData.append("zone", this.form.value.zone);
    }
    if (this.form.value.country) {
      postData.append("country", this.form.value.country);
    }

    // meta details, other & Custom URL details
    // if (this.form.value.customurl) {
    //   postData.append("customurl", this.form.value.customurl);
    // }
    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }
    if (this.form.value.keywords) {
      postData.append("keywords", this.form.value.keywords);
    }
    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (this.form.value.content) {
      postData.append("content", this.form.value.content);
    }

    if (this.form.value.ckeditorcontent) {
      postData.append("ckeditorcontent", this.form.value.ckeditorcontent);
    }

    /*  if(this.form.value.backgroundimg && this.form.value.backgroundimg.length > 0) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) { 
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
    } */
    postData.append("backgroundimg", this.form.value.backgroundimg);

    if (this.form.value.gallery && this.form.value.gallery.length > 0) {
      for (var i = 0; i < this.form.value.gallery.length; i++) {
        postData.append("gallery", this.form.value.gallery[i]);
      }
    }

    postData.append("logoImagePath", this.form.value.logoImagePath);
    postData.append("status", "1");

    console.log(postData);

    this.httpRequest
      .putRequest({
        url: "/college/updatecollege/" + this.router.snapshot.params.id,
        value: postData,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
            //   this.onReset();
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error) => {
          this.isSubmit = false;
          this.btntext = "Submit";
          this.isLoading = false;
          this.isdisabled = false;
        }
      );
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  checkArtsData = (value: any) => {
    let artshmArray = this.SingleObjectData.artshumanities[0].split(",");
    // let artshmArray = this.SingleObjectData.artshumanities[0]
    //   ? this.SingleObjectData.artshumanities[0].split(",")
    //   : [""];
    // console.log(artshmArray, " : artshmArray");
    for (var i = 0; i < artshmArray.length; i++) {
      if (artshmArray[i] == value) {
        return true;
      }
    }
    // return false;
    // console.log('value: '+ value);
    // let data = this.SingleObjectData.artshumanities.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
  };

  checkDesignData = (value) => {
    let designArray = this.SingleObjectData.design[0].split(",");
    for (var i = 0; i < designArray.length; i++) {
      if (designArray[i] == value) {
        return true;
      }
    }
  };

  checkSocialData = (value) => {
    // let data = this.SingleObjectData.social.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let socialArray = this.SingleObjectData.social[0].split(",");
    for (var i = 0; i < socialArray.length; i++) {
      if (socialArray[i] == value) {
        return true;
      }
    }
  };

  checkPhysicalData = (value) => {
    // let data = this.SingleObjectData.physical.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let physicalArray = this.SingleObjectData.physical[0].split(",");
    for (var i = 0; i < physicalArray.length; i++) {
      if (physicalArray[i] == value) {
        return true;
      }
    }
  };

  checkLifesciencesData = (value) => {
    // let data = this.SingleObjectData.lifesciences.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let lifesciencesArray = this.SingleObjectData.lifesciences[0].split(",");
    for (var i = 0; i < lifesciencesArray.length; i++) {
      if (lifesciencesArray[i] == value) {
        return true;
      }
    }
  };

  checkEngineeringData = (value) => {
    // let data = this.SingleObjectData.engineering.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let engineeringArray = this.SingleObjectData.engineering[0].split(",");
    for (var i = 0; i < engineeringArray.length; i++) {
      if (engineeringArray[i] == value) {
        return true;
      }
    }
  };

  checkBusinessData = (value) => {
    // let data = this.SingleObjectData.business.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let businessArray = this.SingleObjectData.business[0].split(",");
    for (var i = 0; i < businessArray.length; i++) {
      if (businessArray[i] == value) {
        return true;
      }
    }
  };

  checkMedicineData = (value) => {
    // let data = this.SingleObjectData.medicine.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let medicineArray = this.SingleObjectData.medicine[0].split(",");
    for (var i = 0; i < medicineArray.length; i++) {
      if (medicineArray[i] == value) {
        return true;
      }
    }
  };

  checkEducationData = (value) => {
    // let data = this.SingleObjectData.education.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let educationArray = this.SingleObjectData.education[0].split(",");
    for (var i = 0; i < educationArray.length; i++) {
      if (educationArray[i] == value) {
        return true;
      }
    }
  };

  checkLawData = (value) => {
    // let data = this.SingleObjectData.law.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let lawArray = this.SingleObjectData.law[0].split(",");
    for (var i = 0; i < lawArray.length; i++) {
      if (lawArray[i] == value) {
        return true;
      }
    }
  };

  getChilddata(bjectId) {
    console.log(bjectId);
    if (bjectId) {
      this.httpRequest
        .getRequest({
          url: "/subbranch/getChildBranch?subbranchid=" + bjectId,
        })
        .subscribe(
          (response: any) => {
            if (response.status == "success") {
              this.childData = response.data;
            }
          },
          (error) => {}
        );
    }
  }

  // Institute filtering section for Parent institute mapping
  filterLoading: boolean = false;
  filterDisabled: boolean = false;
  filterIsSubmit: boolean = false;

  onChangedPage(pageData: any) {
    this.filterLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    // this.mbsService.getPosts(
    //   this.postsPerPage,
    //   this.currentPage,
    //   this.searchForm.value
    // );
    let payload: any = {
      collegename: this.typedInstituteName,
      state: this.typedStateName,
      district: this.typedDistrictName,
      city: this.typedCityName,
      type: this.typeForFilteringInstitute,
      branchid: this.branchIdForFilteringInstitute,
      currentPage: this.currentPage,
      postsPerPage: this.postsPerPage,
    };

    // console.log(payload, " : payload");
    this.filterColleges(payload);
  }

  // Below is the section for filtering and mapping the Colleges with Rankings...
  handleCollegeFilterButtonClick() {
    this.currentPage = 1;
    this.postsPerPage = 20;

    let payload: any = {
      collegename: this.typedInstituteName,
      state: this.typedStateName,
      district: this.typedDistrictName,
      city: this.typedCityName,
      type: this.typeForFilteringInstitute,
      branchid: this.branchIdForFilteringInstitute,
      currentPage: this.currentPage,
      postsPerPage: this.postsPerPage,
    };

    // console.log(payload, " : payload");
    this.filterColleges(payload);
  }

  // Separate API calling Handler should be called to filter the Institutions
  filterColleges(payload: any) {
    this.httpRequest
      .postRequest({
        url: "/college/filter-colleges",
        value: payload,
      })
      .subscribe(
        (response: any) => {
          this.filterLoading = false;
          this.filterDisabled = false;
          if (response.status == "success") {
            this.filterIsSubmit = true;
            this.posts = response.posts;
            this.totalPosts = response.maxPosts;
            setTimeout(() => {
              this.filterIsSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error: any) => {
          this.filterDisabled = false;
          this.filterLoading = false;
          console.log(error.message);
        }
      );
  }

  // Map on Event Click
  mapOnEventClick(post: any) {
    console.log(post, " : mapped college details post");
    this.mappedCollegeIdDetails = post;
    this.form.patchValue({
      parentid: post._id,
    });
  }

  unMapOnEventClick(post: any) {
    this.mappedCollegeIdDetails = undefined;
    this.form.patchValue({
      parentid: "",
    });
  }
}
