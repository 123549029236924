// import { Component, OnInit } from "@angular/core";
// import {
//   FormGroup,
//   FormControl,
//   Validators,
//   FormBuilder,
//   AbstractControl,
// } from "@angular/forms";
// import { HttpRequestService } from "./../../services/http-request.service";

// @Component({
//   selector: "app-uri",
//   templateUrl: "./uri.component.html",
//   styleUrls: ["./uri.component.css"],
// })
// export class UriComponent implements OnInit {
//   years: any[] = ["2020", "2021", "2022", "2023", "2024"];
//   submitted = false;
//   form: FormGroup;
//   objectId: any;
//   ObjectData: any[] = [];
//   BranchObjectData: any[] = [];
//   SingleObjectData: any;
//   isSubmit = false;
//   btntext = "Submit";
//   text = "Add";
//   isLoading = false;
//   isdisabled = false;
//   posts: any[] = [];
//   subbranches: any[] = [];
//   totalCount = 0;
//   postsPerPage = 10;
//   currentPage = 1;
//   constructor(
//     private formBuilder: FormBuilder,
//     private httpRequest: HttpRequestService
//   ) {}

//   ngOnInit() {
//     this.form = this.formBuilder.group({
//       yearid: [""],
//       branchid: [""],
//       subbranchid: [""],
//       // uri: [
//       //   '',
//       //   [Validators.pattern(/^[a-z0-9-]+$/)],
//       // ],
//       status: [""],
//     });

//     this.geBranch();
//     this.fetchUriList();
//     // Added listener for subscribe data
//     this.httpRequest.getPostUpdateListener().subscribe((postData) => {
//       this.isLoading = false;
//       this.totalCount = postData.postCount;
//       this.ObjectData = postData.posts;
//       console.log("ObjectData");
//       console.log(this.ObjectData);
//     });
//   }

//   get f(): { [key: string]: AbstractControl } {
//     return this.form.controls;
//   }

//   geBranch() {
//     this.httpRequest
//       .getRequest({
//         url: "/branch/fetchbranch?status=1",
//         //  value:JSON.stringify(this.form.value),
//         headers: { "content-type": "application/json" },
//       })
//       .subscribe(
//         (response: any) => {
//           console.log(response);
//           if (response.status == "success") {
//             if (response.data.length > 0) {
//               this.BranchObjectData = response.data;
//             }
//           }
//         },
//         (error) => {
//           console.log(error);
//         }
//       );
//   }

//   getSubBranchdata(branchObjectId) {
//     console.log(branchObjectId);
//     this.httpRequest
//       .getRequest({
//         url: "/subbranch/fetchsubbranch?branchid=" + branchObjectId,
//       })
//       .subscribe(
//         (response: any) => {
//           if (response.status == "success") {
//             this.subbranches = response.data;
//           }
//         },
//         (error) => {}
//       );
//   }

//   onSubmit() {
//     this.fetchUriList();
//   }

//   // Pagination to change page
//   onChangedPage(pageData) {
//     this.isLoading = true;
//     this.currentPage = pageData.pageIndex + 1;
//     this.postsPerPage = pageData.pageSize;
//     this.fetchUriList();
//   }

//   // Fetch all data from database with page size
//   fetchUriList() {
//     const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&yearid=${this.form.value.yearid}&status=${this.form.value.status}&branchid=${this.form.value.branchid}&subbranchid=${this.form.value.subbranchid}`;
//     this.httpRequest.getPosts({
//       url: "/uri/getUriList" + queryParams,
//     });
//   }
// }

/////////////=======================>
// Junaid's code starts here
/////////////=======================>

import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { ConfirmationDialogService } from "src/app/services/confirmation-prompt.service";

@Component({
  selector: "app-uri",
  templateUrl: "./uri.component.html",
  styleUrls: ["./uri.component.css"],
})
export class UriComponent implements OnInit {
  // years:any[] = ['2020','2021','2022','2023','2024'];
  yearsArray: any[] = [];
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  BranchObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  subbranches: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      yearid: [""],
      branchid: [""],
      subbranchid: [""],
      // uri: [
      //   '',
      //   [Validators.pattern(/^[a-z0-9-]+$/)],
      // ],
      status: [""],
    });

    this.geBranch();
    this.fetchUriList();
    this.getyears();
    // Added listener for subscribe data
    this.httpRequest.getPostUpdateListener().subscribe((postData) => {
      this.isLoading = false;
      this.totalCount = postData.postCount;
      this.ObjectData = postData.posts;
      console.log("ObjectData");
      console.log(this.ObjectData);
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getyears() {
    this.httpRequest
      .getRequest({
        url: "/year/fetchYears?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.yearsArray = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.BranchObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubBranchdata(branchObjectId) {
    console.log(branchObjectId);
    this.httpRequest
      .getRequest({
        url:
          "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subbranches = response.data;
          }
        },
        (error) => {}
      );
  }

  onSubmit() {
    this.fetchUriList();
  }

  // Pagination to change page
  onChangedPage(pageData) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchUriList();
  }

  // Fetch all data from database with page size
  fetchUriList() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&yearid=${this.form.value.yearid}&status=${this.form.value.status}&branchid=${this.form.value.branchid}&subbranchid=${this.form.value.subbranchid}`;
    this.httpRequest.getPosts({
      url: "/uri/getUriList" + queryParams,
    });
  }

  // AMS AMS AMS AMS    AMS AMS AMS AMS
  onDelete(itemId: string) {
    this.confirmationDialogService
      .openConfirmationDialog()
      .then((confirmed: any) => {
        if (confirmed) {
          this.httpRequest
            .deleteRequest({
              url: "/uri/deleteuri/" + itemId,
            })
            .subscribe((response: any) => {
              if (response.status == "success") {
                this.fetchUriList();
              }
            });
        }
      });
  }
}
