import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-edit-college",
  templateUrl: "./edit-college.component.html",
  styleUrls: ["./edit-college.component.css"],
})
export class EditAbroadCollegeComponent implements OnInit {
  @ViewChild("universityHighlightseditor", { static: false })
  universityHighlightseditor: any;
  @ViewChild("editor", { static: false }) editor: any; // Add this line for CKEditor
  @ViewChild("courseHighlightseditor", { static: false })
  courseHighlightseditor: any;
  @ViewChild("eligibilityCriteriaeditor", { static: false })
  eligibilityCriteriaeditor: any;
  @ViewChild("admissionProcedureeditor", { static: false })
  admissionProcedureeditor: any;
  @ViewChild("feesOvervieweditor", { static: false })
  feesOvervieweditor: any;
  @ViewChild("campusFacilityContent1editor", { static: false })
  campusFacilityContent1editor: any;
  @ViewChild("campusFacilityContent2editor", { static: false })
  campusFacilityContent2editor: any;
  @ViewChild("scholarshipOvervieweditor", { static: false })
  scholarshipOvervieweditor: any;

  submitted = false;
  collegetypes: any[] = [];
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  childData: any[] = [];
  // SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  // s: Angulars = {
  //   editable: true,
  //   spellcheck: true,
  //   height: "300px",
  //   minHeight: "500",
  //   maxHeight: "auto",
  //   width: "auto",
  //   minWidth: "0",
  //   translate: "yes",
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: "Enter text here...",
  //   defaultParagraphSeparator: "",
  //   defaultFontName: "",
  //   defaultFontSize: "",
  //   fonts: [
  //     { class: "arial", name: "Arial" },
  //     { class: "times-new-roman", name: "Times New Roman" },
  //     { class: "calibri", name: "Calibri" },
  //     { class: "comic-sans-ms", name: "Comic Sans MS" },
  //   ],
  //   customClasses: [
  //     {
  //       name: "quote",
  //       class: "quote",
  //     },
  //     {
  //       name: "redText",
  //       class: "redText",
  //     },
  //     {
  //       name: "titleText",
  //       class: "titleText",
  //       tag: "h1",
  //     },
  //   ],
  //   uploadUrl: "v1/image",
  //   uploadWithCredentials: false,
  //   sanitize: true,
  //   toolbarPosition: "top",
  //   toolbarHiddenButtons: [
  //     // ['bold', 'italic'],
  //     // ['fontSize']
  //   ],
  // };

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfigs = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  selectedAlumniarr: any = [];

  // getselectedOptions() { // right now: ['1','3']
  //   this.businessarr
  //             .filter(opt => opt.checked)
  //             .map(opt => opt.value)
  //             console.log("opt.checked", this.businessarr)
  // }

  // alumni: any[] = [
  //   {
  //     "profileimg": "",
  //     "profilename": "",
  //     "profilepos": "",
  //   }

  // ]

  profileimg(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  profilename(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  profilepos(event) {
    let index = this.selectedAlumniarr.indexOf(event.target.value);
    if (index == -1) {
      this.selectedAlumniarr.push(event.target.value);
    } else {
      this.selectedAlumniarr.splice(index, 1);
    }
  }

  ImageBaseUrl: string = "";
  backImagePreview: string = "";
  galleryPreview: any[] = [];
  branches: any[] = [];
  subbranches: any[] = [];
  countries: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private router: ActivatedRoute
  ) {
    this.selectedOptions;
    this.ImageBaseUrl = environment.s3ImageUri;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      //collegetypeid: ["", Validators.required],
      type: ["", Validators.required],
      // branchid: ["", Validators.required],
      // subbranchid: [""],
      childbranchid: [""],
      // collegetypeid: ["", Validators.required],
      countryid: ["", Validators.required],
      // branchid: ["", Validators.required],
      // subbranchid: ["", Validators.required],
      collegename: ["", Validators.required],
      address: ["", Validators.required],
      // backgroundimg: [""],
      phone: [""],
      email: [""],
      websitelink: [""],
      admissionssupport: [""],
      facebookpagelink: [""],
      linkedinpagelink: [""],
      twitterpagelink: [""],

      percentageofinternationalstudents: [""],
      facultytostudentsration: [""],
      highestplacementpackage: [""],
      scholarshipavailability: [""],
      averageplacementpackage: [""],
      researchperspective: [""],
      // ==========================
      universityHighlights: [""],
      establishmentYear: [""],
      totalStudents: [""],
      numbersOfInternationalStudents: [""],
      qsWorldRanking: [""],
      campusSize: [""],
      totalNumbersOfCampus: [""],
      numbersOfSchoolsAndDevision: [""],
      nobelPrizeWinnerAlumni: [""],
      numbersOfEducationPrograms: [""],

      video: [""],
      // gallery: [""],
      lng: [""],
      lat: [""],
      content: [""],
      ckeditorcontent: [""],

      courseHighlights: [""],
      eligibilityCriteria: [""],
      admissionProcedure: [""],
      feesOverview: [""],
      campusFacilityContent1: [""],
      campusFacilityContent2: [""],
      scholarshipOverview: [""],

      status: ["1"],

      customurl: [
        "",
        [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)],
      ],
      metatitle: [""],
      keywords: [""],
      metadescription: [""],

      logoImagePath: new FormControl(null, {
        // validators: [Validators.required],
        // asyncValidators: [mimeType]
      }),
      // backgroundimg: [""],
      backgroundimg: new FormControl([], {}),
      // gallery: [""],
      gallery: new FormControl([], {}),
    });
    this.getSingleFetchdata();
    // this.geBranch();
    // this.getcollegetype();
    this.getCountries();
    // this.geBranch();
    // this.getSubBranchdata();
    // this.getcollegetype();
  }

  getcollegetype() {
    this.httpRequest
      .getRequest({
        url: "/abroad-college/getCollegeType",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.collegetypes = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  getCountries() {
    this.httpRequest
      .getRequest({
        url: "/country/fetchCountries?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if ((response.status = "success")) {
            if (response.data.length > 0) {
              this.countries = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // geBranch() {
  //   this.httpRequest
  //     .getRequest({
  //       url: "/branch/fetchbranch",
  //       //  value:JSON.stringify(this.form.value),
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status == "success") {
  //           if (response.data.length > 0) {
  //             this.branches = response.data;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  // getSubBranchdata(branchObjectId) {
  //   console.log(branchObjectId);
  //   this.httpRequest
  //     .getRequest({
  //       url:
  //         "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         if (response.status == "success") {
  //           this.subbranches = response.data;
  //         } else {
  //         }
  //       },
  //       (error) => {}
  //     );
  // }

  getSingleFetchdata() {
    this.httpRequest
      .getRequest({
        url:
          "/abroad-college/fetchsinglecollege/" +
          this.router.snapshot.params.id,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.SingleObjectData = response.data;
            this.imagePreview =
              this.ImageBaseUrl + this.SingleObjectData.logoImagePath;
            this.backImagePreview =
              this.ImageBaseUrl + this.SingleObjectData.backgroundimg;
            // this.galleryPreview =
            this.SingleObjectData.gallery.forEach((element: any) => {
              this.galleryPreview.push(this.ImageBaseUrl + element);
            });
            console.log(this.ObjectData);
            // if (this.SingleObjectData.branchid) {
            //   this.getSubBranchdata(this.SingleObjectData.branchid);
            // }
            // if (this.SingleObjectData.subbranchid) {
            //   this.getChilddata(this.SingleObjectData.subbranchid);
            // }
            this.form.patchValue({
              type: this.SingleObjectData.type,
              // branchid: this.SingleObjectData.branchid,
              countryid: this.SingleObjectData.countryid,
              // subbranchid: this.SingleObjectData.subbranchid,
              childbranchid: this.SingleObjectData.childbranchid,
              collegename: this.SingleObjectData.collegename,
              businessschools: this.SingleObjectData.businessschools,
              weightedindex: this.SingleObjectData.weightedindex,
              placementperformance: this.SingleObjectData.placementperformance,
              tlrp: this.SingleObjectData.tlrp,
              research: this.SingleObjectData.research,
              industryincomeintegration:
                this.SingleObjectData.industryincomeintegration,
              pss: this.SingleObjectData.pss,
              futureorientation: this.SingleObjectData.futureorientation,
              epio: this.SingleObjectData.epio,
              iirfranking: this.SingleObjectData.iirfranking,
              address: this.SingleObjectData.address,
              content: this.SingleObjectData.content,
              ckeditorcontent: this.SingleObjectData.ckeditorcontent,
              // data: this.SingleObjectData.ckeditorcontent,

              // Metadetails for abroad colleges page constructions  === starts here
              courseHighlights: this.SingleObjectData.courseHighlights,
              eligibilityCriteria: this.SingleObjectData.eligibilityCriteria,
              admissionProcedure: this.SingleObjectData.admissionProcedure,
              feesOverview: this.SingleObjectData.feesOverview,
              campusFacilityContent1:
                this.SingleObjectData.campusFacilityContent1,
              campusFacilityContent2:
                this.SingleObjectData.campusFacilityContent2,
              scholarshipOverview:
                this.SingleObjectData.scholarshipavailability,
              // Metadetails for abroad colleges page constructions  === ends here

              artshumanities: this.SingleObjectData.artshumanities,
              backgroundimg: this.SingleObjectData.backgroundimg,
              percentageofinternationalstudents:
                this.SingleObjectData.percentageofinternationalstudents,
              facultytostudentsration:
                this.SingleObjectData.facultytostudentsration,
              highestplacementpackage:
                this.SingleObjectData.highestplacementpackage,
              scholarshipavailability:
                this.SingleObjectData.scholarshipavailability,
              averageplacementpackage:
                this.SingleObjectData.averageplacementpackage,
              researchperspective: this.SingleObjectData.researchperspective,
              universityHighlights: this.SingleObjectData.universityHighlights,
              establishmentYear: this.SingleObjectData.establishmentYear,
              totalStudents: this.SingleObjectData.totalStudents,
              numbersOfInternationalStudents:
                this.SingleObjectData.numbersOfInternationalStudents,
              qsWorldRanking: this.SingleObjectData.qsWorldRanking,
              campusSize: this.SingleObjectData.campusSize,
              totalNumbersOfCampus: this.SingleObjectData.totalNumbersOfCampus,
              numbersOfSchoolsAndDevision:
                this.SingleObjectData.numbersOfSchoolsAndDevision,
              nobelPrizeWinnerAlumni:
                this.SingleObjectData.nobelPrizeWinnerAlumni,
              numbersOfEducationPrograms:
                this.SingleObjectData.numbersOfEducationPrograms,

              video: this.SingleObjectData.video,
              gallery: this.SingleObjectData.gallery,
              impactranking: this.SingleObjectData.impactranking,
              saarcranking: this.SingleObjectData.saarcranking,
              design: this.SingleObjectData.design,
              social: this.SingleObjectData.social,
              physical: this.SingleObjectData.physical,
              lifesciences: this.SingleObjectData.lifesciences,
              engineering: this.SingleObjectData.engineering,
              business: this.SingleObjectData.business,
              medicine: this.SingleObjectData.medicine,
              education: this.SingleObjectData.education,
              law: this.SingleObjectData.law,
              phone: this.SingleObjectData.phone,
              email: this.SingleObjectData.email,
              websitelink: this.SingleObjectData.websitelink,
              metatitle: this.SingleObjectData.metatitle,
              keywords: this.SingleObjectData.keywords,
              metadescription: this.SingleObjectData.metadescription,
              overallmbaranking: this.SingleObjectData.overallmbaranking,
              schoolofeminenceranking:
                this.SingleObjectData.schoolofeminenceranking,
              topprivatebschoolsranking:
                this.SingleObjectData.topprivatebschoolsranking,
              bestbschoolsnorthranking:
                this.SingleObjectData.bestbschoolsnorthranking,
              bestbschoolssouthranking:
                this.SingleObjectData.bestbschoolssouthranking,
              bestbschoolscentralranking:
                this.SingleObjectData.bestbschoolscentralranking,
              bestbschoolswestranking:
                this.SingleObjectData.bestbschoolswestranking,
              bestbschoolseastranking:
                this.SingleObjectData.bestbschoolseastranking,
              visionranking: this.SingleObjectData.visionranking,
              admissionssupport: this.SingleObjectData.admissionssupport,
              facebookpagelink: this.SingleObjectData.facebookpagelink,
              linkedinpagelink: this.SingleObjectData.linkedinpagelink,
              twitterpagelink: this.SingleObjectData.twitterpagelink,
              lng: this.SingleObjectData.lng,
              lat: this.SingleObjectData.lat,
              logoImagePath: this.SingleObjectData.logoImagePath,
              //  collegetypeid: this.SingleObjectData.collegetypeid,
              customurl: this.SingleObjectData.customurl,
            });

            if (this.SingleObjectData.universityHighlights) {
              this.universityHighlightseditor.editorInstance.setData(
                this.SingleObjectData.universityHighlights
              );
            }
            if (this.SingleObjectData.ckeditorcontent) {
              this.editor.editorInstance.setData(
                this.SingleObjectData.ckeditorcontent
              );
            }
            // Metadetails for abroad colleges page constructions  === starts here
            if (this.SingleObjectData.courseHighlights) {
              this.courseHighlightseditor.editorInstance.setData(
                this.SingleObjectData.courseHighlights
              );
            }
            if (this.SingleObjectData.eligibilityCriteria) {
              this.eligibilityCriteriaeditor.editorInstance.setData(
                this.SingleObjectData.eligibilityCriteria
              );
            }
            if (this.SingleObjectData.admissionProcedure) {
              this.admissionProcedureeditor.editorInstance.setData(
                this.SingleObjectData.admissionProcedure
              );
            }
            if (this.SingleObjectData.feesOverview) {
              this.feesOvervieweditor.editorInstance.setData(
                this.SingleObjectData.feesOverview
              );
            }
            if (this.SingleObjectData.campusFacilityContent1) {
              this.campusFacilityContent1editor.editorInstance.setData(
                this.SingleObjectData.campusFacilityContent1
              );
            }
            if (this.SingleObjectData.campusFacilityContent2) {
              this.campusFacilityContent2editor.editorInstance.setData(
                this.SingleObjectData.campusFacilityContent2
              );
            }
            if (this.SingleObjectData.scholarshipOverview) {
              this.scholarshipOvervieweditor.editorInstance.setData(
                this.SingleObjectData.scholarshipOverview
              );
            }
          } else {
          }
        },
        (error) => {}
      );
  }

  options = [
    { name: "OptionA", value: "1", checked: true },
    { name: "OptionB", value: "2", checked: false },
    { name: "OptionC", value: "3", checked: true },
  ];
  get selectedOptions() {
    // right now: ['1','3']
    return this.options.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  // compareWithFunc(a, b) {
  //   return a.name === b.name;
  // }

  onImagePicked(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];

    if (file) {
      const maxSizeInMB = 1; // Set your maximum file size in MB here
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        alert(`Image file size should not exceed ${maxSizeInMB}MB`);
        fileInput.value = ""; // Clear the input field
      } else {
        // Process the file
        this.form.patchValue({ logoImagePath: file });
        this.form.get("logoImagePath").updateValueAndValidity();
        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onBackgroundPicked(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];

    if (file) {
      const maxSizeInMB = 1; // Set your maximum file size in MB here
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        alert(`Background image file size should not exceed ${maxSizeInMB}MB`);
        fileInput.value = ""; // Clear the input field
      } else {
        // Process the file
        this.form.patchValue({ backgroundimg: file });
        this.form.get("backgroundimg").updateValueAndValidity();
        const reader = new FileReader();
        reader.onload = () => {
          this.backImagePreview = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onGalleryPicked(event: Event) {
    this.galleryPreview = [];
    // console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.galleryPreview.push(reader.result as string);
        // console.log(this.galleryPreview, " : this.galleryPreview");
      };
      reader.readAsDataURL(files[i]);
    }
    // console.log("file: ", files);
    // const reader = new FileReader();
    // // reader.onload = () => {
    // //   this.backImagePreview = reader.result as string;
    // // };
    // // reader.readAsDataURL(file);
    // function readFile(index) {
    //   if (index >= files.length) return;
    //   var file = files[index];
    //   reader.onload = function (e) {
    //     // get file content

    //     // var bin = e.target.result;
    //     // do sth with bin
    //     readFile(index + 1);
    //   };
    //   reader.readAsBinaryString(file);
    // }
    // readFile(0);
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      console.log(this.form.invalid, " : this.form");
      return;
    }
    this.isdisabled = true;
    this.btntext = "Processing...";
    //   console.log(this.form.value);
    let postData = new FormData();
    //postData = this.form.value;
    if (this.form.value.type) {
      postData.append("type", this.form.value.type);
    }

    if (this.form.value.countryid) {
      postData.append("countryid", this.form.value.countryid);
    }
    // if (this.form.value.branchid) {
    //   postData.append("branchid", this.form.value.branchid);
    // }
    // if (this.form.value.subbranchid) {
    //   postData.append("subbranchid", this.form.value.subbranchid);
    // }
    if (this.form.value.childbranchid) {
      postData.append("childbranchid", this.form.value.childbranchid);
    }

    // if (this.form.value.collegetypeid) {
    //   postData.append("collegetypeid", this.form.value.collegetypeid);
    // }
    if (this.form.value.customurl) {
      postData.append("customurl", this.form.value.customurl);
    }

    if (this.form.value.collegename) {
      postData.append("collegename", this.form.value.collegename);
    }

    if (this.form.value.phone) {
      postData.append("phone", this.form.value.phone);
    }
    if (this.form.value.email) {
      postData.append("email", this.form.value.email);
    }
    if (this.form.value.websitelink) {
      postData.append("websitelink", this.form.value.websitelink);
    }
    if (this.form.value.placementperformance) {
      postData.append(
        "placementperformance",
        this.form.value.placementperformance
      );
    }

    if (this.form.value.admissionssupport) {
      postData.append("admissionssupport", this.form.value.admissionssupport);
    }
    if (this.form.value.facebookpagelink) {
      postData.append("facebookpagelink", this.form.value.facebookpagelink);
    }
    if (this.form.value.linkedinpagelink) {
      postData.append("linkedinpagelink", this.form.value.linkedinpagelink);
    }
    if (this.form.value.twitterpagelink) {
      postData.append("twitterpagelink", this.form.value.twitterpagelink);
    }
    if (this.form.value.percentageofinternationalstudents) {
      postData.append(
        "percentageofinternationalstudents",
        this.form.value.percentageofinternationalstudents
      );
    }
    if (this.form.value.facultytostudentsration) {
      postData.append(
        "facultytostudentsration",
        this.form.value.facultytostudentsration
      );
    }
    if (this.form.value.highestplacementpackage) {
      postData.append(
        "highestplacementpackage",
        this.form.value.highestplacementpackage
      );
    }
    if (this.form.value.scholarshipavailability) {
      postData.append(
        "scholarshipavailability",
        this.form.value.scholarshipavailability
      );
    }

    if (this.form.value.averageplacementpackage) {
      postData.append(
        "averageplacementpackage",
        this.form.value.averageplacementpackage
      );
    }
    if (this.form.value.researchperspective) {
      postData.append(
        "researchperspective",
        this.form.value.researchperspective
      );
    }
    if (this.form.value.universityHighlights) {
      postData.append(
        "universityHighlights",
        this.form.value.universityHighlights
      );
    }
    if (this.form.value.establishmentYear) {
      postData.append("establishmentYear", this.form.value.establishmentYear);
    }
    if (this.form.value.totalStudents) {
      postData.append("totalStudents", this.form.value.totalStudents);
    }
    if (this.form.value.numbersOfInternationalStudents) {
      postData.append(
        "numbersOfInternationalStudents",
        this.form.value.numbersOfInternationalStudents
      );
    }
    if (this.form.value.qsWorldRanking) {
      postData.append("qsWorldRanking", this.form.value.qsWorldRanking);
    }
    if (this.form.value.campusSize) {
      postData.append("campusSize", this.form.value.campusSize);
    }
    if (this.form.value.totalNumbersOfCampus) {
      postData.append(
        "totalNumbersOfCampus",
        this.form.value.totalNumbersOfCampus
      );
    }
    if (this.form.value.numbersOfSchoolsAndDevision) {
      postData.append(
        "numbersOfSchoolsAndDevision",
        this.form.value.numbersOfSchoolsAndDevision
      );
    }
    if (this.form.value.nobelPrizeWinnerAlumni) {
      postData.append(
        "nobelPrizeWinnerAlumni",
        this.form.value.nobelPrizeWinnerAlumni
      );
    }
    if (this.form.value.numbersOfEducationPrograms) {
      postData.append(
        "numbersOfEducationPrograms",
        this.form.value.numbersOfEducationPrograms
      );
    }

    if (this.form.value.video) {
      postData.append("video", this.form.value.video);
    }
    if (this.form.value.lng) {
      postData.append("lng", this.form.value.lng);
    }
    if (this.form.value.lat) {
      postData.append("lat", this.form.value.lat);
    }
    if (this.form.value.address) {
      postData.append("address", this.form.value.address);
    }
    if (this.form.value.districtorcity) {
      postData.append("districtorcity", this.form.value.districtorcity);
    }
    if (this.form.value.state) {
      postData.append("state", this.form.value.state);
    }
    if (this.form.value.zone) {
      postData.append("zone", this.form.value.zone);
    }
    if (this.form.value.country) {
      postData.append("country", this.form.value.country);
    }

    // meta details, other & Custom URL details
    // if (this.form.value.customurl) {
    //   postData.append("customurl", this.form.value.customurl);
    // }
    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }
    if (this.form.value.keywords) {
      postData.append("keywords", this.form.value.keywords);
    }
    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (this.form.value.content) {
      postData.append("content", this.form.value.content);
    }

    if (this.form.value.ckeditorcontent) {
      postData.append("ckeditorcontent", this.form.value.ckeditorcontent);
    }

    // Metadetails for abroad colleges page constructions  === starts here
    if (this.form.value.courseHighlights) {
      postData.append("courseHighlights", this.form.value.courseHighlights);
    }
    if (this.form.value.eligibilityCriteria) {
      postData.append(
        "eligibilityCriteria",
        this.form.value.eligibilityCriteria
      );
    }
    if (this.form.value.admissionProcedure) {
      postData.append("admissionProcedure", this.form.value.admissionProcedure);
    }
    if (this.form.value.feesOverview) {
      postData.append("feesOverview", this.form.value.feesOverview);
    }
    if (this.form.value.campusFacilityContent1) {
      postData.append(
        "campusFacilityContent1",
        this.form.value.campusFacilityContent1
      );
    }
    if (this.form.value.campusFacilityContent2) {
      postData.append(
        "campusFacilityContent2",
        this.form.value.campusFacilityContent2
      );
    }
    if (this.form.value.scholarshipOverview) {
      postData.append(
        "scholarshipOverview",
        this.form.value.scholarshipOverview
      );
    }
    // Metadetails for abroad colleges page constructions  === ends here

    /*  if(this.form.value.backgroundimg && this.form.value.backgroundimg.length > 0) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) { 
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
    } */
    postData.append("backgroundimg", this.form.value.backgroundimg);

    if (this.form.value.gallery && this.form.value.gallery.length > 0) {
      for (var i = 0; i < this.form.value.gallery.length; i++) {
        postData.append("gallery", this.form.value.gallery[i]);
      }
    }

    postData.append("logoImagePath", this.form.value.logoImagePath);
    postData.append("status", "1");

    console.log(postData);

    this.httpRequest
      .putRequest({
        url: "/abroad-college/updatecollege/" + this.router.snapshot.params.id,
        value: postData,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
            //   this.onReset();
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error) => {
          this.isSubmit = false;
          this.btntext = "Submit";
          this.isLoading = false;
          this.isdisabled = false;
        }
      );
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  checkArtsData = (value: any) => {
    let artshmArray = this.SingleObjectData.artshumanities[0].split(",");
    // let artshmArray = this.SingleObjectData.artshumanities[0]
    //   ? this.SingleObjectData.artshumanities[0].split(",")
    //   : [""];
    // console.log(artshmArray, " : artshmArray");
    for (var i = 0; i < artshmArray.length; i++) {
      if (artshmArray[i] == value) {
        return true;
      }
    }
    // return false;
    // console.log('value: '+ value);
    // let data = this.SingleObjectData.artshumanities.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
  };
  checkDesignData = (value) => {
    let designArray = this.SingleObjectData.design[0].split(",");
    for (var i = 0; i < designArray.length; i++) {
      if (designArray[i] == value) {
        return true;
      }
    }
  };
  checkSocialData = (value) => {
    // let data = this.SingleObjectData.social.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let socialArray = this.SingleObjectData.social[0].split(",");
    for (var i = 0; i < socialArray.length; i++) {
      if (socialArray[i] == value) {
        return true;
      }
    }
  };
  checkPhysicalData = (value) => {
    // let data = this.SingleObjectData.physical.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let physicalArray = this.SingleObjectData.physical[0].split(",");
    for (var i = 0; i < physicalArray.length; i++) {
      if (physicalArray[i] == value) {
        return true;
      }
    }
  };
  checkLifesciencesData = (value) => {
    // let data = this.SingleObjectData.lifesciences.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let lifesciencesArray = this.SingleObjectData.lifesciences[0].split(",");
    for (var i = 0; i < lifesciencesArray.length; i++) {
      if (lifesciencesArray[i] == value) {
        return true;
      }
    }
  };
  checkEngineeringData = (value) => {
    // let data = this.SingleObjectData.engineering.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let engineeringArray = this.SingleObjectData.engineering[0].split(",");
    for (var i = 0; i < engineeringArray.length; i++) {
      if (engineeringArray[i] == value) {
        return true;
      }
    }
  };
  checkBusinessData = (value) => {
    // let data = this.SingleObjectData.business.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let businessArray = this.SingleObjectData.business[0].split(",");
    for (var i = 0; i < businessArray.length; i++) {
      if (businessArray[i] == value) {
        return true;
      }
    }
  };
  checkMedicineData = (value) => {
    // let data = this.SingleObjectData.medicine.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let medicineArray = this.SingleObjectData.medicine[0].split(",");
    for (var i = 0; i < medicineArray.length; i++) {
      if (medicineArray[i] == value) {
        return true;
      }
    }
  };
  checkEducationData = (value) => {
    // let data = this.SingleObjectData.education.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let educationArray = this.SingleObjectData.education[0].split(",");
    for (var i = 0; i < educationArray.length; i++) {
      if (educationArray[i] == value) {
        return true;
      }
    }
  };
  checkLawData = (value) => {
    // let data = this.SingleObjectData.law.filter(item => item == value.trim())
    // return data.length > 0 ? true : false;
    let lawArray = this.SingleObjectData.law[0].split(",");
    for (var i = 0; i < lawArray.length; i++) {
      if (lawArray[i] == value) {
        return true;
      }
    }
  };

  // getChilddata(bjectId) {
  //   console.log(bjectId);
  //   if (bjectId) {
  //     this.httpRequest
  //       .getRequest({
  //         url: "/subbranch/getChildBranch?subbranchid=" + bjectId,
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           if (response.status == "success") {
  //             this.childData = response.data;
  //           }
  //         },
  //         (error) => {}
  //       );
  //   }
  // }
}
