import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http"; // Just like Axios, Browser fetch
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class HttpRequestService {
  private httpOptions;
  private posts: any[] = [];
  private postsUpdated = new Subject<{ posts; postCount: number }>();
  private postsForExport = new Subject<{ posts; postCount: number }>();
  apiBaseUrl: string = "";
  constructor(private httpClient: HttpClient) {
    this.apiBaseUrl = environment.apiUrl;
  }

  public getRequest(inputParam: any) {
    const headerDict = {
      "Content-Type": "application/json",
    };

    //const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    const body = inputParam.value;
    return this.httpClient.get(requestUrl, {
      params: body,
      headers: headerDict,
    });
  }

  //START:  College Rank List Route added by JA
  getPosts(inputParam: any) {
    // let collegename = inputParam.collegename;
    // let postsPerPage = inputParam.postsPerPage;
    // let currentPage = inputParam.currentPage;
    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    let headersOption: object = {};
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
    this.httpClient
      .get<{ message: string; posts: any; maxPosts: number }>(
        requestUrl,
        headersOption
      )
      .pipe(
        map((postData) => {
          return {
            maxPosts: postData.maxPosts,
            posts: postData.posts,

            // posts: postData.posts.map(post => {
            //   return {
            //     post
            //   };
            // }),
            //  maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe((transformedPostData) => {
        console.log(transformedPostData);
        this.posts = transformedPostData.posts;
        this.postsUpdated.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts,
        });
      });
  }

  //START:  Subscriber list get data route added
  getSubscribers(inputParam: any) {
    // let collegename = inputParam.collegename;
    // let postsPerPage = inputParam.postsPerPage;
    // let currentPage = inputParam.currentPage;
    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    let headersOption: object = {};
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
    this.httpClient
      .get<{ message: string; posts: any; maxPosts: number }>(
        requestUrl,
        headersOption
      )
      .pipe(
        map((postData) => {
          return {
            maxPosts: postData.maxPosts,
            posts: postData.posts,

            // posts: postData.posts.map(post => {
            //   return {
            //     post
            //   };
            // }),
            //  maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe((transformedPostData) => {
        console.log(transformedPostData, " : transformedPostData");
        this.posts = transformedPostData.posts;
        this.postsUpdated.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts,
        });
      });
  }

  getPostUpdateListener() {
    return this.postsUpdated.asObservable();
  }

  // export subscriber list
  exportSubscribers(inputParam: any) {
    // let collegename = inputParam.collegename;
    // let postsPerPage = inputParam.postsPerPage;
    // let currentPage = inputParam.currentPage;
    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    let headersOption: object = {};
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
    this.httpClient
      .get<{ message: string; posts: any; maxPosts: number }>(
        requestUrl,
        headersOption
      )
      .pipe(
        map((postData) => {
          return {
            maxPosts: postData.maxPosts,
            posts: postData.posts,

            // posts: postData.posts.map(post => {
            //   return {
            //     post
            //   };
            // }),
            //  maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe((transformedPostData) => {
        console.log(transformedPostData, " : transformedPostData ***");
        // return transformedPostData.asO;
        this.posts = transformedPostData.posts;
        this.postsForExport.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts,
        });
        // return this.postsForExport.asObservable();
      });
  }

  getExportCsvListener() {
    return this.postsForExport.asObservable();
  }

  public postRequest(inputParam: any) {
    let headersOption: object = {};
    // console.log(inputParam);
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
    // console.log(inputParam);

    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    return this.httpClient.post(requestUrl, body, headersOption);
  }

  public bulkPostRequest(inputParam: any) {
    let headersOption: object = {};
    console.log("Bolk Post Request - precheck : ", inputParam);
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }
    console.log("Bolk Post Request - postcheck : ", inputParam);
    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    return this.httpClient.post(requestUrl, body, headersOption);
  }

  public putRequest(inputParam: any) {
    let headersOption: object = {};
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }

    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    return this.httpClient.put(requestUrl, body, headersOption);
  }

  public patchRequest(inputParam: any) {
    let headersOption: object = {};
    if (inputParam.headers !== undefined) {
      headersOption = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
        }),
      };
    }

    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    return this.httpClient.patch(requestUrl, body, headersOption);
  }

  public deleteRequest(inputParam: any) {
    let headersOption: object = {};
    if (inputParam.headers) {
      headersOption = { headers: new HttpHeaders(inputParam.headers) };
    }
    const body = inputParam.value;
    const requestUrl = this.apiBaseUrl + inputParam.url;
    return this.httpClient.delete(requestUrl, body);
  }
}
