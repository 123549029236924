import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { isPlatformBrowser } from "@angular/common";
// import { PLATFORM_ID } from "@angular/core";
@Component({
  selector: "app-colleges",
  templateUrl: "./colleges.component.html",
  styleUrls: ["./colleges.component.css"],
})
export class AbroadCollegesComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  importbtntext = "Import from CSV";
  imagePreview: string;

  mbaData: any = [];
  userId: string;
  userIsAuthenticated = false;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  private postsSub: Subscription;
  private postsUpdated = new Subject<{ ObjectData; postCount: number }>();
  ImageBaseUrl: string = "";
  s3ImageUri: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService // @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
    this.s3ImageUri = environment.s3ImageUri;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      collegename: [""],
      status: [""],
    });

    this.fetchcollege();

    // Added listener for subscribe data
    this.postsSub = this.httpRequest
      .getPostUpdateListener()
      .subscribe((postData) => {
        this.isLoading = false;
        this.totalCount = postData.postCount;
        this.ObjectData = postData.posts;
        console.log("ObjectData");
        console.log(this.ObjectData);
      });
  }

  // Pagination to change page
  onChangedPage(pageData: any) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchcollege();
  }

  // Fetch all data from database with page size
  fetchcollege() {
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&collegename=${this.form.value.collegename}&status=${this.form.value.status}`;
    this.httpRequest.getPosts({
      url: "/abroad-college/fetchcollege" + queryParams,
    });
  }

  onSubmit() {
    console.log(this.form.value);
    this.fetchcollege();
  }

  // Handler Section for Modal
  displayStyle: any = "none";
  openPopup() {
    // setTimeout(() => {
    this.displayStyle = "block";
    // }, 10000);
  }

  // fileName: string = "";
  displayCsvData: any = [];
  // files: any = "";
  csvButtonChange(event: Event) {
    this.isLoading = true;
    this.isdisabled = true;
    this.importbtntext = "Processing";
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0) {
      alert("Please Choose any file!...");
      return;
    }
    const file: File = (target.files as FileList)[0];
    console.log("** File : ", file);
    let fileName = file.name;
    // let interimData = [];
    let extension = fileName.substring(fileName.lastIndexOf(".")).toUpperCase();
    if (extension == ".CSV") {
      //Here calling another method to read CSV file into json
      try {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          console.log(e.target.result, " : e.target.result");
          let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\n");
          console.log(rows, " : **rows");
          for (let i = 0; i < rows.length - 1; i++) {
            // Handler function to support , inside cells
            let str = rows[i];
            let cells = [];
            let inQuotes = false;
            let current = "";
            for (let h = 0; h < str.length; h++) {
              // if (str[h] === '"' || str[h] === "'") {
              if (str[h] === '"') {
                inQuotes = !inQuotes;
              }
              if (str[h] === "," && !inQuotes) {
                cells.push(current);
                current = "";
              } else {
                // if (str[h] === '"' || str[h] === "'") {
                if (str[h] === '"') {
                  // Do nothing
                } else {
                  current += str[h];
                }
              }
            }
            cells.push(current); // Push the last item
            // Handler function to support , inside cells
            // let cells = rows[i].split(",");

            let rowData = {};
            for (let j = 0; j < cells.length; j++) {
              if (i == 0) {
                let headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                let key = headers[j];
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i != 0) {
              jsonData.push(rowData);
            }
          }
          // displaying the json result in string format
          // document.getElementById("display_csv_data").innerHTML =
          //   JSON.stringify(jsonData);
          console.log("display_csv_data : ", jsonData);

          // Handler function to submit this data...
          // this.bolkOnSubmit(jsonData);
          this.httpRequest
            .bulkPostRequest({
              url: "/abroad-college/savemulticollege",
              value: jsonData,
            })
            .subscribe(
              (response: any) => {
                this.isLoading = false;
                this.isdisabled = false;
                if (response.status == "success") {
                  this.isSubmit = true;
                  // this.btntext = "Submit";
                  this.importbtntext = "Import from CSV";
                  // this.onReset();
                  setTimeout(() => {
                    this.isSubmit = false;
                  }, 3000);
                } else {
                }
              },
              (error) => {
                this.isdisabled = false;
                this.isLoading = false;
                this.importbtntext = "Import from CSV";
              }
            );
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Please select a valid csv file.");
    }
    // this.fileName = event.target.files[0].name;
    // this.files = event.target.files[0];
    // console.log("Kailash : ", event);
    // console.log("File name : ", this.fileName);
    // if (this.fileName)
  }

  closePopup() {
    this.displayStyle = "none";
    // if (isPlatformBrowser(this.platformId)) {
    // sessionStorage.setItem("modelclick", "yes");
    // }
  }
}
