export const environment = {
  production: false,
  // apiUrl: "http://localhost:3004/admin/routers",
  apiUrl: "https://stage-api.iirfranking.com/admin/routers",

  // ImageUrl: "http://localhost:3004/admin/upload/",
  ImageUrl: "https://stage-api.iirfranking.com/admin/upload/",

  // s3ImageUri: "http://localhost:3004/s3-images/",
  s3ImageUri: "https://stage-api.iirfranking.com/s3-images/",
};
