import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
@Component({
  selector: "app-common-course",
  templateUrl: "./level-specific-common-course.component.html",
  styleUrls: ["./level-specific-common-course.component.css"],
})
export class LevelSpecificCommonCourseComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  searchCourseName: string = "";

  totalCount = 0;
  postsPerPage = 50;
  pageSizeOptions = [10, 20, 50, 100, 150, 250, 500, 1000];
  currentPage = 1;

  streamOptions: any[] = [];
  commonCoursesOptions: any[] = [];
  courselevelOptions: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      stream: ["", Validators.required],
      commonCourse: ["", Validators.required],
      courselevel: ["", Validators.required],
      levelSpecifcCourseName: ["", Validators.required],
      status: ["1", Validators.required],
    });

    this.getCourseLevel();
    this.getStream();
    this.getLevelSpecificCourses();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  onSearchCourseName() {
    this.getLevelSpecificCourses(this.searchCourseName);
  }
  // Get Stream Data
  getStream() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchstreams?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.streamOptions = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getStreamSpecificCommonCourses(streamId: any) {
    this.httpRequest
      .getRequest({
        url: `/v1courses/getallcommoncourses?stream=${streamId}`,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status === "success") {
            // if (response.responseFormat[streamId].length > 0) {
            this.commonCoursesOptions = response.responseFormat[streamId];
            // }
          } else {
            this.commonCoursesOptions = [];
          }
        },
        (error) => {
          console.log(error);
          this.commonCoursesOptions = [];
        }
      );
  }

  getCourseLevel() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getcourselevels?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status === "success") {
            if (response.data.length > 0) {
              // this.ObjectData = response.data;
              this.courselevelOptions = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // Pagination to change page
  onChangedPage(pageData: any) {
    // this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.getLevelSpecificCourses();
    // this.fetchcollege();
  }

  // Handler function to get level specific Courses
  getLevelSpecificCourses(levelSpecifcCourseName: string = "") {
    this.isLoading = true;
    this.isdisabled = true;
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&levelSpecifcCourseName=${levelSpecifcCourseName}`;
    this.httpRequest
      .getRequest({
        url: "/v1courses/getLevelSpecificCommonCourses" + queryParams,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status === "success") {
            if (response.posts.length > 0) {
              this.ObjectData = response.posts;
              this.totalCount = response.maxPosts;
              this.isLoading = false;
              this.isdisabled = false;
            } else {
              this.ObjectData = []; // Set to an empty array when there's no data
            }
          }
          this.isLoading = false;
          this.isdisabled = false;
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isdisabled = false;
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/v1courses/updateLevelSpecificCommonCourse/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.getLevelSpecificCourses();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
            this.objectId = "";
            this.getLevelSpecificCourses();
            this.isSubmit = true;
            setTimeout(() => {
              this.isSubmit = true;
              this.btntext = "Submit";
              this.onReset();
              this.isdisabled = false;
            }, 2000);
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/v1courses/saveLevelSpecificCommonCourse",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response, " : response");
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.getLevelSpecificCourses();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
            this.getLevelSpecificCourses();
            this.isSubmit = true;
            setTimeout(() => {
              this.isSubmit = false;
              this.btntext = "Submit";
              this.onReset();
              this.isdisabled = false;
            }, 2000);
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
    this.text = "Add";
    this.commonCoursesOptions = [];
  }

  editform(Object: any) {
    if (Object._id) {
      this.text = "Edit";
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.getStreamSpecificCommonCourses(this.SingleObjectData.stream._id);
      this.form.patchValue({
        // courselevel: this.SingleObjectData.courselevel,
        // status: this.SingleObjectData.status,

        stream: this.SingleObjectData.stream._id,
        commonCourse: this.SingleObjectData.commonCourse._id,
        courselevel: this.SingleObjectData.courselevel._id,
        levelSpecifcCourseName: this.SingleObjectData.levelSpecifcCourseName,
        status: this.SingleObjectData.status,
      });
    }
  }
}
