import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
@Component({
  selector: "import-bulk-images",
  templateUrl: "./import-bulk-images.component.html",
  styleUrls: ["./import-bulk-images.component.css"],
})
export class ImportBulkImages implements OnInit {
  submitted = false;
  form: FormGroup;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  imageAltTag: string;
  backImagePreview: any[] = [];
  galleryPreview: any[] = [];

  ImageBaseUrl: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      logoImagePath: new FormControl(null, {}),
      backgroundimg: new FormControl([], {}),
      gallery: new FormControl([], {}),
    });
    this.imageAltTag = "Logo Image for the institute";
  }

  // Handler Function for logo image pickup
  onImagePicked(event: Event) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({ logoImagePath: file });
    this.form.get("logoImagePath").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  // Handler function for Background image Pick
  onBackgroundPicked(event: Event) {
    this.backImagePreview = [];
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ backgroundimg: files });
    this.form.get("backgroundimg").updateValueAndValidity();
    console.log(files, " : files");
    // console.log(this.form.get())

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.backImagePreview.push(reader.result as string);
      };
      reader.readAsDataURL(files[i]);
    }
  }

  onGalleryPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();
    // const reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.galleryPreview.push(reader.result as string);
        console.log(this.galleryPreview, " : this.galleryPreview");
      };
      reader.readAsDataURL(files[i]);
    }
  }

  // Handler Function for submitting images for post or put request
  onSubmit() {
    console.log(this.form.value);
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.isdisabled = true;
    this.btntext = "processing";
    let postData = new FormData();
    if (this.form.value.logoImagePath) {
      postData.append("logoImagePath", this.form.value.logoImagePath);
    }

    if (
      this.form.value.backgroundimg &&
      this.form.value.backgroundimg.length > 0
    ) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
    }

    if (this.form.value.gallery && this.form.value.gallery.length > 0) {
      for (var i = 0; i < this.form.value.gallery.length; i++) {
        postData.append("gallery", this.form.value.gallery[i]);
      }
    }

    postData.append("status", "1");

    this.httpRequest
      .postRequest({
        url: "/college/save-bulk-images",
        value: postData,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status === "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.onReset();
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
}
