import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
// import { HttpRequestService } from "../../../services/http-request.service";
import { HttpRequestService } from "../../../../services/http-request.service";
@Component({
  selector: "app-sub-stream",
  templateUrl: "./sub-stream.component.html",
  styleUrls: ["./sub-stream.component.css"],
})
export class SubStreamComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SubObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      stream: ["", Validators.required],
      substream: ["", Validators.required],
      substreamslug: [""],
      status: ["1", Validators.required],
    });

    // this.geBranch();
    this.getStream();
    // this.geSubBranch();
    this.getSubStreams();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // geBranch() {
  //   this.httpRequest
  //     .getRequest({
  //       url: "/branch/fetchbranch?status=1",
  //       //  value:JSON.stringify(this.form.value),
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         // console.log(response);
  //         if (response.status == "success") {
  //           if (response.data.length > 0) {
  //             this.ObjectData = response.data;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  getStream() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchstreams?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geSubBranch() {
    this.httpRequest
      .getRequest({
        url: "/subbranch/fetchsubbranch",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.SubObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubStreams() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchsubstreams",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          // console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.SubObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    // console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    // console.log(this.objectId);
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/v1courses/updatesubstream/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            // console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.getSubStreams();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {}
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/v1courses/savesubstream",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.getSubStreams();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {}
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object) {
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        stream: this.SingleObjectData.stream._id,
        substream: this.SingleObjectData.substream,
        substreamslug: this.SingleObjectData.substreamslug,
        status: this.SingleObjectData.status,
        // amount: this.SingleObjectData.amount,
        // tabstatus: this.SingleObjectData.tabstatus
        //   ? this.SingleObjectData.tabstatus
        //   : "",
      });
    }
  }
}
