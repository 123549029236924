import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
@Component({
  selector: "subscriber-list",
  templateUrl: "./subscriber-list.component.html",
  styleUrls: ["./subscriber-list.component.css"],
})
export class SubscriberList implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  csvObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;

  filetitle: "subscribers";

  mbaData: any = [];
  userId: string;
  userIsAuthenticated = false;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  private postsSub: Subscription;
  private exportCsvSub: Subscription;
  private postsUpdated = new Subject<{ ObjectData; postCount: number }>();
  private postsForExport = new Subject<{ csvObjectData; postCount: number }>();
  ImageBaseUrl: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
  }

  ngOnInit() {
    let todayDate = new Date();
    let last30DayDate = new Date(new Date().setDate(todayDate.getDate() - 30));

    this.form = this.formBuilder.group({
      toDate: [`${todayDate.toISOString().match(/^\d{4}-\d{2}-\d{2}/)[0]}`],
      fromDate: [
        `${last30DayDate.toISOString().match(/^\d{4}-\d{2}-\d{2}/)[0]}`,
      ],
      // fromDate: [""],
      // toDate: [""],
    });
    this.fetchcollege();
    this.exportCsv();

    // Added listener for subscribe data
    this.postsSub = this.httpRequest
      .getPostUpdateListener()
      .subscribe((postData) => {
        this.isLoading = false;
        this.totalCount = postData.postCount;
        this.ObjectData = postData.posts.map((item) => {
          item.date = item.date
            ? item.date.match(/^\d{4}-\d{2}-\d{2}/)[0]
            : null;
          return item;
        });
        // console.log(" ** ObjectData : ", this.ObjectData);
        // console.log(this.ObjectData);
      });

    // Add listner for export for CSV data
    this.exportCsvSub = this.httpRequest
      .getExportCsvListener()
      .subscribe((csvData) => {
        this.isLoading = false;
        this.totalCount = csvData.postCount;
        this.csvObjectData = csvData.posts.map((item) => {
          item.date = item.date
            ? item.date.match(/^\d{4}-\d{2}-\d{2}/)[0]
            : null;
          return item;
        });
      });
  }

  // Pagination to change page
  onChangedPage(pageData) {
    this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.fetchcollege();
  }

  // Fetch all data from database with page size
  fetchcollege() {
    console.log("This is called!");
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&fromDate=${this.form.value.fromDate}&toDate=${this.form.value.toDate}`;
    this.httpRequest.getSubscribers({
      url: "/college/subscriber-list" + queryParams,
    });
  }

  onSubmit() {
    console.log(this.form.value);
    this.fetchcollege();
    this.exportCsv();
  }

  exportCsv() {
    console.log("I am clicked!...", this.ObjectData);
    console.log("From Date : ", this.form.value.fromDate);
    console.log("To Date : ", this.form.value.toDate);

    const queryParams = `?fromDate=${this.form.value.fromDate}&toDate=${this.form.value.toDate}`;
    // const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&fromDate=${this.form.value.fromDate}&toDate=${this.form.value.toDate}`;
    this.httpRequest.exportSubscribers({
      url: "/college/export-subscriber" + queryParams,
      // url: "/college/subscriber-list" + queryParams,
    });
    console.log(this.csvObjectData, " : csvObjectData!!!!");
  }

  // convertToCSV(objArray) {
  //   var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
  //   var str = "";

  //   for (var i = 0; i < array.length; i++) {
  //     var line = "";
  //     for (var index in array[i]) {
  //       if (line != "") line += ",";

  //       line += array[i][index];
  //     }

  //     str += line + "\r\n";
  //   }

  //   return str;
  // }

  // downloadCSV(headers, items, fileTitle) {
  //   console.log(this.csvObjectData, " : this.csvObjectData");
  //   if (headers) {
  //     items.unshift(headers);
  //   }

  //   // Convert Object to JSON
  //   var jsonObject = JSON.stringify(items);

  //   var csv = this.convertToCSV(jsonObject);

  //   var exportedFilenmae = fileTitle + ".csv" || "export.csv";

  //   var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  //   // if (navigator.msSaveBlob) { // IE 10+
  //   //     navigator.msSaveBlob(blob, exportedFilenmae);
  //   // } else {
  //   var link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     // feature detection
  //     // Browsers that support HTML5 download attribute
  //     var url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", exportedFilenmae);
  //     link.style.visibility = "hidden";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  //   // }
  // }

  // headers = {
  //   model: "Phone Model".replace(/,/g, ""), // remove commas to avoid errors
  //   chargers: "Chargers",
  //   cases: "Cases",
  //   earphones: "Earphones",
  // };

  // itemsNotFormatted = [
  //   {
  //     model: "Samsung S7",
  //     chargers: "55",
  //     cases: "56",
  //     earphones: "57",
  //     scratched: "2",
  //   },
  //   {
  //     model: "Pixel XL",
  //     chargers: "77",
  //     cases: "78",
  //     earphones: "79",
  //     scratched: "4",
  //   },
  //   {
  //     model: "iPhone 7",
  //     chargers: "88",
  //     cases: "89",
  //     earphones: "90",
  //     scratched: "6",
  //   },
  // ];

  // let itemsFormatted = [];

  // // format the data
  // this.csvObjectData.forEach((item) => {
  //   itemsFormatted.push({
  //       model: item.model.replace(/,/g, ''), // remove commas to avoid errors,
  //       chargers: item.chargers,
  //       cases: item.cases,
  //       earphones: item.earphones
  //   });
  // });

  // let fileTitle = 'orders'; // or 'my-unique-title'

  downloadCSVFromJson() {
    // convert JSON to CSV
    const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
    const header = Object.keys(this.csvObjectData[0]);
    let csv: any = this.csvObjectData.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");

    console.log(csv, " : csv");

    // Create link and download
    // var link = document.createElement("a");

    //Download the file as CSV
    var downloadLink = document.createElement("a");
    var blob = new Blob(["\ufeff", csv]);
    var url = URL.createObjectURL(blob);
    downloadLink.href = url;
    downloadLink.download = "DataDump.csv"; //Name the file here
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // link.setAttribute(
    //   "href",
    //   "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURIComponent(csv)
    // );
    // link.setAttribute("download", "subscriberlist.csv");
    // link.style.visibility = "hidden";
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);
  }

  // this.downloadCSVFromJson(`myCustomName.csv`, this.state.csvArrayOfJson)
}
