import { Component, OnInit } from "@angular/core";
// import { Subscription } from "rxjs";
import { Subscription } from "rxjs";
import { AuthService } from "./auth/auth.service";
// import { ErrorService } from "./error/error.service";

// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  // hasError = false;
  // private errorSub: Subscription;
  private authListenerSubs: Subscription;
  userIsAuthenticated = false;
  constructor(
    private authService: AuthService // private errorService: ErrorService
  ) {}

  // public Editor = ClassicEditor;

  ngOnInit() {
    console.log("auth service called");
    this.authService.autoAuthUser();
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.authListenerSubs = this.authService
      .getAuthStatusListener()
      .subscribe((isAuthenticated) => {
        this.userIsAuthenticated = isAuthenticated;
      });
    this.authService.checkAuth();
    // this.errorSub = this.errorService.getErrorListener().subscribe(
    //   message => this.hasError = message !== null
    // );
  }

  // ngOnDestroy() {
  //   this.errorSub.unsubscribe();
  // }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.authListenerSubs.unsubscribe();
  }
}
