import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray, FormBuilder  } from "@angular/forms";
import { AuthService } from "../../auth/auth.service";
import { Subscription } from "rxjs";
@Component({
  selector: 'app-alumui',
  templateUrl: './alumui.component.html',
  styleUrls: ['./alumui.component.css']
})
export class AlumuiComponent implements OnInit {
  productForm: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  private authStatusSub: Subscription;
  isLinear = true;
  constructor(
    // public postsService: PostsService,
    // public route: ActivatedRoute,
    private authService: AuthService,
    private fb:FormBuilder 
  ) {
    this.productForm = this.fb.group({
      name: '',
      quantities: this.fb.array([]) , 
    });
  }
  quantities() : FormArray {
    return this.productForm.get("quantities") as FormArray
  }
   
  newQuantity(): FormGroup {
    return this.fb.group({
      qty: '',
      price: '',
      prices: '',
    })
  }
   
  addQuantity() {
    this.quantities().push(this.newQuantity());
  }
   
  removeQuantity(i:number) {
    this.quantities().removeAt(i);
  }
   
  onSubmit() {
    console.log(this.productForm.value);
  }

  ngOnInit() {
    this.authStatusSub = this.authService
    .getAuthStatusListener()
    .subscribe(authStatus => { 
      // this.isLoading = false; 
    });
    this.firstFormGroup = this.fb.group({
      name: new FormControl(),
      description: new FormControl() 
    });
    this.secondFormGroup = this.fb.group({
      amount:new FormControl(),
      stock: new FormControl()
    });
  }
  submit(){
    console.log(this.firstFormGroup.value);
    console.log(this.secondFormGroup.value);
}

}
