import { Component, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "../auth/auth.guard";
import { BranchesComponent } from "./branches/branches.component";
import { CollegesComponent } from "./colleges/colleges.component";
import { AddCollegeComponent } from "./colleges/add-college/add-college.component";
import { EditCollegeComponent } from "./colleges/edit-college/edit-college.component";
import { SubBrancheComponent } from "./branches/sub-branche/sub-branche.component";
import { UriComponent } from "./uri/uri.component";
import { AddUriComponent } from "./uri/add-uri/add-uri.component";
import { EditUriComponent } from "./uri/edit-uri/edit-uri.component";
import { YearsComponent } from "./years/years.component";
import { CollegeTypeComponent } from "./college-type/college-type.component";
import { BranchmappingComponent } from "./branches/branchmapping/branchmapping.component";
import { FaqComponent } from "./faq/faq.component";
import { AddFaqComponent } from "./faq/add-faq/add-faq.component";
import { EditFaqComponent } from "./faq/edit-faq/edit-faq.component";
import { SubscriberList } from "./subscriber-list/subscriber-list.component";
import { ImportBulkImages } from "./import-bulk-images/import-bulk-images.component";
import { ChildBranchesComponent } from "./branches/sub-branche/child-branches/child-branches.component";
import { CountriesComponent } from "./countries/countries.component";
import { CourseLevelComponent } from "./course-section/course-level.component";
import { ProgramTypeComponent } from "./course-section/program-type/program-type.component";
import { StreamComponent } from "./course-section/stream/stream.component";
import { SubStreamComponent } from "./course-section/stream/substream/sub-stream.component";
import { CollegesCoursesComponent } from "./colleges/add-college-course/add-college-course.component";
import { CommonCourseComponent } from "./common-course-section/common-course.component";
import { LevelSpecificCommonCourseComponent } from "./common-course-section/level-specific-common-courses/level-specific-common-course.component";
import { AbroadCollegesComponent } from "./abroad-colleges/colleges.component";
import { AddAbroadCollegeComponent } from "./abroad-colleges/add-college/add-college.component";
import { EditAbroadCollegeComponent } from "./abroad-colleges/edit-college/edit-college.component";
import { AddExamStudyAbroadComponent } from "./add-exam-study-abroad/add-exam-study-abroad.component";
import { StudyAbroadExamListingComponent } from "./study-abroad-exam-listing/study-abroad-exam-listing.component";
import { AddCountryComponent } from "./countries/add-country/add-country.component";
import { EditCountryComponent } from "./countries/edit-country/edit-country.component";
import { ProviderComponent } from "./provider/provider.component";
import { AddProviderComponent } from "./provider/add-provider/add-provider.component";
import { EditProviderComponent } from "./provider/edit-provider/edit-provider.component";
import { DurationComponent } from "./duration/duration.component";
import { CategoryComponent } from "./category/category.component";
import { SubCategoryComponent } from "./sub-category/sub-category.component";
import { OnlineCourseComponent } from "./online-course/online-course.component";
import { AddOnlineCourseComponent } from "./online-course/add-online-course/add-online-course.component";
import { EditOnlineCourseComponent } from "./online-course/edit-online-course/edit-online-course.component";
import { OnlineRatingComponent } from "./online-rating/online-rating.component";
import { ChildCategoryComponent } from "./child-category/child-category.component";
import { OnlineCourseLevelComponent } from "./online-course-config/online-course-level.component";
import { OnlineProgramTypeComponent } from "./online-course-config/online-program-type/online-program-type.component";
import { OnlineStreamComponent } from "./online-course-config/online-stream/online-stream.component";
import { OnlineSubStreamComponent } from "./online-course-config/online-stream/online-substream/online-sub-stream.component";
import { CatFaqComponent } from "./category/cat-faq/cat-faq.component";
import { SubCatFaqComponent } from "./sub-category/sub-cat-faq/sub-cat-faq.component";
import { ChildCatFaqComponent } from "./child-category/child-cat-faq/child-cat-faq.component";
import { CoursesFaqComponent } from "./online-course/courses-faq/courses-faq.component";
import { UpdateCommonCourseContentComponent } from "./common-course-section/update-common-courses-content/update-common-courses-content.component";
import { OnlineCourseImagesComponent } from "./online-course-images/online-course-images.component";
import { ImportCsvCoursesComponent } from "./import-csv-courses/import-csv-courses.component";
import { IIRFExamListingComponent } from "./iirf-exam-listing/iirf-exam-listing.component";
import { AddExamIIRFComponent } from "./add-exam-iirf/add-exam-iirf.component";
import { OnlineCourseUserEnqueryComponent } from './online-course-user-enquery/online-course-user-enquery.component';
const routes: Routes = [
  {
    path: "v1/branches",
    component: BranchesComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/colleges",
    component: CollegesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/colleges/add",
    component: AddCollegeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/colleges/add/course/:id",
    component: CollegesCoursesComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/colleges/edit/:id",
    component: EditCollegeComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/abroad-colleges",
    component: AbroadCollegesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/abroad-colleges/add",
    component: AddAbroadCollegeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/abroad-colleges/add/course/:id",
    component: CollegesCoursesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/abroad-colleges/edit/:id",
    component: EditAbroadCollegeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/course-csv-import",
    component: ImportCsvCoursesComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/branches/subbranch",
    component: SubBrancheComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/branches/subbranch/childbranch",
    component: ChildBranchesComponent,
    canActivate: [AuthGuard],
  },
  { path: "v1/uri", component: UriComponent, canActivate: [AuthGuard] },
  { path: "v1/uri/add", component: AddUriComponent, canActivate: [AuthGuard] },
  {
    path: "v1/uri/edit/:id",
    component: EditUriComponent,
    canActivate: [AuthGuard],
  },
  { path: "v1/year", component: YearsComponent, canActivate: [AuthGuard] },
  {
    path: "v1/manageexam",
    component: StudyAbroadExamListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/manageexam/addexam",
    component: AddExamStudyAbroadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/manageexam/editexam",
    component: AddExamStudyAbroadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/iirfexam",
    component: IIRFExamListingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/iirfexam/addexam",
    component: AddExamIIRFComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/iirfexam/editexam",
    component: AddExamIIRFComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/country",
    component: CountriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/country/add",
    component: AddCountryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/country/edit",
    component: AddCountryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/Collegetype",
    component: CollegeTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/branchmapping",
    component: BranchmappingComponent,
    canActivate: [AuthGuard],
  },
  { path: "v1/faq", component: FaqComponent, canActivate: [AuthGuard] },
  { path: "v1/faq/add", component: AddFaqComponent, canActivate: [AuthGuard] },
  {
    path: "v1/faq/edit/:id",
    component: EditFaqComponent,
  },
  {
    path: "v1/subscriber-list",
    component: SubscriberList,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/import-bulk-images",
    component: ImportBulkImages,
    canActivate: [AuthGuard],
  },
  // Implement Course Config section routing
  {
    path: "v1/courses",
    component: CourseLevelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/program-type",
    component: ProgramTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/stream",
    component: StreamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/stream/sub-stream",
    component: SubStreamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/common-course",
    component: CommonCourseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/common-course/:id",
    component: UpdateCommonCourseContentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/courses/common-course/level-specific-common-courses",
    component: LevelSpecificCommonCourseComponent,
    canActivate: [AuthGuard],
  },

  // Implement Online Course Config section routing
  {
    path: "v1/online-courses-config",
    component: OnlineCourseLevelComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses-config/program-type",
    component: OnlineProgramTypeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses-config/stream",
    // component: StreamComponent,
    component: OnlineStreamComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses-config/stream/sub-stream",
    // component: SubStreamComponent,
    component: OnlineSubStreamComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/provider",
    component: ProviderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/provider/add",
    component: AddProviderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/provider/edit/:id",
    component: EditProviderComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/duration",
    component: DurationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/category",
    component: CategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/subcategory",
    component: SubCategoryComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/online-courses",
    component: OnlineCourseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses/add",
    component: AddOnlineCourseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses/edit/:id",
    component: EditOnlineCourseComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/child-category",
    component: ChildCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-rating",
    component: OnlineRatingComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/category/faq/:id",
    component: CatFaqComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/subcategory/faq/:id",
    component: SubCatFaqComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/child-category/faq/:id",
    component: ChildCatFaqComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/online-courses/faq/:id",
    component: CoursesFaqComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "v1/online-courses-images",
    component: OnlineCourseImagesComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "v1/online-courses-user-enquiry",
    component: OnlineCourseUserEnqueryComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class V1RoutingModule {}
