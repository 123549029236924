import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { environment } from "../../../../environments/environment";
@Component({
  selector: "app-edit-online-course",
  templateUrl: "./edit-online-course.component.html",
  styleUrls: ["./edit-online-course.component.css"],
})
export class EditOnlineCourseComponent implements OnInit {
  form: FormGroup;
  ObjectCategoryData: any[] = [];
  SubCategoryArray: any[] = [];
  ChildCategoryArray: any[] = [];
  CourselevelArray: any[] = [
    { id: "1", name: "Beginners" },
    { id: "2", name: "Intermediate" },
    { id: "3", name: "Expert" },
    { id: "4", name: "All Level" },
  ];
  ProgramArray: any[] = [];
  streamArray: any[] = [];
  specializationArray: any[] = [];
  providerArray: any[] = [];
  OrganizationArray: any[] = [];
  durationArray: any[] = [
    { id: 1, durationRange: "Less than 1 month" },
    { id: 2, durationRange: "1 month to 3 months" },
    { id: 3, durationRange: "3 months to 6 months" },
    { id: 4, durationRange: "6 months to 12 months" },
    { id: 5, durationRange: "More than 12 months" },
  ];
  singleCourseData: any;
  isLoading = false;
  isdisabled = false;
  btntext = "Submit";
  isSubmit = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "500",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ],
  };
  curriculumArray: any[] = [];
  isPrice = false;
  allowedFileExtensions = ["jpg", "jpeg", "png"];
  imagePreview: string;
  ImageBaseUrl: string = "";
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private router: ActivatedRoute
  ) {
    this.ImageBaseUrl = environment.s3ImageUri;
    this.form = this.formBuilder.group({
      curriculums: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.getsingleOnlineCourse();
    this.form = new FormGroup({
      coursetitle: new FormControl(null, [Validators.required]),
      pagetitle: new FormControl(null),
      metatitle: new FormControl(null),
      keywords: new FormControl(null),
      metadescription: new FormControl(null),
      categoryid: new FormControl(null, [Validators.required]),
      subcategoryid: new FormControl(null, [Validators.required]),
      childcategoryid: new FormControl(""),
      courselevel: new FormControl(""),
      programid: new FormControl(null, [Validators.required]),
      streamid: new FormControl(null, [Validators.required]),
      providertype: new FormControl(null, [Validators.required]),
      provideid: new FormControl(null, [Validators.required]),
      providerexistence: new FormControl("", [Validators.required]),
      durationrange: new FormControl(null),
      duration: new FormControl(null),
      coursetype: new FormControl(null),
      coursemode: new FormControl(null),
      price: new FormControl(null),
      overview: new FormControl(null),
      courseHighlights: new FormControl(null),
      //curriculum: new FormControl(null),
      status: new FormControl(null),
      logoImagePath: new FormControl(null),
      curriculums: this.formBuilder.array([]),
      categoryids: new FormControl(null),
      subcategoryids: new FormControl(null),
      childcategoryids: new FormControl(null),
      courseurl: new FormControl(null),
      slug: new FormControl("", [Validators.pattern("^[a-zA-Z0-9-]*$")]),
    });

    this.geCategory();
    this.getProgramtype();
    this.getStreamType();
    this.getProvider();
    //this.getDuration();
    this.getSubCategory();
    this.getChildCategory();
  }

  initializeForm(existingData: any[]): void {
    const curriculumArray = this.form.get("curriculums") as FormArray;
    curriculumArray.clear(); // Clear existing form array

    existingData.forEach((item) => {
      curriculumArray.push(
        this.formBuilder.group({
          curriculum: [item.curriculum, Validators.required],
        })
      );
    });
  }

  ShowPriceMode(pricemode) {
    if (pricemode == "1") {
      this.isPrice = true;
    } else {
      this.isPrice = false;
    }
  }
  get curriculumsFormArr(): FormArray {
    return this.form.get("curriculums") as FormArray;
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  getcurriculumsFormArr(index): FormGroup {
    const formGroup = this.curriculumsFormArr.controls[index] as FormGroup;
    return formGroup;
  }

  addCurriculum(): void {
    // if (this.curriculumsFormArr.length < 2) {
    this.curriculumsFormArr.push(
      this.formBuilder.group({
        curriculum: [""],
      })
    );
    // }
  }

  removeCurriculum(index) {
    this.curriculumsFormArr.removeAt(index);
  }

  geCategory() {
    this.httpRequest
      .getRequest({
        url: "/category/fetchallcategory?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectCategoryData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubCategory(categoryid = "") {
    console.log(categoryid);
    this.httpRequest
      .getRequest({
        url:
          "/subcategory/fetchallsubcategory?status=1&categoryid=" + categoryid,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.SubCategoryArray = response.data;
          } else {
            this.SubCategoryArray = [];
          }
        },
        (error) => {}
      );
  }

  getChildCategory(subcategoryid = "") {
    console.log(subcategoryid);
    this.httpRequest
      .getRequest({
        url: "/subcategory/fetchchildcategory?subcategoryid=" + subcategoryid,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.ChildCategoryArray = response.data;
          } else {
            this.ChildCategoryArray = [];
          }
        },
        (error) => {}
      );
  }

  getProgramtype() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getonlinecourselevels?status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.ProgramArray = response.data;
          } else {
            this.ProgramArray = [];
          }
        },
        (error) => {}
      );
  }

  getStreamType() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchonlinestreams?status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.streamArray = response.data;
          } else {
            this.streamArray = [];
          }
        },
        (error) => {}
      );
  }

  getProvider() {
    this.httpRequest
      .getRequest({
        url: "/provider/fetchProvider?status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.providerArray = response.data;
          } else {
            this.providerArray = [];
          }
        },
        (error) => {}
      );
  }

  // getDuration() {
  //   this.httpRequest.getRequest({
  //     url: '/duration/fetchDuration?status=1'
  //     }).
  //     subscribe((response: any) => {

  //       if (response.status == 'success') {
  //         this.durationArray = response.data;
  //       } else {
  //         this.durationArray = [];
  //       }
  //     },
  //     error => {
  //     }
  //   );
  // }

  getsingleOnlineCourse() {
    this.httpRequest
      .getRequest({
        url:
          "/onlinecourse/fetchsingle-onlinecourse/" +
          this.router.snapshot.params.id,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.singleCourseData = response.data;
            if (this.singleCourseData.categoryid) {
              this.getSubCategory(this.singleCourseData.categoryid);
            }
            if (this.singleCourseData.subcategoryid) {
              this.getChildCategory(this.singleCourseData.subcategoryid);
            }
            let curriculums: [];
            // if(this.singleCourseData.curriculums.length > 0) {
            //   for (var i = 0; i < this.singleCourseData.curriculums.length; i++) {

            //     curriculums.push(this.singleCourseData.curriculums[i]);
            //   }
            // }
            for (var i = 0; i < this.singleCourseData.curriculums.length; i++) {
              this.singleCourseData.curriculums[i] = JSON.parse(
                this.singleCourseData.curriculums[i]
              );
            }
            curriculums = this.singleCourseData.curriculums;
            console.log(curriculums);
            this.initializeForm(this.singleCourseData.curriculums);
            let logoImagePath = this.singleCourseData.logoImagePath;
            this.imagePreview = this.ImageBaseUrl + "/" + logoImagePath;
            console.log(this.singleCourseData.curriculums);
            this.form.patchValue({
              coursetitle: this.singleCourseData.coursetitle,
              pagetitle: this.singleCourseData.pagetitle,
              metatitle: this.singleCourseData.metatitle,
              keywords: this.singleCourseData.keywords,
              metadescription: this.singleCourseData.metadescription,
              categoryid: this.singleCourseData.categoryid,
              subcategoryid: this.singleCourseData.subcategoryid,
              childcategoryid: this.singleCourseData.childcategoryid,
              courselevel: this.singleCourseData.courselevel,
              programid: this.singleCourseData.programid,
              streamid: this.singleCourseData.streamid,
              providertype: this.singleCourseData.providertype,
              provideid: this.singleCourseData.provideid,
              providerexistence: this.singleCourseData.providerexistence,
              durationrange: this.singleCourseData.durationrange,
              duration: this.singleCourseData.duration,
              coursetype: this.singleCourseData.coursetype,
              coursemode: this.singleCourseData.coursemode,
              price: this.singleCourseData.price,
              overview: this.singleCourseData.overview,
              courseHighlights: this.singleCourseData.courseHighlights,
              curriculums: curriculums,
              status: this.singleCourseData.status,
              categoryids: this.singleCourseData.categoryids,
              subcategoryids: this.singleCourseData.subcategoryids,
              childcategoryids: this.singleCourseData.childcategoryids,
              courseurl: this.singleCourseData.courseurl,
              slug: this.singleCourseData.slug,
            });
            this.ShowPriceMode(this.singleCourseData.coursemode);
          } else {
            this.singleCourseData = [];
          }
        },
        (error) => {}
      );
  }

  // get file
  onImagePicked(event: Event) {
    console.log(event);
    const file = (event.target as HTMLInputElement).files[0];
    console.log("reader.file : ", file);
    this.form.patchValue({ logoImagePath: file });
    this.form.get("logoImagePath").updateValueAndValidity();
    const reader = new FileReader();
    reader.onload = () => {
      this.imagePreview = reader.result as string;
      console.log("reader.result : ", reader.result);
    };
    reader.readAsDataURL(file);
  }

  onSubmit(event: Event) {
    event.preventDefault();
    this.submitted = true;
    if (this.form.invalid) {
      console.log("Form Vlidation Failed!...");
      return;
    }
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    console.log(this.form.value);
    // if(this.form.value.curriculum){
    //   this.curriculumArray = this.form.value.curriculum.split(',');
    //   if(this.curriculumArray.length > 0) {
    //     this.form.value.curriculum = this.curriculumArray;
    //   }
    // }
    // if(this.form.value.curriculums.length > 0){
    //   for(var i= 0; i < this.form.value.curriculums.length; i++){
    //     this.curriculumArray = this.form.value.curriculums.curriculum.split(',');

    //   }
    // }
    let postData = new FormData();
    if (this.form.value.curriculums.length > 0) {
      for (var i = 0; i < this.form.value.curriculums.length; i++) {
        postData.append(
          "curriculums",
          JSON.stringify(this.form.value.curriculums[i])
        );
      }
    }

    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }

    if (this.form.value.keywords) {
      postData.append("keywords", this.form.value.keywords);
    }

    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (this.form.value.coursetitle) {
      postData.append("coursetitle", this.form.value.coursetitle);
    }

    if (this.form.value.pagetitle) {
      postData.append("pagetitle", this.form.value.pagetitle);
    }

    if (this.form.value.categoryid) {
      postData.append("categoryid", this.form.value.categoryid);
    }
    if (this.form.value.subcategoryid) {
      postData.append("subcategoryid", this.form.value.subcategoryid);
    }

    if (this.form.value.childcategoryid) {
      postData.append("childcategoryid", this.form.value.childcategoryid);
    }

    if (this.form.value.courselevel) {
      postData.append("courselevel", this.form.value.courselevel);
    }

    if (this.form.value.programid) {
      postData.append("programid", this.form.value.programid);
    }
    if (this.form.value.streamid) {
      postData.append("streamid", this.form.value.streamid);
    }

    if (this.form.value.providertype) {
      postData.append("providertype", this.form.value.providertype);
    }

    if (this.form.value.provideid) {
      postData.append("provideid", this.form.value.provideid);
    }
    if (this.form.value.providerexistence) {
      postData.append("providerexistence", this.form.value.providerexistence);
    }

    if (this.form.value.durationrange) {
      postData.append("durationrange", this.form.value.durationrange);
    }
    if (this.form.value.duration) {
      postData.append("duration", this.form.value.duration);
    }
    if (this.form.value.coursetype) {
      postData.append("coursetype", this.form.value.coursetype);
    }

    if (this.form.value.coursemode) {
      postData.append("coursemode", this.form.value.coursemode);
    }
    if (this.form.value.slug) {
      postData.append("slug", this.form.value.slug);
    }
    if (this.form.value.price) {
      postData.append("price", this.form.value.price);
    }
    if (this.form.value.overview) {
      postData.append("overview", this.form.value.overview);
    }
    if (this.form.value.courseHighlights) {
      postData.append("courseHighlights", this.form.value.courseHighlights);
    }
    if (this.form.value.status) {
      postData.append("status", this.form.value.status);
    }
    postData.append("logoImagePath", this.form.value.logoImagePath);

    if (this.form.value.categoryids) {
      // for (var i = 0; i < this.form.value.categoryids.length; i++) {
      //  postData.append("categoryids", JSON.stringify(this.form.value.categoryids[i]));
      // }
      postData.append(
        "categoryids",
        JSON.stringify(this.form.value.categoryids)
      );
    } else {
      postData.append("categoryids", JSON.stringify([]));
    }

    if (this.form.value.subcategoryids) {
      // for (var i = 0; i < this.form.value.subcategoryids.length; i++) {
      //  postData.append("subcategoryids", JSON.stringify(this.form.value.subcategoryids[i]));
      // }
      postData.append(
        "subcategoryids",
        JSON.stringify(this.form.value.subcategoryids)
      );
    } else {
      postData.append("subcategoryids", JSON.stringify([]));
    }

    if (this.form.value.childcategoryids) {
      // for (var i = 0; i < this.form.value.childcategoryids.length; i++) {
      //  postData.append("childcategoryids", JSON.stringify(this.form.value.childcategoryids[i]));
      // }
      postData.append(
        "childcategoryids",
        JSON.stringify(this.form.value.childcategoryids)
      );
    } else {
      postData.append("childcategoryids", JSON.stringify([]));
    }

    if (this.form.value.courseurl) {
      postData.append("courseurl", this.form.value.courseurl);
    }

    this.httpRequest
      .putRequest({
        url:
          "/onlinecourse/update-onlinecourse/" + this.router.snapshot.params.id,
        value: postData,
        // headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;

          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            setTimeout(() => {
              this.isSubmit = false;
              this.btntext = "Submit";
            }, 2000);
          } else {
            this.isLoading = false;
            this.btntext = "Submit";
            this.isdisabled = false;
          }
        },
        (error) => {
          this.isLoading = false;
          this.btntext = "Submit";
          this.isdisabled = false;
        }
      );
  }
}
