import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-countries",
  templateUrl: "./countries.component.html",
  styleUrls: ["./countries.component.css"],
})
export class CountriesComponent implements OnInit {
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfig = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
        { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
        { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
        { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
        { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  submitted = false;
  form: FormGroup;
  // objectId: any;
  ObjectData: any[] = [];
  // SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  // text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}
  // onEditorReady(event: any, index: number) {
  //   event.editor.model.document.on("change:data", () => {
  //     this.form.controls.program_framework.at(index).get("content").setValue(event.editor.getData());
  //   });
  // }

  ngOnInit() {
    this.form = this.formBuilder.group({
      // country: ["", Validators.required],
      program_framework: this.formBuilder.array([]),
      country: [""],
      status: ["1", Validators.required],
    });
    this.geCountries();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // Here this function should be converted to fetch countries lists
  geCountries() {
    this.httpRequest
      .getRequest({
        url: "/country/fetchCountries",
        // value: JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value, " :: country connect!");
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing...";

    this.httpRequest
      .getRequest({
        url: `/country/fetchCountries?country=${
          this.form.value.country ? this.form.value.country : ""
        }&status=${this.form.value.status}`,
        // value: JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
            this.isLoading = false;
            this.isdisabled = false;
            this.btntext = "Submit";
          }
        },
        (error) => {
          console.log(error);
          this.isLoading = false;
          this.isdisabled = false;
          this.btntext = "Submit";
        }
      );
  }

  // onSubmit() {
  //   this.submitted = true;
  //   if (this.form.invalid) {
  //     return;
  //   }
  //   console.log(this.form.value);
  //   this.isLoading = true;
  //   this.isdisabled = true;
  //   this.btntext = "Processing ...";
  //   console.log(this.objectId);
  //   if (this.objectId) {
  //     this.httpRequest
  //       .putRequest({
  //         url: "/country/updateCountry/" + this.objectId,
  //         value: JSON.stringify(this.form.value),
  //         headers: { "content-type": "application/json" },
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           console.log(response);
  //           this.isLoading = false;
  //           if (response.status == "success") {
  //             this.objectId = "";
  //             this.geCountries();
  //             this.isSubmit = true;
  //             this.onReset();
  //             setTimeout(() => {
  //               this.isSubmit = true;
  //               this.btntext = "Submit";
  //               this.isdisabled = false;
  //             }, 1000);
  //           } else {
  //           }
  //         },
  //         (error) => {}
  //       );
  //   } else {
  //     this.httpRequest
  //       .postRequest({
  //         url: "/country/saveCountry",
  //         value: JSON.stringify(this.form.value),
  //         headers: { "content-type": "application/json" },
  //       })
  //       .subscribe(
  //         (response: any) => {
  //           this.isLoading = false;

  //           this.isdisabled = false;
  //           if (response.status == "success") {
  //             this.geCountries();
  //             this.isSubmit = true;
  //             setTimeout(() => {
  //               this.isSubmit = false;
  //               this.btntext = "Submit";
  //               this.onReset();
  //             }, 2000);
  //           } else {
  //           }
  //         },
  //         (error) => {}
  //       );
  //   }
  // }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    // this.objectId = "";
    // this.SingleObjectData = {};
  }

  // editform(Object: any) {
  //   console.log(Object);
  //   if (Object._id) {
  //     this.objectId = Object._id;
  //     this.SingleObjectData = Object;
  //     this.form.patchValue({
  //       country: this.SingleObjectData.country,
  //       status: this.SingleObjectData.status,
  //     });
  //   }
  // }
}
