import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  Validators,
  FormBuilder,
  AbstractControl,
  ValidatorFn
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";
import { ActivatedRoute } from "@angular/router";
import { environment } from "../../../../environments/environment";
@Component({
  selector: 'app-edit-provider',
  templateUrl: './edit-provider.component.html',
  styleUrls: ['./edit-provider.component.css']
})
export class EditProviderComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "500",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ],
  };
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  allowedFileExtensions = ['jpg', 'jpeg', 'png'];
  imagePreview: string;
  ImageBaseUrl: string = "";
  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService,
    private router: ActivatedRoute
  ) {
    this.ImageBaseUrl = environment.ImageUrl;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      provider_name: ["", Validators.required],
      logoImagePath:["", this.fileUploadValidator(this.allowedFileExtensions)],
      providerexistence: ["1", Validators.required],
      providertype: ["1", Validators.required],
      overview: [""],
      status: ["1", Validators.required],
    });

    this.getsingleProvider();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }
  // file format validation
  fileUploadValidator(allowedExtensions: any): ValidatorFn {
    console.log('inside validation');
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      // Enter to validation only if has value or it's not undefined
      if (control.value !== undefined && isNaN(control.value)) {
        const file = control.value;
        // Get extension from file name
        // const ext = file.substring(file.lastIndexOf('.') + 1);
        // // Find extension file inside allowed extensions array
        // if (allowedExtensions.includes(ext.toLowerCase())) {
        // } else {
        //   return { extensionFile: true };
        // }
      }
      return null;
    };
  }

// get file 
onImagePicked(event: Event) {
  console.log(event);
  const file = (event.target as HTMLInputElement).files[0];
  console.log('reader.file : ', file);
  this.form.patchValue({ logoImagePath: file });
  this.form.get("logoImagePath").updateValueAndValidity();
  const reader = new FileReader();
  reader.onload = () => {
    this.imagePreview = reader.result as string;
    console.log('reader.result : ', reader.result);
  };
  reader.readAsDataURL(file);
}

  getsingleProvider(){
    this.httpRequest.getRequest({
      url: '/provider/getsingleProvider/'+this.router.snapshot.params.id
      }).
      subscribe((response: any) => {
       
        if (response.status == 'success') {
          this.SingleObjectData = response.data;
          let curriculum = this.SingleObjectData.curriculum;
          let  logoImagePath = this.SingleObjectData.logoImagePath
          this.imagePreview = this.ImageBaseUrl+'/'+logoImagePath;
         
          this.form.patchValue({
            provider_name: this.SingleObjectData.provider_name,
            logoImagePath: this.SingleObjectData.logoImagePath,
            providerexistence: this.SingleObjectData.providerexistence,
            providertype: this.SingleObjectData.providertype,
            overview: this.SingleObjectData.overview,
            status: this.SingleObjectData.status,
          });
        } else {
          this.SingleObjectData = [];
        }
      },
      error => {
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    console.log(this.form.value);
    if (this.form.invalid) {
      return;
    }
    let postData = new FormData();
    if (this.form.value.provider_name) {
      postData.append("provider_name", this.form.value.provider_name);
    }

    if (this.form.value.logoImagePath) {
      postData.append("logoImagePath", this.form.value.logoImagePath);
    }

    if (this.form.value.providerexistence) {
      postData.append("providerexistence", this.form.value.providerexistence);
    }

    if (this.form.value.providertype) {
      postData.append("providertype", this.form.value.providertype);
    }

    if (this.form.value.overview) {
      postData.append("overview", this.form.value.overview);
    }

    if (this.form.value.status) {
      postData.append("status", this.form.value.status);
    }

    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
      this.httpRequest
        .putRequest({
          url: "/provider/updateProvider/" + this.router.snapshot.params.id,
          value: postData,
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
              }, 2000);
            } else {
              this.isLoading = false;
              this.btntext = "Submit";
              this.isdisabled = false;
            }
          },
          (error) => {
            this.isLoading = false;
            this.btntext = "Submit";
            this.isdisabled = false;
          }
        );
  }


}
