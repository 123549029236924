import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
@Component({
  selector: "app-course-level",
  templateUrl: "./course-level.component.html",
  styleUrls: ["./course-level.component.css"],
})
export class CourseLevelComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      courselevel: ["", Validators.required],
      courselevelname: ["", Validators.required],
      status: ["1", Validators.required],
    });

    this.getCourseLevel();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getCourseLevel() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getcourselevels",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status === "success") {
            if (response.data.length > 0) {
              this.ObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/v1courses/updatecourselevel/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              this.getCourseLevel();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/v1courses/savecourselevel",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              this.getCourseLevel();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  editform(Object) {
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        courselevel: this.SingleObjectData.courselevel,
        courselevelname: this.SingleObjectData.courselevelname,
        status: this.SingleObjectData.status,
      });
    }
  }
}
