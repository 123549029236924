import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

@Injectable({
  providedIn: "root", // or provide it in another module
})
export class ConfirmationDialogService {
  constructor(public dialog: MatDialog) {}

  openConfirmationDialog(): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent);

    return dialogRef.afterClosed().toPromise();
  }
}
