import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { PostListComponent } from "./posts/post-list/post-list.component";
import { PostCreateComponent } from "./posts/post-create/post-create.component";
import { AuthGuard } from "./auth/auth.guard";
import { AlumuiComponent } from "./posts/alumui/alumui.component";
import { LoginComponent } from "./auth/login/login.component";
import { AddMetaComponent } from "./posts/add-meta/add-meta.component";
import { NameComponentComponent } from "./name-component/name-component.component";
import { EditComponent } from "./posts/edit/edit.component";

const routes: Routes = [
  { path: "listing-mba-ranking", component: PostListComponent },
  // { path: "demo", component: NameComponentComponent },
  { path: "add-mba-ranking-meta", component: AddMetaComponent },
  { path: "add-mba-ranking", component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: "add-alumni", component: AlumuiComponent, canActivate: [AuthGuard] },
  { path: "edit/:postId", component: PostCreateComponent, canActivate: [AuthGuard] },
  { path: "mbaedit/:postId", component: EditComponent, canActivate: [AuthGuard] },
  { path: "auth", loadChildren: "./auth/auth.module#AuthModule"},
  { path: "", loadChildren: "./universities/universities.module#UniversitiesModule", }, 
  { path: "", loadChildren: "./studyIn/studyin.module#StudyinModule", },
  { path: "", loadChildren: "./mbbs/mbbs.module#MbbsModule"},
  { path: "", loadChildren: "./mba/mba.module#MbaModule"},
 
  { path: "", loadChildren: "./architecture/architecture.module#ArchitectureModule"},
  { path: "", loadChildren: "./architecture-govt/architecture.module#ArchitectureGovtModule"}, 
  { path: "", loadChildren: "./universities_new/universities.module#UniversitiesModule"}, 
  { path: "", loadChildren: './engineering/engineering.module#EngineeringModule'},
  //  {  
  //   path: '', 
  //   loadChildren: () => import('./mbbs/mbbs.module').then(m => m.MbbsModule),canActivate: [AuthGuard]
  // },

  { path: 'engineering-colleges', loadChildren: './engineering-colleges/engineering-colleges.module#EngineeringCollegesModule'},
  { path: "", loadChildren: './law/law.module#LawModule'},

  { path: "", loadChildren: './law-govt/law.module#LawGovtModule'},
  { path: "", loadChildren: './Design-govt/design.module#DesignGovtModule'}, 
  { path: "", loadChildren: './Design-Private/design.module#DesignPrivateModule'}, 
  { path: "", loadChildren: './mba-Private/mba.module#MbaPrivateModule'}, 
  { path: "", loadChildren: './mba-Govt/mba-govt.module#MbaGovtModule'}, 
  
  { path: "", loadChildren: "./all-page-data/mba/page.module#PageDataModule"},
   
  { path: "", loadChildren: "./courses/courses.module#CoursesModule"},

  { path: "", loadChildren: "./career-guidance/career-guidance.module#CareerGuidanceModule"},
  // { path: 'mba-colleges', loadChildren: () => import('./all-page-data/mba-colleges/mba-colleges.module').then(m => m.MbaCollegesModule) },
  // { path: 'engineering-colleges', loadChildren: './engineering-colleges/engineering-colleges.module#EngineeringCollegesModule'},
  // {  
  //   path: '',  
  //   loadChildren: () => import('./universities/universities.module').then(m => m.UniversitiesModule),canActivate: [AuthGuard]
  // },


  { path: "v1", loadChildren: "./v1/v1.module#V1Module"},
];

@NgModule({ 
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
