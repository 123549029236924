import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "../../../services/http-request.service";
@Component({
  selector: "app-add-uri",
  templateUrl: "./add-uri.component.html",
  styleUrls: ["./add-uri.component.css"],
})
export class AddUriComponent implements OnInit {
  yearsArray: any[] = []; // ['2020','2021','2022','2023','2024'];
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  BranchObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  subbranches: any[] = [];
  textmesg = "";
  alertclass = "success";

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      yearid: ["", Validators.required],
      branchid: ["", Validators.required],
      subbranchid: [""],
      uri: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9-]+$/)]],
      status: ["1", Validators.required],
      tabs: [
        "",
        [
          Validators.pattern(
            /^([a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+-)*[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+$/
          ),
        ],
      ],
      combinedranking: ["0", Validators.required],
    });

    this.geBranch();
    this.geyears();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geyears() {
    this.httpRequest
      .getRequest({
        url: "/year/fetchYears?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.yearsArray = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.BranchObjectData = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubBranchdata(branchObjectId) {
    console.log(branchObjectId);
    this.httpRequest
      .getRequest({
        url:
          "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subbranches = response.data;
          }
        },
        (error) => {}
      );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.form.value.subbranchid == "") {
      this.form.removeControl("subbranchid");
    }
    this.httpRequest
      .postRequest({
        url: "/uri/saveuri",
        value: JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (!this.form.get("subbranchid")) {
            this.form.addControl("subbranchid", new FormControl(""));
          }
          this.isLoading = false;
          this.isdisabled = false;
          console.log(response);
          if (response.status == "success") {
            this.alertclass = "success";
            this.isSubmit = true;
            this.textmesg = "Data save successfully!";
            setTimeout(() => {
              this.isSubmit = false;
              this.btntext = "Submit";
              this.onReset();
            }, 2000);
          } else if (response.status == "422") {
            this.textmesg = response.message;
            this.alertclass = "info";
            this.isSubmit = true;
            console.log(response.status);
            setTimeout(() => {
              this.isSubmit = false;
              this.btntext = "Submit";
            }, 2000);
          }
        },
        (error) => {}
      );
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }
}
