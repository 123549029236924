import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
  FormArray,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-add-college",
  templateUrl: "./add-college.component.html",
  styleUrls: ["./add-college.component.css"],
})
export class AddAbroadCollegeComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  countries: any[] = [];
  // branches: any[] = [];
  childData: any[] = [];
  // streams: any[] = [];
  courseLevels: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  importbtntext = "Import from CSV";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  backImagePreview: any[] = [];
  galleryPreview: any[] = [];
  subbranches: any[] = [];
  public editorConfigs = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "imageUpload",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add other heading levels as needed
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:full",
        "imageStyle:side",
      ],
      styles: ["full", "side"],
      customUploadFileName: (file) => {
        return "custom-prefix-" + file.name;
      },
    },
  };

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfig = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  @ViewChild("educationinputValue", { static: false })
  educationinputValue: ElementRef;
  @ViewChild("educationinputId", { static: false })
  educationinputId: ElementRef;

  // dynamicCourseName: string;
  // dynamicCourseCode: string;

  // @ViewChild("courseNameInput", { static: false })
  // courseNameInput: ElementRef;
  // @ViewChild("courseCodeInput", { static: false })
  // courseCodeInput: ElementRef;

  @ViewChildren("courseNameInput") courseNameInputs: QueryList<ElementRef>;
  @ViewChildren("courseCodeInput") courseCodeInputs: QueryList<ElementRef>;

  // @ViewChild("lawinputValue", { static: false }) lawinputValue: ElementRef;
  // @ViewChild("lawinputId", { static: false }) lawinputId: ElementRef;

  lawarr = [
    {
      id: "1",
      value: "Law",
    },
  ];

  // alumni: any[] = [
  //   {
  //     "profileimg": "",
  //     "profilename": "",
  //     "profilepos": "",
  //   }
  // ]

  addNewLaw() {
    this.lawarr.push({
      id: this.educationinputId.nativeElement.value,
      value: this.educationinputValue.nativeElement.value,
    });
  }

  // New Course Options
  courseOptions: any = {};

  addNewCourse(courseLevel: any, stream: any) {
    console.log(courseLevel, " : courseLevel");
    console.log(stream, " : stream");

    // Get the input values
    const courseNameInputsArray = this.courseNameInputs.toArray();
    const courseCodeInputsArray = this.courseCodeInputs.toArray();

    const courseName = courseNameInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseNameInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value;
    // Clearing Value
    courseNameInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseNameInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value = "";

    const courseCode = courseCodeInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseCodeInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value;
    // Clearing Value
    courseCodeInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseCodeInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value = "";

    // Use the input values in your function
    console.log("Course Name:", courseName);
    console.log("Course Code:", courseCode);

    console.log(this.courseOptions, " : __courseOptions");

    let payload: any = {
      courselevel: courseLevel._id,
      stream: stream._id,
    };
    if (courseName) {
      payload.courseName = courseName;
    }
    if (courseCode) {
      payload.courseCode = courseCode;
    } else {
      payload.courseCode = " ";
    }

    this.httpRequest
      .postRequest({
        url: "/v1courses/savecommoncourse",
        value: payload,
      })
      .subscribe(
        (response: any) => {
          // this.isLoading = false;
          // this.isdisabled = false;
          if (response.status == "success") {
            console.log("Course Added Successfully!");
          } else {
            console.log("Unable to register the course");
          }
        },
        (error) => {
          console.log(error);
          // this.isLoading = false;
          // this.isdisabled = false;
          // this.isSubmit = false;
        }
      );
  }

  // Fetch Course for a level
  tabClicked(courseLevel: any) {
    console.log("Tab clicked:", courseLevel);

    this.fetchCourseForThisLevel(courseLevel.courselevel);
  }

  fetchCourseForThisLevel(courselevel: any) {
    console.log(courselevel, " : courselevel");
  }

  collegetypes: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {
    this.selectedOptions;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      //  collegetypeid: ["", Validators.required],
      type: ["", Validators.required],
      // branchid: ["", Validators.required],
      // subbranchid: [""],
      // childbranchid: [""],
      countryid: ["", Validators.required],
      // subbranchid: ["", Validators.required],
      collegename: ["", Validators.required],
      address: ["", Validators.required],
      phone: [""],
      email: [""],
      websitelink: [""],
      admissionssupport: [""],
      facebookpagelink: [""],
      linkedinpagelink: [""],
      twitterpagelink: [""],

      percentageofinternationalstudents: [""],
      facultytostudentsration: [""],
      highestplacementpackage: [""],
      scholarshipavailability: [""],
      averageplacementpackage: [""],
      researchperspective: [""],
      // ============================
      universityHighlights: [""],
      establishmentYear: [""],
      totalStudents: [""],
      numbersOfInternationalStudents: [""],
      qsWorldRanking: [""],
      campusSize: [""],
      totalNumbersOfCampus: [""],
      numbersOfSchoolsAndDevision: [""],
      nobelPrizeWinnerAlumni: [""],
      numbersOfEducationPrograms: [""],
      seoMetaFields: this.formBuilder.array([this.initializeSEOMeta()]),
      metaTitle: [""],
      video: [""],
      lng: [""],
      lat: [""],
      content: [""],
      ckeditorcontent: [""],
      courseHighlights: [""],
      eligibilityCriteria: [""],
      admissionProcedure: [""],
      feesOverview: [""],
      campusFacilityContent1: [""],
      campusFacilityContent2: [""],
      scholarshipOverview: [""],

      status: ["1"],

      customurl: [
        "",
        [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)],
      ],

      keywords: [""],
      metadescription: [""],

      checked: [false],
      indeterminate: [false],

      logoImagePath: new FormControl(null, {
        // validators: [Validators.required],
        // asyncValidators: [mimeType]
      }),
      // backgroundimg: [""],
      backgroundimg: new FormControl([], {}),
      // gallery: [""],
      gallery: new FormControl([], {}),
    });
    // this.geBranch();
    this.getCountries();
  }

  initializeSEOMeta(): FormGroup {
    return this.formBuilder.group({
      metaName: ["", Validators.required],
      metaContent: ["", Validators.required],
    });
  }

  addSEOMeta() {
    const seoFields = this.form.get("seoMetaFields") as FormArray;
    seoFields.push(this.initializeSEOMeta());
  }

  removeSEOMeta(index: number) {
    const seoFields = this.form.get("seoMetaFields") as FormArray;
    seoFields.removeAt(index);
  }

  getCountries() {
    this.httpRequest
      .getRequest({
        url: "/country/fetchCountries?status=1&onlyAbroad=true",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response, " :countries");
          if ((response.status = "success")) {
            if (response.data.length > 0) {
              this.countries = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  options = [
    { name: "OptionA", value: "1", checked: true },
    { name: "OptionB", value: "2", checked: false },
    { name: "OptionC", value: "3", checked: true },
  ];

  get selectedOptions() {
    // right now: ['1','3']
    return this.options.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  // compareWithFunc(a, b) {
  //   return a.name === b.name;
  // }

  onImagePicked(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in kilobytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        this.form.get("logoImagePath").setValue(null);
        this.imagePreview = "";
      } else {
        this.form.patchValue({ logoImagePath: file });
        this.form.get("logoImagePath").updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onBackgroundPicked(event: Event) {
    this.backImagePreview = [];
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;
    const maxSizeInKB = 1024; // Define your maximum size in kilobytes (KB)

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        alert("Background image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        this.form.get("backgroundimg").setValue(null);
        this.backImagePreview = [];
        break; // Exit the loop if one file exceeds the size limit
      } else {
        this.form.patchValue({ backgroundimg: files });
        this.form.get("backgroundimg").updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
          this.backImagePreview.push(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  // function with out validation
  // onImagePicked(event: Event) {
  //   console.log(event);
  //   const file = (event.target as HTMLInputElement).files[0];
  //   this.form.patchValue({ logoImagePath: file });
  //   this.form.get("logoImagePath").updateValueAndValidity();
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imagePreview = reader.result as string;
  //     // console.log('reader.result : ', reader.result);
  //     //  this.alldata.logoImagePath = reader.result as string;
  //     // backgroundimg
  //   };
  //   reader.readAsDataURL(file);
  // }

  // onBackgroundPicked(event: Event) {
  //   this.backImagePreview = [];
  //   // console.log(event);
  //   const files = (event.target as HTMLInputElement).files;
  //   this.form.patchValue({ backgroundimg: files });
  //   this.form.get("backgroundimg").updateValueAndValidity();
  //   console.log(files, " : files");
  //   // console.log(this.form.get())

  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.backImagePreview.push(reader.result as string);
  //       // console.log(this.backImagePreview, " : this.backImagePreview");
  //     };
  //     reader.readAsDataURL(files[i]);
  //   }

  //   // console.log("file: ", files);
  //   // // console.log(this.back);
  //   // // const reader = new FileReader();
  //   // // this.alldata.backgroundimg = reader.result as string;
  //   // // reader.onload = () => {
  //   // //   this.backImagePreview = reader.result as string;
  //   // // };
  //   // // reader.readAsDataURL(file);
  //   // function readFile(index: any) {
  //   //   if (index >= files.length) return;
  //   //   const reader = new FileReader();
  //   //   let file = files[index];
  //   //   reader.readAsDataURL(file);
  //   //   reader.onload = () => {
  //   //     // get file content
  //   //     // this.galleryPreview();
  //   //     this.backImagePreview.push(reader.result as string);
  //   //     console.log(this.backImagePreview, " : backImagePreview");

  //   //     // var bin = e.target.result;
  //   //     // do sth with bin
  //   //     readFile(index + 1);
  //   //   };
  //   //   // reader.readAsBinaryString(file);
  //   //   // reader.readAsDataURL(file);
  //   // }
  //   // readFile(0);
  // }

  onGalleryPicked(event: Event) {
    this.galleryPreview = [];
    // console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();
    // console.log("file: ", files);
    // const reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.galleryPreview.push(reader.result as string);
        // console.log(this.galleryPreview, " : this.galleryPreview");
      };
      reader.readAsDataURL(files[i]);
    }
  }

  onSubmit() {
    console.log("Submit : ", this.form);
    // return;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.isdisabled = true;
    this.btntext = "Processing...";
    const seoMetaArray = this.form.get("seoMetaFields").value.map((seo) => ({
      metaName: seo.metaName,
      metaContent: seo.metaContent,
    }));
    const metaTitleValue = this.form.value.metaTitle;
    const seoData = {
      seo: {
        meta: seoMetaArray,
        metaTitle: metaTitleValue,
      },
    };
    let postData = new FormData();
    // Append the SEO data to the FormData
    postData.append("seo", JSON.stringify(seoData));

    if (this.form.value.type) {
      postData.append("type", this.form.value.type);
    }

    if (this.form.value.countryid) {
      postData.append("countryid", this.form.value.countryid);
    }

    // if (this.form.value.branchid) {
    //   postData.append("branchid", this.form.value.branchid);
    // }

    if (this.form.value.subbranchid) {
      postData.append("subbranchid", this.form.value.subbranchid);
    }
    if (this.form.value.childbranchid) {
      postData.append("childbranchid", this.form.value.childbranchid);
    }
    // if (this.form.value.collegetypeid) {
    //   postData.append("collegetypeid", this.form.value.collegetypeid);
    // }

    if (this.form.value.collegename) {
      postData.append("collegename", this.form.value.collegename);
    }

    if (this.form.value.universityHighlights) {
      postData.append(
        "universityHighlights",
        this.form.value.universityHighlights
      );
    }

    if (this.form.value.establishmentYear) {
      postData.append("establishmentYear", this.form.value.establishmentYear);
    }

    // if (this.form.value.area) {
    //   postData.append("area", this.form.value.area);
    // }

    if (this.form.value.districtorcity) {
      postData.append("districtorcity", this.form.value.districtorcity);
    }

    if (this.form.value.state) {
      postData.append("state", this.form.value.state);
    }

    if (this.form.value.zone) {
      postData.append("zone", this.form.value.zone);
    }

    if (this.form.value.country) {
      postData.append("country", this.form.value.country);
    }

    if (this.form.value.phone) {
      postData.append("phone", this.form.value.phone);
    }
    if (this.form.value.email) {
      postData.append("email", this.form.value.email);
    }
    if (this.form.value.websitelink) {
      postData.append("websitelink", this.form.value.websitelink);
    }

    if (this.form.value.admissionssupport) {
      postData.append("admissionssupport", this.form.value.admissionssupport);
    }
    if (this.form.value.facebookpagelink) {
      postData.append("facebookpagelink", this.form.value.facebookpagelink);
    }
    if (this.form.value.linkedinpagelink) {
      postData.append("linkedinpagelink", this.form.value.linkedinpagelink);
    }
    if (this.form.value.twitterpagelink) {
      postData.append("twitterpagelink", this.form.value.twitterpagelink);
    }

    if (this.form.value.percentageofinternationalstudents) {
      postData.append(
        "percentageofinternationalstudents",
        this.form.value.percentageofinternationalstudents
      );
    }
    if (this.form.value.totalStudents) {
      postData.append("totalStudents", this.form.value.totalStudents);
    }
    if (this.form.value.numbersOfInternationalStudents) {
      postData.append(
        "numbersOfInternationalStudents",
        this.form.value.numbersOfInternationalStudents
      );
    }
    if (this.form.value.qsWorldRanking) {
      postData.append("qsWorldRanking", this.form.value.qsWorldRanking);
    }
    if (this.form.value.campusSize) {
      postData.append("campusSize", this.form.value.campusSize);
    }
    if (this.form.value.totalNumbersOfCampus) {
      postData.append(
        "totalNumbersOfCampus",
        this.form.value.totalNumbersOfCampus
      );
    }
    if (this.form.value.numbersOfSchoolsAndDevision) {
      postData.append(
        "numbersOfSchoolsAndDevision",
        this.form.value.numbersOfSchoolsAndDevision
      );
    }
    if (this.form.value.nobelPrizeWinnerAlumni) {
      postData.append(
        "nobelPrizeWinnerAlumni",
        this.form.value.nobelPrizeWinnerAlumni
      );
    }
    if (this.form.value.numbersOfEducationPrograms) {
      postData.append(
        "numbersOfEducationPrograms",
        this.form.value.numbersOfEducationPrograms
      );
    }

    if (this.form.value.facultytostudentsration) {
      postData.append(
        "facultytostudentsration",
        this.form.value.facultytostudentsration
      );
    }
    if (this.form.value.highestplacementpackage) {
      postData.append(
        "highestplacementpackage",
        this.form.value.highestplacementpackage
      );
    }
    if (this.form.value.scholarshipavailability) {
      postData.append(
        "scholarshipavailability",
        this.form.value.scholarshipavailability
      );
    }

    if (this.form.value.averageplacementpackage) {
      postData.append(
        "averageplacementpackage",
        this.form.value.averageplacementpackage
      );
    }

    if (this.form.value.researchperspective) {
      postData.append(
        "researchperspective",
        this.form.value.researchperspective
      );
    }
    if (this.form.value.video) {
      postData.append("video", this.form.value.video);
    }
    if (this.form.value.lng) {
      postData.append("lng", this.form.value.lng);
    }
    if (this.form.value.lat) {
      postData.append("lat", this.form.value.lat);
    }

    if (this.form.value.customurl) {
      postData.append("customurl", this.form.value.customurl);
    }

    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }

    if (this.form.value.keywords) {
      postData.append("keywords", this.form.value.keywords);
    }

    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (this.form.value.address) {
      postData.append("address", this.form.value.address);
    }
    if (this.form.value.content) {
      postData.append("content", this.form.value.content);
    }
    if (this.form.value.ckeditorcontent) {
      postData.append("ckeditorcontent", this.form.value.ckeditorcontent);
    }

    // Metadetails for abroad colleges page constructions  === starts here
    if (this.form.value.courseHighlights) {
      postData.append("courseHighlights", this.form.value.courseHighlights);
    }
    if (this.form.value.eligibilityCriteria) {
      postData.append(
        "eligibilityCriteria",
        this.form.value.eligibilityCriteria
      );
    }
    if (this.form.value.admissionProcedure) {
      postData.append("admissionProcedure", this.form.value.admissionProcedure);
    }
    if (this.form.value.feesOverview) {
      postData.append("feesOverview", this.form.value.feesOverview);
    }
    if (this.form.value.campusFacilityContent1) {
      postData.append(
        "campusFacilityContent1",
        this.form.value.campusFacilityContent1
      );
    }
    if (this.form.value.campusFacilityContent2) {
      postData.append(
        "campusFacilityContent2",
        this.form.value.campusFacilityContent2
      );
    }
    if (this.form.value.scholarshipOverview) {
      postData.append(
        "scholarshipOverview",
        this.form.value.scholarshipOverview
      );
    }

    console.log(this.form.value);
    if (
      this.form.value.backgroundimg &&
      this.form.value.backgroundimg.length > 0
    ) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
      // postData.append("backgroundimg", this.form.value.backgroundimg);
    }

    if (this.form.value.gallery && this.form.value.gallery.length > 0) {
      for (var i = 0; i < this.form.value.gallery.length; i++) {
        postData.append("gallery", this.form.value.gallery[i]);
      }
      // postData.append("gallery", this.form.value.gallery);
    }

    postData.append("logoImagePath", this.form.value.logoImagePath);
    postData.append("status", "1");

    console.log(postData);

    this.httpRequest
      .postRequest({
        url: "/abroad-college/savecollege",
        value: postData,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.onReset();
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error) => {
          this.isSubmit = false;
          this.btntext = "Submit";
          this.isLoading = false;
          this.isdisabled = false;
          // console.log(error);
          // this.isLoading = false;
          // this.isdisabled = false;
          // this.isSubmit = false;
        }
      );
  }

  // fileName: string = "";
  displayCsvData: any = [];
  // files: any = "";
  csvButtonChange(event: Event) {
    this.isLoading = true;
    this.isdisabled = true;
    this.importbtntext = "Processing";
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0) {
      alert("Please Choose any file!...");
      return;
    }
    const file: File = (target.files as FileList)[0];
    console.log("** File : ", file);
    let fileName = file.name;
    // let interimData = [];
    let extension = fileName.substring(fileName.lastIndexOf(".")).toUpperCase();
    if (extension == ".CSV") {
      //Here calling another method to read CSV file into json
      try {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          console.log(e.target.result, " : e.target.result");
          let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\n");
          console.log(rows, " : **rows");
          for (let i = 0; i < rows.length - 1; i++) {
            // Handler function to support , inside cells
            let str = rows[i];
            let cells = [];
            let inQuotes = false;
            let current = "";
            for (let h = 0; h < str.length; h++) {
              // if (str[h] === '"' || str[h] === "'") {
              if (str[h] === '"') {
                inQuotes = !inQuotes;
              }
              if (str[h] === "," && !inQuotes) {
                cells.push(current);
                current = "";
              } else {
                // if (str[h] === '"' || str[h] === "'") {
                if (str[h] === '"') {
                  // Do nothing
                } else {
                  current += str[h];
                }
              }
            }
            cells.push(current); // Push the last item
            // Handler function to support , inside cells
            // let cells = rows[i].split(",");

            let rowData = {};
            for (let j = 0; j < cells.length; j++) {
              if (i == 0) {
                let headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                let key = headers[j];
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i != 0) {
              jsonData.push(rowData);
            }
          }
          // displaying the json result in string format
          // document.getElementById("display_csv_data").innerHTML =
          //   JSON.stringify(jsonData);
          console.log("display_csv_data : ", jsonData);

          // Handler function to submit this data...
          // this.bolkOnSubmit(jsonData);
          this.httpRequest
            .bulkPostRequest({
              url: "/abroad-college/savemulticollege",
              value: jsonData,
            })
            .subscribe(
              (response: any) => {
                this.isLoading = false;
                this.isdisabled = false;
                if (response.status == "success") {
                  this.isSubmit = true;
                  this.btntext = "Submit";
                  this.importbtntext = "Import from CSV";
                  this.onReset();
                  setTimeout(() => {
                    this.isSubmit = false;
                  }, 3000);
                } else {
                }
              },
              (error) => {
                this.isdisabled = false;
                this.isLoading = false;
                this.importbtntext = "Import from CSV";
              }
            );
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Please select a valid csv file.");
    }
    // this.fileName = event.target.files[0].name;
    // this.files = event.target.files[0];
    // console.log("Kailash : ", event);
    // console.log("File name : ", this.fileName);
    // if (this.fileName)
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  getChilddata(bjectId) {
    console.log(bjectId);
    if (bjectId) {
      this.httpRequest
        .getRequest({
          url: "/subbranch/getChildBranch?subbranchid=" + bjectId,
        })
        .subscribe(
          (response: any) => {
            if (response.status == "success") {
              this.childData = response.data;
            }
          },
          (error) => {}
        );
    }
  }
}
