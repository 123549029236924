import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";

import { HttpRequestService } from "./../../services/http-request.service";
import { NgSelectModule, NgOption } from "@ng-select/ng-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-common-course",
  templateUrl: "./common-course.component.html",
  styleUrls: ["./common-course.component.css"],
})
export class CommonCourseComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  posts: any[] = [];
  totalCount = 0;
  postsPerPage = 10;
  pageSizeOptions = [10, 20, 50, 100, 150, 250, 500, 1000];
  currentPage = 1;
  searchCourseName: string = "";
  selectedIdForContentUpdate: string = "";

  streamOptions: any[] = [];
  subStreamOptions: any[] = [];
  ckeditorcontent: string = "";

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {
    // process environment
    console.log(environment, " : Process Environment");
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      // courselevel: ["", Validators.required],
      // courselevelname: ["", Validators.required],
      // status: ["1", Validators.required],
      stream: ["", Validators.required],
      subStream: [""],
      courseName: ["", Validators.required],
    });

    // this.getCourseLevel();
    this.getStream();
    this.getCommonCourses();
  }

  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  // onSearchCourseName() {
  //   this.ObjectData = this.filterCoursesByName(this.searchCourseName);
  // }

  // filterCoursesByName(query: string) {
  //   return this.ObjectData.filter((course) =>
  //     course.courseName.toLowerCase().includes(query.toLowerCase())
  //   );
  // }

  onSearchCourseName() {
    this.getCommonCourses(this.searchCourseName);
  }
  // Pagination to change page
  onChangedPage(pageData: any) {
    // this.isLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    this.getCommonCourses();
    // this.fetchcollege();
  }
  getCommonCourses(courseName: string = "") {
    this.isLoading = true;
    const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&courseName=${courseName}`;
    this.httpRequest
      .getRequest({
        url: "/v1courses/getallcommoncourses" + queryParams,
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          // console.log(response);
          // if (response.status === "success") {
          //   this.isLoading = false;
          //   if (response.responseFormat.data.length > 0) {
          //     this.ObjectData = response.responseFormat.data;
          //     this.totalCount = response.maxPosts;
          //   }
          // }
          if (response.status === "success") {
            this.isLoading = false;
            if (response.responseFormat.data.length > 0) {
              this.ObjectData = response.responseFormat.data;
              this.totalCount = response.maxPosts;
            } else {
              this.ObjectData = []; // Set to an empty array when there's no data
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  // getCommonCourses() {
  //   this.isLoading = true;
  //   const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}`;
  //   this.httpRequest
  //     .getRequest({
  //       url: "/v1courses/getallcommoncourses" + queryParams,
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         // console.log(response);
  //         if (response.status === "success") {
  //           this.isLoading = false;
  //           if (response.responseFormat.data.length > 0) {
  //             this.ObjectData = response.responseFormat.data;
  //             this.totalCount = response.maxPosts;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  // getCourseLevel() {
  //   this.httpRequest
  //     .getRequest({
  //       url: "/v1courses/getcourselevels",
  //       headers: { "content-type": "application/json" },
  //     })
  //     .subscribe(
  //       (response: any) => {
  //         console.log(response);
  //         if (response.status === "success") {
  //           if (response.data.length > 0) {
  //             this.ObjectData = response.data;
  //           }
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // }

  // Get Stream Data
  getStream() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchstreams?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.streamOptions = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getSubStreamData(streamObjectId: any) {
    console.log(streamObjectId);
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchsubstreams?streamId=" + streamObjectId,
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subStreamOptions = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  // Handler Section for Modal
  displayStyle: any = "none";
  openPopup(item: any) {
    console.log(item, " : Open Pop Up");
    this.selectedIdForContentUpdate = item;
    console.log(
      this.selectedIdForContentUpdate,
      " : this.selectedIdForContentUpdate"
    );
    // this.displayStyle = "block";
    let url = `/v1/courses/common-course/${this.selectedIdForContentUpdate}`;
    window.open(url, "_blank");
  }

  closePopup() {
    this.selectedIdForContentUpdate = "";
    this.displayStyle = "none";
    console.log(
      this.selectedIdForContentUpdate,
      " : this.selectedIdForContentUpdate"
    );
  }

  onCkEditorContentChange(event: any) {
    console.log(event, " :: **** CkEditor Event");
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled = true;
    this.btntext = "Processing ...";
    if (this.objectId) {
      this.httpRequest
        .putRequest({
          url: "/v1courses/updatecommoncourse/" + this.objectId,
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            console.log(response);
            this.isLoading = false;
            if (response.status == "success") {
              this.objectId = "";
              // this.getCourseLevel();
              this.getCommonCourses();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = true;
                this.btntext = "Submit";
                this.onReset();
                this.isdisabled = false;
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      this.httpRequest
        .postRequest({
          url: "/v1courses/savecommoncourse",
          value: JSON.stringify(this.form.value),
          headers: { "content-type": "application/json" },
        })
        .subscribe(
          (response: any) => {
            this.isLoading = false;

            this.isdisabled = false;
            if (response.status == "success") {
              // this.getCourseLevel();
              this.getCommonCourses();
              this.isSubmit = true;
              setTimeout(() => {
                this.isSubmit = false;
                this.btntext = "Submit";
                this.onReset();
              }, 2000);
            } else {
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  onReset(): void {
    this.submitted = false;
    this.text = "Add";
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
    this.subStreamOptions = [];
  }

  editform(Object: any) {
    this.text = "Edit";
    if (Object._id) {
      this.objectId = Object._id;
      this.SingleObjectData = Object;
      this.form.patchValue({
        stream: this.SingleObjectData.stream._id,
        subStream: "",
        courseName: this.SingleObjectData.courseName,
      });
      this.getSubStreamData(this.SingleObjectData.stream._id);
      if (this.SingleObjectData.subStream) {
        this.form.patchValue({
          subStream: this.SingleObjectData.subStream._id,
        });
      }
    }
  }
}
