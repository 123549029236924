import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { HttpRequestService } from "./../../services/http-request.service";
import { Router, ActivatedRoute } from "@angular/router"; // Import the Router module
import { CKEditorComponent } from "@ckeditor/ckeditor5-angular";

@Component({
  selector: "app-add-exam-iirf",
  templateUrl: "./add-exam-iirf.component.html",
  styleUrls: ["./add-exam-iirf.component.css"],
})
export class AddExamIIRFComponent implements OnInit, AfterViewInit {
  @ViewChild("editor", { static: false }) editorComponent: CKEditorComponent;

  public editorFields = [
    { key: "title", label: "Title", content: "" },
    { key: "short_description", label: "Short Description", content: "" },
    { key: "startDate", label: "Start Date", content: "" },
    { key: "endDate", label: "End Date", content: "" },
    { key: "examLogo", label: "Exam Logo", content: "" },
    { key: "short_form", label: "Short Form", content: "" },
    { key: "examCategory", label: "Exam Category", content: "" },
    { key: "examType", label: "Exam Type", content: "" },
    { key: "courseLevel", label: "Course Level", content: "" },
    { key: "latestUpdate", label: "Latest Update", content: "" },
    { key: "content", label: "Content", content: "" },
    { key: "heading", label: "heading", content: "" },
    { key: "examTitle", label: "examTitle", content: "" },
    { key: "examDescription", label: "examDescription", content: "" },
    { key: "examKeywords", label: "examKeywords", content: "" },

    // { key: "title", label: "Title", content: "" },
  ];
  public examId: any;
  public metaTitle: string = "";
  // public seoMetaName: string = "";
  // public seoMetaContent: string = "";
  public seoMetaList: { metaName: string; metaContent: string }[] = [];
  public text: any;
  public faqs: any[] = []; // Array to hold FAQ objects
  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  public editorConfig = {
    ...ClassicEditor.defaultConfig, // Include default CKEditor settings
    toolbar: {
      items: [
        ...ClassicEditor.defaultConfig.toolbar.items, // Include default toolbar items
        // "heading", // Add the heading dropdown to the toolbar only once
        // "customButton", // Add your custom button to the toolbar
      ],
    },
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add your custom heading options here
        // { model: "my-custom-heading", view: "h5", title: "My Custom Heading", class: "my-custom-heading" },
        // { model: "another-custom-heading", view: "h6", title: "Another Custom Heading", class: "another-custom-heading" },
      ],
    },
    // Add other custom settings as needed
  };
  // Function to add a new FAQ field

  constructor(
    private httpRequest: HttpRequestService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  // const seo = {
  //   meta: [
  //     {
  //       metaName: this.editorFields[12].content,
  //       metaContent: this.editorFields[13].content,
  //     },
  //   ],
  // };
  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.examId = params["_id"];
      if (this.examId) {
        this.text = `Edit Exam: ${this.examId}`;
        this.fetchExamDataForEdit(this.examId);
      }
    });
    console.log(this.examId, "dsfeferfer");
  }

  ngAfterViewInit() {
    // Initialize CKEditor content after view initialization
    // this.setCKEditorContent();
  }

  // Function to add a new SEO meta data field
  addSEOMeta() {
    this.seoMetaList.push({ metaName: "", metaContent: "" });
  }

  // Function to remove an SEO meta data field by index
  removeSEOMeta(index: number) {
    this.seoMetaList.splice(index, 1);
  }
  addFAQ() {
    this.faqs.push({ question: "", answer: "" });
  }

  // Function to remove a FAQ field by index
  removeFAQ(index: number) {
    this.faqs.splice(index, 1);
  }
  submitData() {
    const payload = {};
    this.editorFields.forEach((field) => {
      payload[field.key] = field.content;
    });
    // Create an array to hold the SEO meta data
    const seoMetaArray = this.seoMetaList.map((seo) => ({
      metaName: seo.metaName,
      metaContent: seo.metaContent,
    }));
    const titleAndDescription = {
      title: this.editorFields[0].content,
      short_description: this.editorFields[1].content,
      short_form: this.editorFields[5].content,
      faqs: this.faqs,
      seo: { meta: seoMetaArray, metaTitle: this.metaTitle }, // Add the SEO meta data here
    };

    // Update with your API submit endpoint
    let submitUrl = "/iirfexam/iirfexams";

    if (this.examId) {
      // If examId is present, it means you want to update existing data using PATCH
      let EditUrl = `/iirfexam/iirfexam/${this.examId}`;
      const combinedPayload = { ...payload, ...titleAndDescription };

      this.httpRequest
        .patchRequest({ url: EditUrl, value: combinedPayload })
        .subscribe((response) => {
          console.log("Data updated successfully.");
          // Optionally, you can reset the content after submission
          this.editorFields.forEach((field) => {
            field.content = "";
          });

          // After updating the data, you might want to navigate to a different page
          this.router.navigate(["/v1/iirfexam"]);
        });
    } else {
      const combinedPayload = { ...payload, ...titleAndDescription };

      // If examId is not present, it means you want to submit new data using POST
      this.httpRequest
        .postRequest({ url: submitUrl, value: combinedPayload })
        .subscribe((response) => {
          console.log("Data submitted successfully.");
          // Navigate to the /manageexam page
          this.router.navigate(["/v1/iirfexam"]);
          // Optionally, you can reset the content after submission
          // this.editorFields.forEach(field => {
          //   field.content = "";
          // });
        });
    }
  }

  fetchExamDataForEdit(examId: string) {
    const apiUrl = `/iirfexam/get-exam?_id=${examId}`;

    this.httpRequest.getRequest({ url: apiUrl }).subscribe((data: any) => {
      // Assuming data structure matches the response you've shown
      console.log(data, "konhai");
      if (data) {
        // If the response structure has an array under "data" property
        // this.editorFields[0].content = data[0].title || "";
        // this.editorFields[1].content = data[0].short_description || "";

        // this.editorFields[7].content = data[0].startDate || "";
        // this.editorFields[2].content = data[0].endDate || "";
        // // this.editorFields[2].content = data[0].examHighlights || "";
        // this.editorFields[3].content = data[0].examLogo || "";
        // // this.editorFields[5].content = data[0].examStructure || "";
        // this.editorFields[4].content = data[0].short_form || "";
        // this.editorFields[5].content = data[0].examCategory || "";
        // // this.editorFields[8].content = data[0].examRegistration || "";
        // this.editorFields[6].content = data[0].examType || "";
        // this.editorFields[8].content = data[0].courseLevel || "";
        // this.editorFields[9].content = data[0].latestUpdate || "";
        // this.editorFields[10].content = data[0].content || "";
        // this.editorFields[11].content = data[0].heading || "";
        this.editorFields[0].content = data[0].title || "";
        this.editorFields[1].content = data[0].short_description || "";
        this.editorFields[2].content = data[0].startDate || "";
        this.editorFields[3].content = data[0].endDate || "";
        this.editorFields[4].content = data[0].examLogo || "";
        this.editorFields[5].content = data[0].short_form || ""; // Corrected index for short_form
        this.editorFields[6].content = data[0].examCategory || "";
        this.editorFields[7].content = data[0].examType || "";
        this.editorFields[8].content = data[0].courseLevel || "";
        this.editorFields[9].content = data[0].latestUpdate || "";
        this.editorFields[10].content = data[0].content || "";
        this.editorFields[11].content = data[0].heading || "";
        this.editorFields[12].content = data[0].examTitle || "";
        this.editorFields[13].content = data[0].examDescription || "";
        this.editorFields[14].content = data[0].examKeywords || "";
        // SEO meta data edit
        if (data[0].seo && data[0].seo.meta && data[0].seo.meta.length > 0) {
          this.seoMetaList = [...data[0].seo.meta];
        }
        this.faqs = data[0].faqs || "";
      } else {
        this.editorFields[0].content = data[0].title || "";
        this.editorFields[1].content = data[0].short_description || "";
        this.editorFields[2].content = data[0].startDate || "";
        this.editorFields[3].content = data[0].endDate || "";
        this.editorFields[4].content = data[0].examLogo || "";
        this.editorFields[5].content = data[0].short_form || ""; // Corrected index for short_form
        this.editorFields[6].content = data[0].examCategory || "";
        this.editorFields[7].content = data[0].examType || "";
        this.editorFields[8].content = data[0].courseLevel || "";
        this.editorFields[9].content = data[0].latestUpdate || "";
        this.editorFields[10].content = data[0].content || "";
        this.editorFields[11].content = data[0].heading || "";
        this.editorFields[12].content = data[0].examTitle || "";
        this.editorFields[13].content = data[0].examDescription || "";
        this.editorFields[14].content = data[0].examKeywords || "";
        // this.editorFields[0].content = data[0].title || "";
        // this.editorFields[1].content = data[0].short_description || "";

        // this.editorFields[7].content = data[0].startDate || "";
        // this.editorFields[2].content = data[0].endDate || "";
        // // this.editorFields[2].content = data[0].examHighlights || "";
        // this.editorFields[3].content = data[0].examLogo || "";
        // // this.editorFields[5].content = data[0].examStructure || "";
        // this.editorFields[4].content = data[0].short_form || "";
        // this.editorFields[5].content = data[0].examCategory || "";
        // // this.editorFields[8].content = data[0].examRegistration || "";
        // this.editorFields[6].content = data[0].examType || "";
        // this.editorFields[8].content = data[0].courseLevel || "";
        // this.editorFields[9].content = data[0].latestUpdate || "";
        // this.editorFields[10].content = data[0].content || "";
        // this.editorFields[11].content = data[0].heading || "";
      }

      // console.log(this.editorFields[0].content, title, "jhbhuhbhbih");

      // Once the content is set, you can initialize CKEditor
      // this.setCKEditorContent();
    });
  }
}
