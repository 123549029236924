import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Meta } from "./post.model";

const BACKEND_URL = environment.apiUrl + "/mbametadata/";

@Injectable({ providedIn: "root" })
export class MetaService {
  warning(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  error(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  success(arg0: string, arg1: string) {
    throw new Error("Method not implemented.");
  }
  private posts: Meta[] = [];
  private postsUpdated = new Subject<{ posts: Meta[]; postCount: number }>();

  private httpOptions;

  constructor(private http: HttpClient, private router: Router) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
          // method: 'GET',
      })
    };
  }

  getPosts(postsPerPage: number, currentPage: number) {
    const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}`;
    this.http
      .get<{ message: string; posts: any; maxPosts: number }>(
        BACKEND_URL + queryParams, {headers: this.httpOptions }
      )
      .pipe(
        map(postData => {
          return {
            posts: postData.posts.map(post => {
              return {
                id: post._id,
                collegename: post.collegename,
                title: post.title,
                metadescription: post.metadescription,
                keywords: post.keywords,
                creator: post.creator
              };
            }),
            maxPosts: postData.maxPosts
          };
        })
      )
      .subscribe(transformedPostData => {
        this.posts = transformedPostData.posts;
        this.postsUpdated.next({
          posts: [...this.posts],
          postCount: transformedPostData.maxPosts
        });
      });
  }

  getPostUpdateListener() {
    return this.postsUpdated.asObservable();
  }

  getPost(id: string) {
    return this.http.get<{
      _id: string, collegename: string, title: string,  metadescription: string, keywords: string,creator: string;
    }>(BACKEND_URL + id , {headers: this.httpOptions});
  }

  addPost(postData) {
    console.log('postData  : ', postData);
    
    // const postData = new FormData();
    // if(collegename){
    //   postData.append("collegename", collegename);
    // }
    // if(title){
    //   postData.append("title", title);
    // }
    // if(metadescription){
    //   postData.append("metadescription",metadescription);
    // }
    // if(keywords){
    //   postData.append("keywords",keywords);
    // }
   
    this.http
      .post<{ message: string; post: Meta }>(
        BACKEND_URL,
        postData
      )
      .subscribe(responseData => {
        this.router.navigate(["/"]);
      });
  }

  updatePost(id: string, collegename: string, title: string,  metadescription: string, keywords: string) {
    let postData: Meta | FormData;
    if (typeof collegename === "object") {
 postData = new FormData();
      postData.append("id", id);
      if(collegename){
        postData.append("collegename", collegename);
      }
      if(title){
        postData.append("title", title);
      }
      if(metadescription){
        postData.append("metadescription",metadescription);
      }
      if(keywords){
        postData.append("keywords",keywords);
      }
    } else {
      postData = {
        id: id,
        collegename: collegename,
        title: title,
        metadescription: metadescription,
        keywords: keywords,
        creator: null
      };
    }
    this.http
      .put(BACKEND_URL + id, postData, this.httpOptions)
      .subscribe(response => {
        this.router.navigate(["/"]);
      });
  }

  deletePost(postId: string) {
    return this.http.delete(BACKEND_URL + postId, this.httpOptions);
  }
}
