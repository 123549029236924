import { CUSTOM_ELEMENTS_SCHEMA,NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { PostCreateComponent } from "./post-create/post-create.component";
import { PostListComponent } from "./post-list/post-list.component";
import { AngularMaterialModule } from "../angular-material.module";
import { AngularEditorModule } from '@kolkov/angular-editor';
import { AlumuiComponent } from './alumui/alumui.component';
import {MatTabsModule} from '@angular/material/tabs';
import { AddMetaComponent } from './add-meta/add-meta.component';
import { EditComponent } from './edit/edit.component';

@NgModule({
  declarations: [PostCreateComponent, PostListComponent, AlumuiComponent, AddMetaComponent, EditComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    RouterModule,
    AngularEditorModule,
    MatTabsModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ] 
})
export class PostsModule {}
