import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl  } from "@angular/forms";
import { HttpRequestService } from '../../../services/http-request.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { ActivatedRoute } from "@angular/router";
@Component({
  selector: 'app-edit-faq',
  templateUrl: './edit-faq.component.html',
  styleUrls: ['./edit-faq.component.css']
})
export class EditFaqComponent implements OnInit {

  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit =  false;
  btntext = 'Submit';
  text = 'Add';
  isLoading= false;
  isdisabled = false;
  subbranches:any[] = [];
  yearsArray:any[] = [];
  SubObjectData:any= [];
  branches:any= [];
  childbranches:any= [];
  constructor(private formBuilder: FormBuilder, private httpRequest: HttpRequestService, private router: ActivatedRoute) { }

  ngOnInit() {
    this.form = this.formBuilder.group({ 
      yearid:['',Validators.required],
      branchid:['',Validators.required],
      subbranchid:[''],
      title:['',Validators.required],
      description:['',Validators.required],
      status:['',Validators.required],
    })
    this.getBranch();
    this.getSingleFetchdata();
    this.geyears();
  }


  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  geyears(){
    this.httpRequest.getRequest({
      url: '/year/fetchYears?status=1',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
       // console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.yearsArray = response.data;
          }
        }
        
      },
      error => {
        console.log(error)
      }
    );

  }

  getBranch(yearObjectId=0){
    this.httpRequest.getRequest({
      url: '/branch/fetchbranch',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
      //  console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.branches = response.data;
          }
        }
        
      },
      error => {
        console.log(error)
      }
    );
  }

  getSubBranchdata(branchObjectId){
    console.log(branchObjectId);
    this.httpRequest.getRequest({
      url: '/subbranch/fetchsubbranch?branchid='+branchObjectId
      }).
      subscribe((response: any) => {
       
        if (response.status == 'success') {
          this.subbranches = response.data;
        }
      },
      error => {
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    console.log(this.form.value);
    this.isLoading = true;
    this.isdisabled= true;
    this.btntext = 'Processing ...';
    console.log(this.objectId);
    if(this.objectId) {
      this.httpRequest.putRequest({
        url: '/faq/updatefaq/'+ this.objectId,
        value:JSON.stringify(this.form.value),
        headers:{'content-type':'application/json'}
        }).
        subscribe((response: any) => {
          console.log(response);
          this.isLoading = false;
          if (response.status == 'success') {
            this.objectId = '';
            this.isSubmit= true;
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
            this.btntext = 'Submit';
            this.isdisabled= false;
  
          } else {
  
          }
        },
        error => {
          this.isSubmit= false;
          this.btntext = 'Submit';
          this.isdisabled= false;
          this.isLoading = false;
        }
      );

    }

  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = '';
    this.SingleObjectData = {};
  }


  getSingleFetchdata() {
  this.httpRequest
  .getRequest({
    url: "/faq/getsinglefaq/" + this.router.snapshot.params.id,
  })
  .subscribe(
    (response: any) => {
      this.isLoading = false;
      this.isdisabled = false;
      if (response.status == "success") {
        this.SingleObjectData = response.data;
        this.objectId = this.SingleObjectData._id;
        console.log(this.SingleObjectData);
        this.getBranch()
        this.getSubBranchdata(this.SingleObjectData.branchid)
        this.form.patchValue({
          yearid: this.SingleObjectData.yearid,
          branchid: this.SingleObjectData.branchid,
          subbranchid: this.SingleObjectData.subbranchid,
          title: this.SingleObjectData.title,
          description: this.SingleObjectData.description,
          status: this.SingleObjectData.status,
         
        });
      } else {
      }
    },
    (error) => {}
  );
}

}
