import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  QueryList,
  ViewChildren,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { HttpRequestService } from "./../../../services/http-request.service";
import { AngularEditorConfig } from "@kolkov/angular-editor";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "app-add-college",
  templateUrl: "./add-college.component.html",
  styleUrls: ["./add-college.component.css"],
})
export class AddCollegeComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  countries: any[] = [];
  branches: any[] = [];
  childData: any[] = [];
  streams: any[] = [];
  courseLevels: any[] = [];
  SingleObjectData: any;
  isSubmit = false;
  btntext = "Submit";
  importbtntext = "Import from CSV";
  text = "Add";
  isLoading = false;
  isdisabled = false;
  imagePreview: string;
  backImagePreview: any[] = [];
  galleryPreview: any[] = [];
  subbranches: any[] = [];
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: "300px",
    minHeight: "500",
    maxHeight: "auto",
    width: "auto",
    minWidth: "0",
    translate: "yes",
    enableToolbar: true,
    showToolbar: true,
    placeholder: "Enter text here...",
    defaultParagraphSeparator: "",
    defaultFontName: "",
    defaultFontSize: "",
    fonts: [
      { class: "arial", name: "Arial" },
      { class: "times-new-roman", name: "Times New Roman" },
      { class: "calibri", name: "Calibri" },
      { class: "comic-sans-ms", name: "Comic Sans MS" },
    ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: "redText",
        class: "redText",
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ],
    uploadUrl: "v1/image",
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: "top",
    toolbarHiddenButtons: [
      // ['bold', 'italic'],
      // ['fontSize']
    ],
  };

  public Editor = ClassicEditor; // Editor Configuration for CKEDitor
  // Editor Configs is for CKEditor
  public editorConfigs = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "imageUpload",
        "bulletedList",
        "numberedList",
        "blockQuote",
        "undo",
        "redo",
      ],
    },
    height: "10rem",
    // extraPlugins: "autogrow",
    // autoGrow_minHeight: 200,
    // autoGrow_maxHeight: 600,
    // autoGrow_bottomSpace: 50,
    language: "en",
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        // Add other heading levels as needed
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:full",
        "imageStyle:side",
      ],
      styles: ["full", "side"],
      customUploadFileName: (file) => {
        return "custom-prefix-" + file.name;
      },
    },
  };

  @ViewChild("educationinputValue", { static: false })
  educationinputValue: ElementRef;
  @ViewChild("educationinputId", { static: false })
  educationinputId: ElementRef;

  // dynamicCourseName: string;
  // dynamicCourseCode: string;

  // @ViewChild("courseNameInput", { static: false })
  // courseNameInput: ElementRef;
  // @ViewChild("courseCodeInput", { static: false })
  // courseCodeInput: ElementRef;

  @ViewChildren("courseNameInput") courseNameInputs: QueryList<ElementRef>;
  @ViewChildren("courseCodeInput") courseCodeInputs: QueryList<ElementRef>;

  // @ViewChild("lawinputValue", { static: false }) lawinputValue: ElementRef;
  // @ViewChild("lawinputId", { static: false }) lawinputId: ElementRef;

  lawarr = [
    {
      id: "1",
      value: "Law",
    },
  ];

  // alumni: any[] = [
  //   {
  //     "profileimg": "",
  //     "profilename": "",
  //     "profilepos": "",
  //   }
  // ]

  addNewLaw() {
    this.lawarr.push({
      id: this.educationinputId.nativeElement.value,
      value: this.educationinputValue.nativeElement.value,
    });
  }

  // Discarded----
  // courseOptions: any = {
  //   "6488093cfef38a2470b74e45": {
  //     "64786810386d8f2a94c3eec1": [
  //       // {
  //       //   _id: "123456",
  //       //   courseName: "Bachelor In Science",
  //       //   courseCode: "B.Sc",
  //       // },
  //       // {
  //       //   _id: "123457",
  //       //   courseName: "Bachelor In Arts",
  //       //   courseCode: "B.A",
  //       // },
  //       // {
  //       //   _id: "123458",
  //       //   courseName: "Bachelor In Commerece",
  //       //   courseCode: "B.Com",
  //       // },
  //     ],
  //   },
  // };
  // New Course Options
  courseOptions: any = {};

  // constructCourseOption() {
  //   this.courseLevels.forEach((item1) => {
  //     this.courseOptions[item1._id] = {};
  //     this.branches.forEach((item2) => {
  //       this.courseOptions[item1._id][item2._id] = [
  //         {
  //           _id: "123456",
  //           courseName: "Bachelor In Science",
  //           courseCode: "B.Sc",
  //         },
  //         {
  //           _id: "123457",
  //           courseName: "Bachelor In Arts",
  //           courseCode: "B.A",
  //         },
  //         {
  //           _id: "123458",
  //           courseName: "Bachelor In Commerece",
  //           courseCode: "B.Com",
  //         },
  //       ];
  //     });
  //     // this.courseOptions[item1._id];
  //   });
  // }

  // Construct Course Option with Dynamic Names

  addNewCourse(courseLevel: any, stream: any) {
    // console.log(courseLevel, " : courseLevel");
    // console.log(stream, " : stream");

    // Get the input values
    const courseNameInputsArray = this.courseNameInputs.toArray();
    const courseCodeInputsArray = this.courseCodeInputs.toArray();

    const courseName = courseNameInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseNameInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value;
    // Clearing Value
    courseNameInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseNameInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value = "";

    const courseCode = courseCodeInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseCodeInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value;
    // Clearing Value
    courseCodeInputsArray.find(
      (input) =>
        input.nativeElement.id ===
        `courseCodeInput_${courseLevel._id}_${stream._id}`
    ).nativeElement.value = "";

    // Use the input values in your function
    console.log("Course Name:", courseName);
    console.log("Course Code:", courseCode);

    // this.courseOptions["6488093cfef38a2470b74e45"][
    //   "64786810386d8f2a94c3eec1"
    // ].push({
    //   _id: new Date(),
    //   courseName: courseName,
    //   courseCode: courseCode,
    // });

    console.log(this.courseOptions, " : __courseOptions");

    // API request to add Course
    // let payload: any = {
    //   courseName: courseName,
    //   courseCode: courseCode,
    //   courselevel: courseLevel._id,
    //   branch: branch._id,
    // };
    let payload: any = {
      courselevel: courseLevel._id,
      stream: stream._id,
    };
    if (courseName) {
      payload.courseName = courseName;
    }
    if (courseCode) {
      payload.courseCode = courseCode;
    } else {
      payload.courseCode = " ";
    }

    this.httpRequest
      .postRequest({
        url: "/v1courses/savecommoncourse",
        value: payload,
      })
      .subscribe(
        (response: any) => {
          // this.isLoading = false;
          // this.isdisabled = false;
          if (response.status == "success") {
            console.log("Course Added Successfully!");
            this.getAllCommonCourses();
            // let item = {};
            // let keyName = `${response.data.courselevel}_${response.data.branch}`;
            // item[keyName] = response.data._id;
            // this.form.patchValue(item);

            // this.isSubmit = true;
            // this.btntext = "Submit";
            // this.onReset();
            // setTimeout(() => {
            //   this.isSubmit = false;
            // }, 3000);
          } else {
            console.log("Unable to register the course");
          }
        },
        (error) => {
          console.log(error);
          // this.isLoading = false;
          // this.isdisabled = false;
          // this.isSubmit = false;
        }
      );
  }

  // Fetch Course for a level
  tabClicked(courseLevel: any) {
    console.log("Tab clicked:", courseLevel);
    // Add your logic here
    // Call the fetchCourseForThisLevel() function or perform any other desired action
    this.fetchCourseForThisLevel(courseLevel.courselevel);
  }

  fetchCourseForThisLevel(courselevel: any) {
    console.log(courselevel, " : courselevel");
  }

  collegetypes: any[] = [];

  mappedCollegeIdDetails: any;

  // Below is the section for Filtering Colleges to map
  typedStateName: string = "";
  typedDistrictName: string = "";
  typedCityName: string = "";
  typedInstituteName: string = "";
  typeForFilteringInstitute: string = "";
  branchIdForFilteringInstitute: string = "";

  totalPosts = 0;
  postsPerPage = 20;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100, 150];
  posts: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private httpRequest: HttpRequestService
  ) {
    this.selectedOptions;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      //  collegetypeid: ["", Validators.required],
      type: ["", Validators.required],
      parentid: [""],
      branchid: ["", Validators.required],
      // subbranchid: [""],
      // childbranchid: [""],
      // countryid: ["", Validators.required],
      // branchid: ["", Validators.required],
      // subbranchid: ["", Validators.required],
      collegename: ["", Validators.required],
      address: ["", Validators.required],
      state: ["", Validators.required],
      district: ["", Validators.required],
      city: [""],

      phone: [""],
      email: [""],
      websitelink: [""],
      admissionssupport: [""],
      facebookpagelink: [""],
      linkedinpagelink: [""],
      twitterpagelink: [""],
      percentageofinternationalstudents: [""],
      facultytostudentsration: [""],
      highestplacementpackage: [""],
      scholarshipavailability: [""],
      averageplacementpackage: [""],
      researchperspective: [""],
      video: [""],
      lng: [""],
      lat: [""],
      content: [""],
      ckeditorcontent: [""],
      status: ["1"],

      // customurl: [
      //   "",
      //   [Validators.required, Validators.pattern(/^[a-z0-9-]+$/)],
      // ],
      customurl: [""],
      metatitle: [""],
      keywords: [""],
      metadescription: [""],

      checked: [false],
      indeterminate: [false],

      logoImagePath: new FormControl(null, {
        // validators: [Validators.required],
        // asyncValidators: [mimeType]
      }),
      // backgroundimg: [""],
      backgroundimg: new FormControl([], {}),
      // gallery: [""],
      gallery: new FormControl([], {}),
    });
    this.geBranch();
    // this.getcollegetype();
    this.getCountries();
    // this.geBranch();

    // this.courseLevels.forEach((item1) => {
    //   this.branches.forEach((item2) => {
    //     let name = `${item1._id}_${item2._id}`;
    //     this.form.addControl(name, new FormControl(""));
    //   });
    // });

    // Under Review
    /* this.getActiveStreams();
    this.getCourseLevels();
    this.getAllCommonCourses();
    */

    // Constructing Dynamic Course Multiplselect Form Field
    // this.form.addControl
    // this.constructCourseOption();
    // this.getcollegetype();
  }

  getAllCommonCourses() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getallcommoncourses",
        headers: { "content-type": "application/json" },
      })
      .subscribe((response: any) => {
        console.log(response, " : all common courses");
        if (response.status === "success") {
          this.courseOptions = response.responseFormat;
        }
      });
  }

  getCountries() {
    this.httpRequest
      .getRequest({
        url: "/country/fetchCountries?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response, " :countries");
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.countries = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  geBranch() {
    this.httpRequest
      .getRequest({
        url: "/branch/fetchbranch?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              this.branches = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getActiveStreams() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/fetchstreams?status=1",
        //  value:JSON.stringify(this.form.value),
        headers: { "content-type": "application/json" },
      })
      .subscribe(
        (response: any) => {
          console.log(response);
          if (response.status == "success") {
            if (response.data.length > 0) {
              // this.branches = response.data;
              this.streams = response.data;
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  getCourseLevels() {
    this.httpRequest
      .getRequest({
        url: "/v1courses/getcourselevels?status=1",
        headers: { "content-type": "application/json" },
      })
      .subscribe((response: any) => {
        console.log(response);
        if (response.status === "success") {
          if (response.data.length > 0) {
            this.courseLevels = response.data;

            for (let i = 0; i < this.courseLevels.length; i++) {
              for (let j = 0; j < this.streams.length; j++) {
                let name = `${this.courseLevels[i]._id}_${this.streams[j]._id}`;
                console.log(name, " : name");
                this.form.addControl(name, new FormControl(""));
              }
            }
          }
        }
      });
  }

  getcollegetype() {
    this.httpRequest
      .getRequest({
        url: "/college/getCollegeType",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.collegetypes = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  getSubBranchdata(branchObjectId) {
    console.log(branchObjectId);
    this.httpRequest
      .getRequest({
        url:
          "/subbranch/fetchsubbranch?branchid=" + branchObjectId + "&status=1",
      })
      .subscribe(
        (response: any) => {
          if (response.status == "success") {
            this.subbranches = response.data;
          } else {
          }
        },
        (error) => {}
      );
  }

  options = [
    { name: "OptionA", value: "1", checked: true },
    { name: "OptionB", value: "2", checked: false },
    { name: "OptionC", value: "3", checked: true },
  ];

  get selectedOptions() {
    // right now: ['1','3']
    return this.options.filter((opt) => opt.checked).map((opt) => opt.value);
  }

  // compareWithFunc(a, b) {
  //   return a.name === b.name;
  // }

  onImagePicked(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const file = fileInput.files[0];
    const maxSizeInKB = 1024; // Define your maximum size in kilobytes (KB)

    if (file) {
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Logo image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        this.form.get("logoImagePath").setValue(null);
        this.imagePreview = "";
      } else {
        this.form.patchValue({ logoImagePath: file });
        this.form.get("logoImagePath").updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result as string;
        };
        reader.readAsDataURL(file);
      }
    }
  }

  onBackgroundPicked(event: Event) {
    this.backImagePreview = [];
    const fileInput = event.target as HTMLInputElement;
    const files = fileInput.files;
    const maxSizeInKB = 1024; // Define your maximum size in kilobytes (KB)

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSizeInKB = file.size / 1024;

      if (fileSizeInKB > maxSizeInKB) {
        // File size exceeds the allowed limit
        alert("Background image size exceeds the maximum allowed size.");
        fileInput.value = ""; // Clear the input field
        this.form.get("backgroundimg").setValue(null);
        this.backImagePreview = [];
        break; // Exit the loop if one file exceeds the size limit
      } else {
        this.form.patchValue({ backgroundimg: files });
        this.form.get("backgroundimg").updateValueAndValidity();

        const reader = new FileReader();
        reader.onload = () => {
          this.backImagePreview.push(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  }

  // old code

  // onImagePicked(event: Event) {
  //   console.log(event);
  //   const file = (event.target as HTMLInputElement).files[0];
  //   this.form.patchValue({ logoImagePath: file });
  //   this.form.get("logoImagePath").updateValueAndValidity();
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imagePreview = reader.result as string;
  //     // console.log('reader.result : ', reader.result);
  //     //  this.alldata.logoImagePath = reader.result as string;
  //     // backgroundimg
  //   };
  //   reader.readAsDataURL(file);
  // }

  // onBackgroundPicked(event: Event) {
  //   this.backImagePreview = [];
  //   console.log(event);
  //   const files = (event.target as HTMLInputElement).files;
  //   this.form.patchValue({ backgroundimg: files });
  //   this.form.get("backgroundimg").updateValueAndValidity();
  //   console.log(files, " : files");
  //   // console.log(this.form.get())

  //   for (let i = 0; i < files.length; i++) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.backImagePreview.push(reader.result as string);
  //       console.log(this.backImagePreview, " : this.backImagePreview");
  //     };
  //     reader.readAsDataURL(files[i]);
  //   }

  //   // console.log("file: ", files);
  //   // // console.log(this.back);
  //   // // const reader = new FileReader();
  //   // // this.alldata.backgroundimg = reader.result as string;
  //   // // reader.onload = () => {
  //   // //   this.backImagePreview = reader.result as string;
  //   // // };
  //   // // reader.readAsDataURL(file);
  //   // function readFile(index: any) {
  //   //   if (index >= files.length) return;
  //   //   const reader = new FileReader();
  //   //   let file = files[index];
  //   //   reader.readAsDataURL(file);
  //   //   reader.onload = () => {
  //   //     // get file content
  //   //     // this.galleryPreview();
  //   //     this.backImagePreview.push(reader.result as string);
  //   //     console.log(this.backImagePreview, " : backImagePreview");

  //   //     // var bin = e.target.result;
  //   //     // do sth with bin
  //   //     readFile(index + 1);
  //   //   };
  //   //   // reader.readAsBinaryString(file);
  //   //   // reader.readAsDataURL(file);
  //   // }
  //   // readFile(0);
  // }

  onGalleryPicked(event: Event) {
    console.log(event);
    const files = (event.target as HTMLInputElement).files;
    this.form.patchValue({ gallery: files });
    this.form.get("gallery").updateValueAndValidity();
    console.log("file: ", files);
    // const reader = new FileReader();
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = () => {
        this.galleryPreview.push(reader.result as string);
        console.log(this.galleryPreview, " : this.galleryPreview");
      };
      reader.readAsDataURL(files[i]);
    }
    // reader.onload = () => {
    //   this.backImagePreview = reader.result as string;
    // };
    // reader.readAsDataURL(file);
    // function readFile(index) {
    //   if (index >= files.length) return;
    //   var file = files[index];
    //   reader.onload = function (e) {
    //     // get file content

    //     // var bin = e.target.result;
    //     // do sth with bin
    //     readFile(index + 1);
    //   };
    //   reader.readAsBinaryString(file);
    // }
    // readFile(0);
  }

  onSubmit() {
    console.log("Submit : ", this.form);
    // return;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.isdisabled = true;
    this.btntext = "Processing...";
    // console.log(this.selectedArtsarr);
    // (this.form.value.artshumanities = this.selectedArtsarr),
    //   (this.form.value.design = this.selectedDesignarr),
    //   (this.form.value.social = this.selectedSocialarr),
    //   (this.form.value.physical = this.selectedPhysicalarr),
    //   (this.form.value.lifesciences = this.selectedLifearr),
    //   (this.form.value.engineering = this.selectedEngineeringarr),
    //   (this.form.value.business = this.selectedBusinessarr),
    //   (this.form.value.medicine = this.selectedMedicinearr),
    //   (this.form.value.education = this.selectedEducationarr),
    //   (this.form.value.law = this.selectedLawarr),
    // console.log(this.form.value);
    let postData = new FormData();
    //postData = this.form.value;

    if (this.form.value.type) {
      postData.append("type", this.form.value.type);
    }

    if (this.form.value.parentid) {
      postData.append("parentid", this.form.value.parentid);
    }

    if (this.form.value.countryid) {
      postData.append("countryid", this.form.value.countryid);
    }

    if (this.form.value.branchid) {
      postData.append("branchid", this.form.value.branchid);
    }

    if (this.form.value.subbranchid) {
      postData.append("subbranchid", this.form.value.subbranchid);
    }
    if (this.form.value.childbranchid) {
      postData.append("childbranchid", this.form.value.childbranchid);
    }
    // if (this.form.value.collegetypeid) {
    //   postData.append("collegetypeid", this.form.value.collegetypeid);
    // }

    if (this.form.value.collegename) {
      postData.append("collegename", this.form.value.collegename);
    }

    if (this.form.value.establishmentYear) {
      postData.append("establishmentYear", this.form.value.establishmentYear);
    }

    // if (this.form.value.area) {
    //   postData.append("area", this.form.value.area);
    // }

    if (this.form.value.zone) {
      postData.append("zone", this.form.value.zone);
    }

    if (this.form.value.country) {
      postData.append("country", this.form.value.country);
    }

    if (this.form.value.phone) {
      postData.append("phone", this.form.value.phone);
    }
    if (this.form.value.email) {
      postData.append("email", this.form.value.email);
    }
    if (this.form.value.websitelink) {
      postData.append("websitelink", this.form.value.websitelink);
    }
    // if (this.form.value.placementperformance) {
    //   postData.append(
    //     "placementperformance",
    //     this.form.value.placementperformance
    //   );
    // }
    // if (this.form.value.tlrp) {
    //   postData.append("tlrp", this.form.value.tlrp);
    // }
    // if (this.form.value.research) {
    //   postData.append("research", this.form.value.research);
    // }
    // if (this.form.value.industryincomeintegration) {
    //   postData.append(
    //     "industryincomeintegration",
    //     this.form.value.industryincomeintegration
    //   );
    // }
    // if (this.form.value.pss) {
    //   postData.append("pss", this.form.value.pss);
    // }
    // if (this.form.value.futureorientation) {
    //   postData.append("futureorientation", this.form.value.futureorientation);
    // }
    // if (this.form.value.epio) {
    //   postData.append("epio", this.form.value.epio);
    // }
    if (this.form.value.admissionssupport) {
      postData.append("admissionssupport", this.form.value.admissionssupport);
    }
    if (this.form.value.facebookpagelink) {
      postData.append("facebookpagelink", this.form.value.facebookpagelink);
    }
    if (this.form.value.linkedinpagelink) {
      postData.append("linkedinpagelink", this.form.value.linkedinpagelink);
    }
    if (this.form.value.twitterpagelink) {
      postData.append("twitterpagelink", this.form.value.twitterpagelink);
    }
    if (this.form.value.percentageofinternationalstudents) {
      postData.append(
        "percentageofinternationalstudents",
        this.form.value.percentageofinternationalstudents
      );
    }
    if (this.form.value.facultytostudentsration) {
      postData.append(
        "facultytostudentsration",
        this.form.value.facultytostudentsration
      );
    }
    if (this.form.value.highestplacementpackage) {
      postData.append(
        "highestplacementpackage",
        this.form.value.highestplacementpackage
      );
    }
    if (this.form.value.scholarshipavailability) {
      postData.append(
        "scholarshipavailability",
        this.form.value.scholarshipavailability
      );
    }

    if (this.form.value.averageplacementpackage) {
      postData.append(
        "averageplacementpackage",
        this.form.value.averageplacementpackage
      );
    }

    if (this.form.value.researchperspective) {
      postData.append(
        "researchperspective",
        this.form.value.researchperspective
      );
    }
    if (this.form.value.video) {
      postData.append("video", this.form.value.video);
    }
    if (this.form.value.lng) {
      postData.append("lng", this.form.value.lng);
    }
    if (this.form.value.lat) {
      postData.append("lat", this.form.value.lat);
    }

    if (this.form.value.customurl) {
      postData.append("customurl", this.form.value.customurl);
    }

    if (this.form.value.metatitle) {
      postData.append("metatitle", this.form.value.metatitle);
    }

    if (this.form.value.keywords) {
      postData.append("keywords", this.form.value.keywords);
    }

    if (this.form.value.metadescription) {
      postData.append("metadescription", this.form.value.metadescription);
    }

    if (this.form.value.address) {
      postData.append("address", this.form.value.address);
    }
    if (this.form.value.state) {
      postData.append("state", this.form.value.state);
    }
    if (this.form.value.district) {
      postData.append("district", this.form.value.district);
    }
    if (this.form.value.city) {
      postData.append("city", this.form.value.city);
    }

    if (this.form.value.content) {
      postData.append("content", this.form.value.content);
    }
    if (this.form.value.ckeditorcontent) {
      postData.append("ckeditorcontent", this.form.value.ckeditorcontent);
    }
    // if (this.form.value.artshumanities) {
    //   postData.append("artshumanities", this.form.value.artshumanities);
    // }

    // if (this.form.value.design) {
    //   postData.append("design", this.form.value.design);
    // }
    // if (this.form.value.social) {
    //   postData.append("social", this.form.value.social);
    // }
    // if (this.form.value.physical) {
    //   postData.append("physical", this.form.value.physical);
    // }
    // if (this.form.value.lifesciences) {
    //   postData.append("lifesciences", this.form.value.lifesciences);
    // }
    // if (this.form.value.engineering) {
    //   postData.append("engineering", this.form.value.engineering);
    // }
    // if (this.form.value.business) {
    //   postData.append("business", this.form.value.business);
    // }
    // if (this.form.value.medicine) {
    //   postData.append("medicine", this.form.value.medicine);
    // }
    // if (this.form.value.education) {
    //   postData.append("education", this.form.value.education);
    // }
    // if (this.form.value.law) {
    //   postData.append("law", this.form.value.law);
    // }

    console.log(this.form.value);
    if (
      this.form.value.backgroundimg &&
      this.form.value.backgroundimg.length > 0
    ) {
      for (var i = 0; i < this.form.value.backgroundimg.length; i++) {
        postData.append("backgroundimg", this.form.value.backgroundimg[i]);
      }
      // postData.append("backgroundimg", this.form.value.backgroundimg);
    }

    if (this.form.value.gallery && this.form.value.gallery.length > 0) {
      for (var i = 0; i < this.form.value.gallery.length; i++) {
        postData.append("gallery", this.form.value.gallery[i]);
      }
      // postData.append("gallery", this.form.value.gallery);
    }

    postData.append("logoImagePath", this.form.value.logoImagePath);
    postData.append("status", "1");

    console.log(postData);

    this.httpRequest
      .postRequest({
        url: "/college/savecollege",
        value: postData,
      })
      .subscribe(
        (response: any) => {
          this.isLoading = false;
          this.isdisabled = false;
          if (response.status == "success") {
            this.isSubmit = true;
            this.btntext = "Submit";
            this.onReset();
            setTimeout(() => {
              this.isSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error) => {
          this.isSubmit = false;
          this.btntext = "Submit";
          this.isLoading = false;
          this.isdisabled = false;
          // console.log(error);
          // this.isLoading = false;
          // this.isdisabled = false;
          // this.isSubmit = false;
        }
      );
  }

  // fileName: string = "";
  displayCsvData: any = [];
  // files: any = "";
  csvButtonChange(event: Event) {
    this.isLoading = true;
    this.isdisabled = true;
    this.importbtntext = "Processing";
    const target = event.target as HTMLInputElement;
    if (target.files.length === 0) {
      alert("Please Choose any file!...");
      return;
    }
    const file: File = (target.files as FileList)[0];
    console.log("** File : ", file);
    let fileName = file.name;
    // let interimData = [];
    let extension = fileName.substring(fileName.lastIndexOf(".")).toUpperCase();
    if (extension == ".CSV") {
      //Here calling another method to read CSV file into json
      try {
        let reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (e: any) => {
          console.log(e.target.result, " : e.target.result");
          let jsonData = [];
          let headers = [];
          let rows = e.target.result.split("\n");
          console.log(rows, " : **rows");
          for (let i = 0; i < rows.length - 1; i++) {
            // Handler function to support , inside cells
            let str = rows[i];
            let cells = [];
            let inQuotes = false;
            let current = "";
            for (let h = 0; h < str.length; h++) {
              // if (str[h] === '"' || str[h] === "'") {
              if (str[h] === '"') {
                inQuotes = !inQuotes;
              }
              if (str[h] === "," && !inQuotes) {
                cells.push(current);
                current = "";
              } else {
                // if (str[h] === '"' || str[h] === "'") {
                if (str[h] === '"') {
                  // Do nothing
                } else {
                  current += str[h];
                }
              }
            }
            cells.push(current); // Push the last item
            // Handler function to support , inside cells
            // let cells = rows[i].split(",");

            let rowData = {};
            for (let j = 0; j < cells.length; j++) {
              if (i == 0) {
                let headerName = cells[j].trim();
                headers.push(headerName);
              } else {
                let key = headers[j];
                if (key) {
                  rowData[key] = cells[j].trim();
                }
              }
            }
            //skip the first row (header) data
            if (i != 0) {
              jsonData.push(rowData);
            }
          }
          // displaying the json result in string format
          // document.getElementById("display_csv_data").innerHTML =
          //   JSON.stringify(jsonData);
          console.log("display_csv_data : ", jsonData);

          // Handler function to submit this data...
          // this.bolkOnSubmit(jsonData);
          this.httpRequest
            .bulkPostRequest({
              url: "/college/savemulticollege",
              value: jsonData,
            })
            .subscribe(
              (response: any) => {
                this.isLoading = false;
                this.isdisabled = false;
                if (response.status == "success") {
                  this.isSubmit = true;
                  this.btntext = "Submit";
                  this.importbtntext = "Import from CSV";
                  this.onReset();
                  setTimeout(() => {
                    this.isSubmit = false;
                  }, 3000);
                } else {
                }
              },
              (error) => {
                this.isdisabled = false;
                this.isLoading = false;
                this.importbtntext = "Import from CSV";
              }
            );
        };
        reader.readAsDataURL(file);
      } catch (e) {
        console.log(e);
      }
    } else {
      alert("Please select a valid csv file.");
    }
    // this.fileName = event.target.files[0].name;
    // this.files = event.target.files[0];
    // console.log("Kailash : ", event);
    // console.log("File name : ", this.fileName);
    // if (this.fileName)
  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
    this.objectId = "";
    this.SingleObjectData = {};
  }

  getChilddata(bjectId: any) {
    console.log(bjectId);
    if (bjectId) {
      this.httpRequest
        .getRequest({
          url: "/subbranch/getChildBranch?subbranchid=" + bjectId,
        })
        .subscribe(
          (response: any) => {
            if (response.status == "success") {
              this.childData = response.data;
            }
          },
          (error) => {}
        );
    }
  }

  // Institute filtering section for Parent institute mapping
  filterLoading: boolean = false;
  filterDisabled: boolean = false;
  filterIsSubmit: boolean = false;

  onChangedPage(pageData: any) {
    this.filterLoading = true;
    this.currentPage = pageData.pageIndex + 1;
    this.postsPerPage = pageData.pageSize;
    // this.mbsService.getPosts(
    //   this.postsPerPage,
    //   this.currentPage,
    //   this.searchForm.value
    // );
    let payload: any = {
      collegename: this.typedInstituteName,
      state: this.typedStateName,
      district: this.typedDistrictName,
      city: this.typedCityName,
      type: this.typeForFilteringInstitute,
      branchid: this.branchIdForFilteringInstitute,
      currentPage: this.currentPage,
      postsPerPage: this.postsPerPage,
    };

    // console.log(payload, " : payload");
    this.filterColleges(payload);
  }

  // Below is the section for filtering and mapping the Colleges with Rankings...
  handleCollegeFilterButtonClick() {
    this.currentPage = 1;
    this.postsPerPage = 20;

    let payload: any = {
      collegename: this.typedInstituteName,
      state: this.typedStateName,
      district: this.typedDistrictName,
      city: this.typedCityName,
      type: this.typeForFilteringInstitute,
      branchid: this.branchIdForFilteringInstitute,
      currentPage: this.currentPage,
      postsPerPage: this.postsPerPage,
    };

    // console.log(payload, " : payload");
    this.filterColleges(payload);
  }

  // Separate API calling Handler should be called to filter the Institutions
  filterColleges(payload: any) {
    this.httpRequest
      .postRequest({
        url: "/college/filter-colleges",
        value: payload,
      })
      .subscribe(
        (response: any) => {
          this.filterLoading = false;
          this.filterDisabled = false;
          if (response.status == "success") {
            this.filterIsSubmit = true;
            this.posts = response.posts;
            this.totalPosts = response.maxPosts;
            setTimeout(() => {
              this.filterIsSubmit = false;
            }, 3000);
          } else {
          }
        },
        (error: any) => {
          this.filterDisabled = false;
          this.filterLoading = false;
          console.log(error.message);
        }
      );
  }

  // Map on Event Click
  mapOnEventClick(post: any) {
    console.log(post, " : mapped college details post");
    this.mappedCollegeIdDetails = post;
    this.form.patchValue({
      parentid: post._id,
    });
  }

  unMapOnEventClick(post: any) {
    this.mappedCollegeIdDetails = undefined;
    this.form.patchValue({
      parentid: "",
    });
  }
}
