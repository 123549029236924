import { Component, OnInit } from '@angular/core';
import { HttpRequestService } from './../../services/http-request.service';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { FormGroup, FormControl, Validators, FormBuilder, AbstractControl  } from "@angular/forms";
import { Subscription } from "rxjs";
import { Subject } from "rxjs";
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  submitted = false;
  form: FormGroup;
  objectId: any;
  ObjectData: any[] = [];
  SingleObjectData: any;
  isSubmit =  false;
  btntext = 'Submit';
  text = 'Add';
  isLoading= false;
  isdisabled = false;
  imagePreview: string;
  
  mbaData: any = [];
  userId: string;
  userIsAuthenticated = false;
  totalCount = 0;
  postsPerPage = 10;
  currentPage = 1;
  pageSizeOptions = [10, 20, 50, 100,150];
  yearsArray: any[] = [];
  branches: any[] = [];
  subbranches: any[] = [];
  private postsSub: Subscription;
  private postsUpdated = new Subject<{ ObjectData, postCount: number }>();
  constructor(private formBuilder: FormBuilder, private httpRequest: HttpRequestService) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      yearid:[''],
      branchid:[''],
      subbranchid:[''],
      status:[''],
    
    });

    this.fetchcollege();
    this.postsSub = this.httpRequest
    .getPostUpdateListener()
    .subscribe((postData) => {
      this.isLoading = false;
      this.totalCount = postData.postCount;
      this.ObjectData = postData.posts;
      console.log(this.ObjectData);
    });

    this.geBranch();
    this.getyears();
  }
   
  getyears(){
    this.httpRequest.getRequest({
      url: '/year/fetchYears?status=1',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
        console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.yearsArray = response.data;
          }
        }
        
      },
      error => {
        console.log(error)
      }
    );

  }

  geBranch(yearObjectId=0){
    this.branches = []
    this.httpRequest.getRequest({
      url: '/branch/fetchbranch',
    //  value:JSON.stringify(this.form.value),
      headers:{'content-type':'application/json'}
      }).
      subscribe((response: any) => {
        console.log(response);
        if(response.status == 'success') {
          if(response.data.length > 0) {
            this.branches = response.data;
          } 
        }
        
      },
      error => {
        console.log(error)
      }
    );
  }
  
  getSubBranchdata(branchObjectId){
    console.log(branchObjectId);
    this.subbranches = [];
    this.httpRequest.getRequest({
      url: '/subbranch/fetchsubbranch?branchid='+branchObjectId
      }).
      subscribe((response: any) => {
        if (response.status == 'success') {
          this.subbranches = response.data;
        }
      },
      error => {
      }
    );
  }

    // Pagination to change page
    onChangedPage(pageData) {
      this.isLoading = true;
      this.currentPage = pageData.pageIndex + 1;
      this.postsPerPage = pageData.pageSize;
      this.fetchcollege();
  
    }
  
    // Fetch all data from database with page size
    fetchcollege() {
      const queryParams = `?pagesize=${this.postsPerPage}&page=${this.currentPage}&yearid=${this.form.value.yearid}&branchid=${this.form.value.branchid}&subbranchid=${this.form.value.subbranchid}&status=${this.form.value.status}`;
      this.httpRequest.getPosts({
      url: '/faq/fetchfaq'+queryParams
     })
    }

    onSubmit() {
      console.log(this.form.value);
      this.fetchcollege();
  
    }

}
